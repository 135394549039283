import { gql } from '@apollo/client';

export const productFragment = gql`
  fragment ProductFragment on Product {
    id
    name
    sku
    price
    uom {
      nodes {
        name
        displayName
        quantityInBaseUnit
      }
    }
    inventory {
      available
      onAllocation
    }
    img {
      cdnURL
      alt
    }
    attributes {
      AttributeId
      AttributeGroupName
      ValueId
      Value
      Name
			displayName
      Sort
    }
    combo {
      id
      code
      allowMultiple
    }
  }
`;
