import React from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';


interface Props {
	
};

const BlankLayout: React.FC<Props> = (props) => {
	const classes = useStyles();

	return (<>

		<main className={classes.main}>
			{props.children}
		</main>
	</>);
};

const useStyles = makeStyles((theme: Theme) => ({
	main: {
		flex: '1 0 100%',
		display: 'flex',
		flexDirection: 'column',
	},
}));

export default BlankLayout;
