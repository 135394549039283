export const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  // eslint-disable-next-line no-bitwise
  const r = (Math.random() * 16) | 0;
  // eslint-disable-next-line no-bitwise
  const v = c === 'x' ? r : (r & 0x3) | 0x8;
  return v.toString(16);
});

export const generateSession = () => {
  const uuid = uuidv4();

  if (
    document.cookie
      .split(';')
      .filter((item) => item.trim().startsWith('sessionId=')).length
  ) {
    const { cookie } = document;
    let cookieValue: string | undefined;
    const sessionCookie = cookie
      .split(';')
      .find((item) => item.trim().startsWith('sessionId='));

    if (sessionCookie !== undefined) {
      cookieValue = sessionCookie.split('=')[1];
    }

    return cookieValue;
  }
  // Do not add '.' for localhost
  if (window.location.host.includes('localhost')) {
    document.cookie = `sessionId=${uuid};`;
  } else {
    document.cookie = `sessionId=${uuid};domain=${window.location.hostname};path=/`;
  }

  return uuid;
};
