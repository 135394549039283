import React, { useState, useCallback, MouseEvent } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';

// Local
import DrawerNav from '../components/DrawerNav';
import HeaderNav from '../components/HeaderNav';
import CountdownTimer from '../components/CountdownTimer';
import Messages from '../components/Messages';
import Notifications from '../components/Notifications';
import HeaderIcons from '../components/HeaderIcons';
import HeaderSnackbar from '../components/HeaderSnackbar';
import { useSnackbar } from 'pages/_layouts/components/HeaderSnackbar/SnackbarProvider';

// Operations
import { useMyChatGroup } from 'operations/queries/chatGroupList';
import { useGetCatalog } from 'operations/queries/getCategories';
import { useLogout } from 'operations/mutations/logout';

// Helpers
import { LINKS } from 'helpers/constants';

// Components
import ElevationScroll from 'components/ElevationScroll';

// Icons
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';

// Material-UI
import { makeStyles, Theme, darken } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import ButtonBase from '@material-ui/core/ButtonBase';

interface Props {
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  settings?: any;
}

const Header: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const snackbarData = useSnackbar();
  // State
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cutoffTimerOpen, setCutoffTimerOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  // Operations
  const { data: catalog } = useGetCatalog();
  const { data: myChatGroup } = useMyChatGroup();
  const { mutate: logout } = useLogout();

  /*
   * When location change close the search box if it is open
   */
  history.listen((_) => {
    if (drawerOpen) {
      setDrawerOpen(false);
    }
  });

  const handleLogout = async (event: MouseEvent<HTMLElement | MouseEvent>) => {
    setDrawerOpen(false);

    try {
      await logout();
    } catch (error) {
      console.error('Unable to clear cache ', error);
    }
    if (window !== undefined) {
      window.location.href = LINKS.LOGIN;
    }
  };
  const openNotificationCb = useCallback(() => {
    setOpenNotification(true);
  }, []);
  const handleNewNotification = (notifications: any) => {
    notifications.forEach((notification: any) =>
      snackbarData.addNotification({
        ...notification.node,
        handleOkAction: () => {
          openNotificationCb();
        },
      })
    );
  };

  const handleAlertClose = () => {
    setOpenNotification(false);
  };

  return (
    <>
      <ElevationScroll>
        <AppBar position="sticky">
          {/* Desktop */}
          <Hidden smDown>
            <Container maxWidth={props.maxWidth} disableGutters>
              <div className={classes.toolbar}>
                <ButtonBase
                  component={RouterLink}
                  to="/"
                  className={classes.logo}
                >
                  <img
                    alt="horizon logo"
                    src={props.settings?.logoPath || ''}
                    tabIndex={-1}
                  />
                </ButtonBase>
                <HeaderNav catalog={catalog} />
                <div className={classes.actions}>
                  <Toolbar
                    variant="dense"
                    disableGutters
                    className={classes.smallButtonToolbar}
                  >
                    <div className="flex-grow"></div>
                    <CountdownTimer
                      open={cutoffTimerOpen}
                      handleOpen={(open: boolean) => setCutoffTimerOpen(open)}
                    />
                    {myChatGroup?.id && (
                      <Messages chatGroupId={myChatGroup.id} />
                    )}
                    <React.StrictMode>
                      <Notifications
                        onNewNotification={(notifications) => {
                          handleNewNotification(notifications);
                        }}
                        openNotification={openNotification}
                      />
                    </React.StrictMode>
                  </Toolbar>
                  <Toolbar variant="dense" disableGutters>
                    <div className="flex-grow"></div>
                    <HeaderIcons />
                  </Toolbar>
                </div>
              </div>
            </Container>
          </Hidden>
          {/* Mobile */}
          <Hidden mdUp>
            <Toolbar
              variant="dense"
              disableGutters
              className={clsx(classes.smallToolbar, classes.smallButtonToolbar)}
            >
              <div className="flex-grow"></div>
              <CountdownTimer
                open={cutoffTimerOpen}
                handleOpen={(open: boolean) => setCutoffTimerOpen(open)}
              />
              {myChatGroup?.id && <Messages chatGroupId={myChatGroup.id} />}
              <Notifications
                onNewNotification={(notifications) => {
                  handleNewNotification(notifications);
                }}
                openNotification={openNotification}
              />
            </Toolbar>
            <Toolbar className={classes.mobileIcons} variant="dense">
              <IconButton
                aria-label="menu"
                color="inherit"
                onClick={(e: MouseEvent<HTMLButtonElement>) =>
                  setDrawerOpen((value) => !value)
                }
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                PaperProps={{ className: classes.drawerPaper }}
              >
                <DrawerNav
                  catalog={catalog}
                  logoPath={props.settings?.logoPath || ''}
                  handleClose={() => setDrawerOpen(false)}
                  handleLogout={handleLogout}
                />
              </Drawer>
              <div className="flex-grow"></div>
              <RouterLink to="/" tabIndex={-1}>
                <IconButton color="inherit" aria-label="home">
                  <HomeIcon />
                </IconButton>
              </RouterLink>
              <HeaderIcons />
            </Toolbar>
          </Hidden>
        </AppBar>
      </ElevationScroll>
      <HeaderSnackbar onAlertClose={handleAlertClose} />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    maxHeight: 100,
  },
  smallToolbar: {
    minHeight: 30,
    height: 40,
  },
  logo: {
    height: 90,
    padding: theme.spacing(0, 0, 2, 0),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    '& > img': {
      display: 'block',
      height: '100%',
      objectFit: 'contain',
    },
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.35)',
    },
  },
  mobileIcons: {
    padding: theme.spacing(0),
    color: theme.palette.primary.contrastText,
    '& > a': {
      color: theme.palette.primary.contrastText,
    },
  },
  drawerPaper: {
    width: '95vw',
    flex: '1 0 100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },
  smallButtonToolbar: {
    '&:first-child > *': {
      // marginRight: theme.spacing(1),
    },
  },
  smallButton: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: darken(theme.palette.primary.dark, 0.05),
    transform: 'scale(0.9)',
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.dark, 0.15),
    },
    '& > .MuiButton-label > .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    '& > .MuiToolbar-dense': {
      padding: theme.spacing(0, 1),
    },
  },
  iconButton: {
    '&[data-open="true"]': {
      display: 'inline-block',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: darken(theme.palette.primary.dark, 0.05),
    },
  },
  accountMenu: {
    '& > .MuiListItem-root': {
      padding: theme.spacing(0.5, 2),
      '& > .MuiListItemIcon-root': {
        minWidth: 34,
      },
    },
  },
  messages: {
    '&[data-new="true"]': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      animation: 'pulse-secondary 2s infinite',
    },
  },
}));

export default Header;
