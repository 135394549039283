import { gql } from '@apollo/client';

export const orderAddressFragment = gql`
  fragment OrderAddressFragment on Address {
    id
    firstName
    lastName
    address1
    address2
    city
    countryId
    stateId
    countryName
    stateName
    postalCode
    phoneNumber
  }
`;
