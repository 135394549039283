import React, { useState, MouseEvent } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

// Local
import HeaderSearch from './HeaderSearch';
import MiniCart from './MiniCart';
import Sobo from './Sobo';
import IconPopoverButton from './IconPopoverButton';

// Operations
import { useLogout } from 'operations/mutations/logout';

// Components
import Ability from 'components/Ability';

// Helpers
import { LINKS } from 'helpers/constants';

// Icons
import AccountIcon from '@material-ui/icons/AccountCircle';
import ProfileIcon from '@material-ui/icons/AssignmentInd';
import ChangePasswordIcon from '@material-ui/icons/Lock';
import OrderSheetsIcon from '@material-ui/icons/ListAlt';
import OrderHistoryIcon from '@material-ui/icons/History';
import OrderFavoritesIcon from '@material-ui/icons/StarOutlined';
import AccountStatementIcon from '@material-ui/icons/AccountBalance';
import AccountManagementIcon from '@material-ui/icons/Group';
import LogoutIcon from '@material-ui/icons/ExitToApp';

// Material-UI
import { makeStyles, Theme, darken, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { TypographyProps } from '@material-ui/core/Typography';

import Popover from '@material-ui/core/Popover';
import useMediaQuery from '@material-ui/core/useMediaQuery';


interface Props {
	
};

const HeaderIcons: React.FC<Props> = (props) => {
	const classes = useStyles();
	const history = useHistory();

	const primaryProps:TypographyProps = { variant: 'subtitle1' };

	// State
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [cartOpen, setCartOpen] = useState(false);

	// Operations
	const { mutate: logout } = useLogout();

	const theme = useTheme();	
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));

	// State
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	/*
	 * When location change close the search box if it is open
	 */
	history.listen((_) => {
		if(drawerOpen) {
			setDrawerOpen(false);
		}
	});

	const handleLogout = async (event: MouseEvent<HTMLElement | MouseEvent>) => {
		setDrawerOpen(false);

		try {
			await logout();
		}
		catch (error) {
			console.log('Unable to clear cache ', error);
		}
		if(window !== undefined)
			{window.location.href = LINKS.LOGIN;}
	};

	const handleNavigate = (route: string) => {
		setDrawerOpen(false);
		setAnchorEl(null);
		history.push(route);
	}

	const toggleDrawer = (e: MouseEvent<HTMLButtonElement>) => {
		if(drawerOpen) {
			setAnchorEl(null);
		} else {
			setAnchorEl(e.currentTarget);
		}
		setDrawerOpen(!drawerOpen);
	}

	return (<>
		<HeaderSearch/>
		<Sobo/>
		<div>
			<span className={classes.iconButton} data-open={drawerOpen} onClick={toggleDrawer}><IconButton color="inherit" aria-label="account"><AccountIcon/></IconButton></span>
			<Popover
				anchorEl={anchorEl}
				open={drawerOpen}
				anchorOrigin={{ vertical: 'bottom', horizontal: mobile ? 'left' : 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: mobile ? 'left' : 'right' }}
				onClose={() => {setDrawerOpen(false); setAnchorEl(null);}}
			>
				<>
				<List className={classes.accountMenu}>
					<ListItem button aria-label="profile" onClick={() => handleNavigate("/account")}>
						<ListItemIcon><ProfileIcon fontSize="small"/></ListItemIcon>
						<ListItemText primary="Profile" primaryTypographyProps={primaryProps} />
					</ListItem>
					<ListItem button aria-label="Change Password" onClick={() => handleNavigate("/account/change-password")}>
						<ListItemIcon><ChangePasswordIcon fontSize="small"/></ListItemIcon>
						<ListItemText primary="Change Password" primaryTypographyProps={primaryProps} />
					</ListItem>
					<Ability subject="PlanningSheets" action="read">
						<ListItem button aria-label="Planning Sheets" onClick={() => handleNavigate("/account/planning-sheets")}>
							<ListItemIcon><OrderSheetsIcon fontSize="small"/></ListItemIcon>
							<ListItemText primary="Planning Sheets" primaryTypographyProps={primaryProps} />
						</ListItem>
					</Ability>
					<ListItem button aria-label="Order History" onClick={() => handleNavigate("/account/order-history")}>
						<ListItemIcon><OrderHistoryIcon fontSize="small"/></ListItemIcon>
						<ListItemText primary="Order History" primaryTypographyProps={primaryProps} />
					</ListItem>
					<ListItem button aria-label="Order Favorites" onClick={() => handleNavigate("/account/order-favorites")}>
						<ListItemIcon><OrderFavoritesIcon fontSize="small"/></ListItemIcon>
						<ListItemText primary="Order Favorites" primaryTypographyProps={primaryProps} />
					</ListItem>
					<Ability subject="AccountStatement" action="read">
						<ListItem button aria-label="Account Statement" onClick={() => handleNavigate("/account/account-statement")}>
							<ListItemIcon><AccountStatementIcon fontSize="small"/></ListItemIcon>
							<ListItemText primary="Account Statement" primaryTypographyProps={primaryProps} />
						</ListItem>
					</Ability>
					<ListItem button aria-label="User Management" onClick={() => handleNavigate("/account/user-management")}>
						<ListItemIcon><AccountManagementIcon fontSize="small"/></ListItemIcon>
						<ListItemText primary="User Management" primaryTypographyProps={primaryProps} />
					</ListItem>
				</List>
				<Divider/>
				<List className={classes.accountMenu}>
					<ListItem button onClick={handleLogout}>
						<ListItemIcon><LogoutIcon fontSize="small"/></ListItemIcon>
						<ListItemText primary="Sign out" primaryTypographyProps={primaryProps} />
					</ListItem>
				</List>
			</>
			</Popover>
		</div>
		<Ability subject="Cart" action="create">
			<MiniCart open={cartOpen} handleOpen={(value:boolean) => setCartOpen(value)}/>
		</Ability>
	</>);
};

const useStyles = makeStyles((theme: Theme) => ({
	accountMenu: {
		'& > .MuiListItem-root': {
			padding: theme.spacing(0.5, 2),
			'& > .MuiListItemIcon-root': {
				minWidth: 34,
			},
		},
	},
	iconButton: {
		'&[data-open="true"]': {
			display: 'inline-block',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: darken(theme.palette.primary.dark, 0.05),
		},
	},
}));

export default HeaderIcons;
