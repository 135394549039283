import React, { useRef, useState, useEffect } from 'react';
import _isFunction from 'lodash/isFunction';

import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInDays,
  formatDistanceToNowStrict,
  format,
} from 'date-fns';

import { useOnScreen } from 'hooks/useOnScreen';
import { chatMessages_messages_nodes } from '__generated__/chatMessages';
import Typography from '@material-ui/core/Typography';
// #region Material-ui
import Avatar from '@material-ui/core/Avatar';
// #endregion Material-ui

type Props = {
  message: chatMessages_messages_nodes;
  userId: string;
  onScreenView?: (message: any) => void;
};

const fromNow = (value: string) => {
  if (differenceInDays(new Date(), new Date(value)) > 6)
    return format(new Date(value), 'MMM d, h:mm a');
  else if (differenceInDays(new Date(), new Date(value)) > 1)
    return format(new Date(value), 'iii h:mm a');
  else if (differenceInDays(new Date(), new Date(value)) > 0)
    return format(new Date(value), `'Yesterday' h:mm a`);
  else if (differenceInMinutes(new Date(), new Date(value)) > 30)
    return format(new Date(value), 'h:mm a');
  else if (differenceInSeconds(new Date(), new Date(value)) > 5)
    return formatDistanceToNowStrict(new Date(value))
      .replace('minute', 'min')
      .replace('minutes', 'min')
      .replace('seconds', 'sec');

  return 'Now';
};

const getInitials = (name: string) => {
  if (!name) return;
  const names = name.split(' ');
  return names[0].substr(0, 1) + names[names.length - 1].substr(0, 1);
};

export const MessageItem = (props: Props) => {

  const { message, userId, onScreenView } = props;
  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);

  useEffect(() => {
    if (_isFunction(onScreenView)) {
      onScreenView(message);
    }
  }, [isOnScreen]);
  // State
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefresh((value) => !value);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div key={message.id} className="item" ref={elementRef}>
      <div className="dot" data-read={message?.isRead}></div>
      <div className="detail" data-me={userId === message?.from?.id}>
        <Avatar className="avatar" data-me={userId === message?.from?.id}>
          {getInitials(message?.from?.fullName)}
        </Avatar>
        <Typography variant="subtitle2" color="textPrimary">
          <b>{message?.from?.fullName}</b>
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          data-refresh={refresh}
        >
          {fromNow(message.created)}
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          className="message"
          dangerouslySetInnerHTML={{ __html: message.body }}
        ></Typography>
      </div>
    </div>
  );
};