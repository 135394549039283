/**
 * Detects if user has new / unread message. 
 * If true the favicon icon will be changed.
 * This gives user a visual indicator about 
 * new message. 
 *
 */

import { useEffect, useState } from 'react';

export const useFavIconNotification = (initialCount?: number) => {
  const [unreadCount, setUnreadCount] = useState(initialCount || 0);
  const [hasNewMessages, setHasNewNewssages] = useState(false);
  useEffect(() => {
    const icon: any = document.querySelector("link[rel*='icon']");
    if (icon) {
      icon.href =
        unreadCount > 0 ? 'horizon-icon-badge.png' : 'horizon-icon.png';
    }

    setHasNewNewssages(unreadCount > 0);
  }, [unreadCount]);

  return { setUnreadCount, hasNewMessages };
};
