import { onError } from '@apollo/client/link/error';

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      console.error('Encountered graphql error', graphQLErrors);
      // eslint-disable-next-line
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        switch (extensions && extensions.code) {
          case 'UNAUTHENTICATED': {
            // TODO: Try token refresh here
          }
        }
        console.error(
          `[GraphQL error]: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }

    if (networkError) {
      console.error(`[Network Error]: ${networkError}`);
    }
  }
);
