import { gql, useQuery } from '@apollo/client';
import { cartHeaderFragment } from '../fragments/cart';
import { cartLinesFragment } from '../fragments/cartLines';

export const GET_MINI_CART = gql`
  query miniCart {
    cart {
      ...CartHeaderFragment
      lines {
        ...CartLinesFragment
      }
    }
  } 
  ${cartHeaderFragment}
  ${cartLinesFragment}
`;

export const useMiniCart = () => {
  const { data, loading, error } = useQuery(GET_MINI_CART);

  return { data, loading, error };
};
