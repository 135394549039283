import React, { useState, ChangeEvent } from 'react';
import _debounce from 'lodash/debounce';

// Icons
import SearchIcon from '@material-ui/icons/Search';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Chip from '@material-ui/core/Chip';


interface Props {
	count: number;
	handleSearch: Function;
};

const SoboSearchInput: React.FC<Props> = (props) => {
	const classes = useStyles();

	// State
	const [keyword, setKeyword] = useState('');
	

	const fetchTypeahead = async (value:string) => {
		props.handleSearch(value);
	};

	const [debouncedCall] = useState(() => _debounce(fetchTypeahead, 300));

	const handleInputChange = (e:ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setKeyword(e.currentTarget.value);
		// debouncedCall(e.currentTarget.value);
		props.handleSearch(e.currentTarget.value);
	};

	return (<>
		<InputBase
			autoFocus
			fullWidth
			defaultValue={keyword}
			className={classes.inputBase}
			placeholder="Search accounts..."
			onChange={handleInputChange}
			startAdornment={<SearchIcon color="disabled"/>}
			endAdornment={<Chip label={props.count} size="small"/>}
		/>
	</>);
};

const useStyles = makeStyles((theme: Theme) => ({
	inputBase: {
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: 4,
		paddingRight: theme.spacing(0.5),
		'& .MuiSvgIcon-root': {
			marginLeft: theme.spacing(1),
		},
		'& > input': {
			borderRadius: 4,
			backgroundColor: theme.palette.common.white,
			padding: 8,
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			fontSize: 14,
		},
		'& > .MuiChip-root': {
			borderRadius: theme.shape.borderRadius,
		},
	},
	progress: {
		position: 'absolute',
		top: 2,
		right: theme.spacing(0.5),
	}
}));

export default SoboSearchInput;
