import { gql, useQuery } from '@apollo/client';
import _get from 'lodash/get';

export const SEARCH_SOBO = gql`
	query searchSobo($accountId: ID, $input: SoboAccountListInput) {
		soboAccountList(accountId: $accountId, input: $input) {
			recordCount
    		pageInfo {
      			top
      			skip
				search
				sort
				sortDesc
				startCursor
				endCursor
				hasPreviousPage
				hasNextPage
    		}
    		nodes {
      			id
      			name
      			code
				defaultBillingAddress {
					id
					city
					stateName
				}
    		}
			
  		}
	}
`;

export const useSearchSobo = () => {
	const variables = {
		input: {
			top: 100,
			accountLinkType: 'SOBO'
		}
	};
	const { data, loading, error } = useQuery(SEARCH_SOBO, { variables });

	const list = _get(data, 'soboAccountList.nodes', []);

	return { data:list, loading, error };
};
