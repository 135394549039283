import React, { createContext, useReducer, useContext } from 'react';
import snackbarReducer, { initialState, State } from './SnackbarReducer';
import identity from 'lodash/fp/identity';
interface Snackbar extends State {
  addNotification: (payload: any) => void;
  dismiss: (id: string) => void;
  clearAll: () => void;
}
const SnackbarContext = createContext<Snackbar>({
  ...initialState,
  addNotification: identity,
  dismiss: identity,
  clearAll: identity,
});

interface Props {}

const SnackbarProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(snackbarReducer, initialState);

  const dismiss = (id: string) => {
    dispatch({
      type: 'dismissNotification',
      payload: {
        id,
      },
    });
  };

  const addNotification = (payload: any) => {
    dispatch({
      type: 'addNotification',
      payload,
    });
  };

  const clearAll = () => {
    dispatch({
      type: 'clearAll',
    });
  };

  const value = {
    notificationList: state.notificationList,
    notificationCount: state.notificationCount,
    addNotification,
    dismiss,
    clearAll,
  };
  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};

const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within SnackbarContext');
  }

  return context;
};

export { useSnackbar };

export default SnackbarProvider;
