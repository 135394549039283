import { gql } from '@apollo/client';

export const attributeValueFragment = gql`
	fragment AttributeValueFragment on AttributeValue {
		id
		AttributeId
		AttributeValueId
		AttributeValue
		Count
		IsSelected
		Rank
	}
`;
