import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GoogleAnalytics from 'utils/GoogleAnalytics';

// Components
import SiteMetaData from 'components/SiteMetaData';

// src
import { createBrowserHistory } from 'history';
import Routes from './routes';

// Material-UI
import CssBaseline from '@material-ui/core/CssBaseline';

interface Props {
	
}

export const history = createBrowserHistory();

const App: React.FC<Props> = (props) => {
	return (<>
		<SiteMetaData/>
		<Router>
			<Routes />
			<CssBaseline />
			<GoogleAnalytics/>
		</Router>
	</>);
};

export default App;
