/**
 * OperationQueuing is used well for queuing graphql request.
 * The main feature is to replay all the request that were
 * triggered during token request but could not be send to the 
 * server as they will trigger multiple `refreshToken` requests.
 * In that scenario race conditions could arise and break
 * the client because multiple `accessTokens` will be generated.
 */
import { Operation, Observable, NextLink, FetchResult } from '@apollo/client';

interface SubscriberInterface {
  next?: (result: FetchResult) => void;
  error?: (error: Error) => void;
  complete?: () => void;
}

interface QueuedRequest {
  operation: Operation;
  forward?: NextLink;
  subscriber?: SubscriberInterface;
  observable?: Observable<FetchResult>;
  next?: (result: FetchResult) => void;
  error?: (error: Error) => void;
  complete?: () => void;
}


export class OperationQueuing {
  public queuedRequests: QueuedRequest[] = [];

  constructor() {
    this.queuedRequests = [];
  }

  public enqueueRequest(request: QueuedRequest): Observable<FetchResult> {
    const requestCopy = { ...request };

    requestCopy.observable =
      requestCopy.observable ||
      new Observable<FetchResult>((observer) => {
        this.queuedRequests.push(requestCopy);

        if (typeof requestCopy.subscriber === 'undefined') {
          requestCopy.subscriber = {};
        }

        requestCopy.subscriber.next =
          requestCopy.next || observer.next.bind(observer);
        requestCopy.subscriber.error =
          requestCopy.error || observer.error.bind(observer);
        requestCopy.subscriber.complete =
          requestCopy.complete || observer.complete.bind(observer);
      });

    return requestCopy.observable;
  }

  public consumeQueue(): void {
    this.queuedRequests.forEach((request) => {
      // @ts-ignore
      request.forward(request.operation).subscribe(request.subscriber);
    });
    this.queuedRequests = [];
  }
}
