import { gql, useQuery } from '@apollo/client';
import _get from 'lodash/get';


export const GET_SETTINGS = gql`
	query APP_SETTINGS {
		settings {
			logoPath
			siteName
		}
	}
`;

export const useSettings = () => {
	const { data, loading, error } = useQuery(GET_SETTINGS);

	const settings = _get(data, 'settings', {});

	return { data: settings, loading, error };
};
