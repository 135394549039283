import { gql, useQuery } from '@apollo/client';
import _get from 'lodash/get';
import { initialLoad, initialLoad_settings } from '__generated__/initialLoad';

// Fragments
import { meUserFragment } from 'operations/fragments/meUser';
import { cartHeaderFragment } from 'operations/fragments/cart';

export const ME_USER = gql`
  query me {
    user: me {
      ...MeUserFragment
    }
  }
  ${meUserFragment}
`;
export const CART_LOAD = gql`
  query cartInitialLoad {
    cart {
      ...CartHeaderFragment
    }
  }
  ${cartHeaderFragment}
`;

export const INITIAL_LOAD = gql`
  query initialLoad {
    user: me {
      ...MeUserFragment
    }
    alerts {
      pricing
      cutoff {
        stateId
        stateName
        cutoffTime
      }
    }
    session {
      soboUser {
        firstName
        lastName
        email
        account {
          id
          code
          name
          email
          defaultBillingAddress {
            id
            city
            stateName
          }
        }
      }
    }
    settings {
      resultsPerPage
      currency
      siteName
      logoPath
      themeName
      taxesOnShipping
      products {
        resultsPerPage
      }
      customers {
        resultsPerPage
      }
      selectedTheme {
        themeId
        colorPrimary
        colorSecondary
        textPrimary
        textSecondary
        error
        warning
        info
        success
        background
        panelBackground
        h1
        h2
        h3
        h4
        h5
        h6
        body1
        body2
        h1Font
        h2Font
        h3Font
        h4Font
        h5Font
        h6Font
        body1Font
        body2Font
        xsBreakpoint
        smBreakpoint
        mdBreakpoint
        lgBreakpoint
        xlBreakpoint
        defaultTheme
        themeName
        fontFamily
      }
      googleAnalyticsId
    }
  }
  ${meUserFragment}
`;

export const useInitialLoad = () => {
  const { data, loading, error } = useQuery<initialLoad>(INITIAL_LOAD);

  const site = {
    settings: _get(data, 'settings', {} as initialLoad_settings),
    alerts: _get(data, 'alerts', {}),
    session: {
      isLoggedIn: !!data?.user,
      user: _get(data, 'user', null),
      sobo: _get(data, 'session.soboUser', null),
    },
  };

  return { site, loading, error };
};
