import { gql } from '@apollo/client';

export const pageInfoFragment = gql`
  fragment PageInfoFragment on PageInfo {
    hasPreviousPage
    hasNextPage
    endCursor
    currentPage
    sort
    sortDesc
  }
`;
