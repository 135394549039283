import { gql } from '@apollo/client';

export const GET_ACCOUNT_INFORMATON = gql`
  query account {
    account {
      id
      dbaName
      name
      email
      originCode
    }
  }
`;
