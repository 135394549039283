export const formatCurrency = (price: any, forceParentheses: boolean = false) => {
	if(isNaN(price))
		{return '';}

	var n:any = price, 
	c:any = isNaN(c = Math.abs(c)) ? 2 : c, 
	d:any = d == undefined ? '.' : d, 
	t:any = t == undefined ? ',' : t, 
	s:any = n < 0 ? '- ' : '', 
	i:any = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))), 
	j:any = (j = i.length) > 3 ? j % 3 : 0;

	if(forceParentheses) {
		return '($'+ (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '') +')';
	}
	return s +'$'+ (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
};
