import React, { useState, ChangeEvent, KeyboardEvent } from 'react';

// Operations
import { usePostMessage } from 'operations/mutations/postMessage';

// Icons
import SendIcon from '@material-ui/icons/Send';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

interface Props {
  chatGroupId: string;
}

const NewMessage: React.FC<Props> = (props) => {
  const classes = useStyles();

  // State
  const [newMessage, setNewMessage] = useState('');

  // Operations
  const {
    mutate: postMessage,
    loading,
    error,
  } = usePostMessage(props.chatGroupId);

  const handleNewMessage = async () => {
    try {
      if (newMessage !== '') {
        await postMessage(newMessage.replace(/\n/g, '<br/>'));
        setNewMessage('');
      }
    } catch {}
  };

  const handleEnter = (
    event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      handleNewMessage();
    }
  };

  return (
    <Box padding={0.5}>
      <InputBase
        autoFocus
        fullWidth
        multiline
        placeholder="New message..."
        className={classes.input}
        endAdornment={
          <IconButton onClick={handleNewMessage}>
            <SendIcon color="action" />
          </IconButton>
        }
        value={newMessage}
        onKeyDown={handleEnter}
        onChange={(
          e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | undefined>
        ) => setNewMessage(e.currentTarget.value)}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    padding: 0,
    '& > .MuiInputBase-input': {
      whiteSpace: 'break-spaces',
      padding: theme.spacing(1),
    },
  },
}));

export default NewMessage;
