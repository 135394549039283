import addAttentionMessage from './addAttentionMessage';
import popAttentionMessage from './popAttentionMessage';

import addAddToCartMessage from './addAddToCartMessage';
import popAddToCartMessage from './popAddToCartMessage';

import {
  lowAttentionMessagesVar,
  mediumAttentionMessagesVar,
  highAttentionMessagesVar,
  addToCartMessagesVar,
} from 'operations/typePolicies';

export const messageMutations = {
  addLowAttentionMessage: addAttentionMessage(lowAttentionMessagesVar),
  popLowAttentionMessage: popAttentionMessage(lowAttentionMessagesVar),
  addMediumAttentionMessage: addAttentionMessage(mediumAttentionMessagesVar),
  popMediumAttentionMessage: popAttentionMessage(mediumAttentionMessagesVar),
  addHighAttentionMessage: addAttentionMessage(highAttentionMessagesVar),
  popHighAttentionMessage: popAttentionMessage(highAttentionMessagesVar),
  addAddToCartMessage: addAddToCartMessage(addToCartMessagesVar),
  popAddToCartMessage: popAddToCartMessage(addToCartMessagesVar),
};
