
import { gql } from '@apollo/client';

export const groupChatFragment = gql`
  fragment GroupChatFragment on ChatGroup {
    id
    code
    name
    hasUnreadMessages @client
  }
`;
