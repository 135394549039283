import { gql } from '@apollo/client';

export const GET_ADD_TO_CART_MESSAGES = gql`
  query GetAddToCartMessages {
    addToCartMessages @client {
      id
      name
      img {
        cdnURL
      }
      price
      quantity
      attributes {
        name
        value
      }
      seoName
    }
  }
`;
