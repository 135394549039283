/**
 * @description
 * Client identification send to GraphQL server
 * as request headers. We use this information
 * for session creation and to distinguish
 * storefront from the studio. If not provided server
 * will create e4cs cookie for the application. 
 * The consequence of creating cookie as E4CS is that 
 * the session will be taken over by the last logged in client.
 * 
 */
export const applicationInformation = {
    'client-name': 'storefront',
    'client-version': '1.0.0'
};