import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import isFunction from 'lodash/isFunction';
import { Input } from 'components/FormElements';

// #region Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CreateWishListInput } from '__generated__/globalTypes';
import { useUpsertWishlist } from 'operations/mutations/upsertWishList';
// #endregion Material-UI

import { messageMutations } from 'operations/mutations/messages';
import Grid from '@material-ui/core/Grid';

const schema = Yup.object({
  name: Yup.string()
    .required('Please enter list name.')
    .min(4, 'Must be at least 4 characters long!')
    .max(75, 'Must be less than 75 characters!'),
  comment: Yup.string()
    .max(150, 'Must be less than 150 characters!'),
});

interface CreateMyListControls {
  name: string;
  description: string;
}

interface Props {
  handleCancel: () => void;
  handleSuccess: (form: CreateMyListControls) => void;
}

const CreateMyListForm = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const upsertWishlist = useUpsertWishlist();

  const handleCancel = () => {
    if (isFunction(props.handleCancel)) {
      props.handleCancel();
    }
  };

  // const handleSuccess = () => {
  //     if (isFunction(props.handleSuccess)) {
  //         props.handleSuccess();
  //     }

  //     formControls = {
  //         name: new FormControl('', true, /\w+/),
  //         description: new FormControl('', false, /\w+/),
  //     };
  //     setFormState(formControls);
  // };

  /**
   * Handles the state and change events of all input boxes
   * @param value Value of the input box being modified
   * @param control Name of the form control for the input box being modified
   */
  // const handleFormChange = <T extends keyof CreateMyListControls>(
  //     value: string,
  //     control: T
  // ) => {
  //     const fc: BaseFormControl = formState[control];

  //     fc.value = value;
  //     fc.hasError = false;
  //     fc.isTouched = true;

  //     if (fc.isRequired && !fc.value) {
  //         fc.hasError = true;
  //     }
  //     if (fc.validation && !fc.hasError) {
  //         const regex = fc.validation as RegExp;
  //         const matches = regex.test(fc.value);

  //         fc.hasError = !matches;
  //     }

  //     setFormState({
  //         ...formState,
  //         [control]: fc,
  //     });
  // };

  // const handleSubmit = <T extends keyof CreateMyListControls>(
  //     e: React.FormEvent<HTMLFormElement>
  // ) => {
  //     e.preventDefault();

  //     const formControlKeys = Object.keys(formState);
  //     formControlKeys.forEach((key) => {
  //         const controlKey = key as T;
  //         const control = formState[controlKey];
  //         handleFormChange(control.value, controlKey);
  //     });

  //     const erroredControls = formControlKeys.filter(
  //         (key) => formState[key as T].hasError
  //     );

  //     if (!erroredControls.length) {
  //         const newListRequest: CreateMyListRequest = {
  //             Name: formState.name.value,
  //             Comment: formState.description.value,
  //             onSuccess: handleSuccess,
  //         };

  //         console.log('new list data ', newListRequest);
  //         dispatch(createMyList(newListRequest));
  //     }
  // };

  const handleCreateWishlist = (formValues: CreateWishListInput) => {
    upsertWishlist
      .mutate({ variables: { wishlist: Object.assign(formValues, {isEditable: true}) } })
      .then(() => {
        messageMutations.addLowAttentionMessage(
          t('myAccountPage.myList.message.success'),
          'outlined',
          'success'
        );
        handleCancel();
      })
      .catch(() => {
        messageMutations.addLowAttentionMessage(
          t('myAccountPage.myList.message.fail'),
          'alert',
          'error'
        );
        handleCancel();
      });
  };

  return (
    <Formik
      initialValues={{
        name: t('myAccountPage.myList.text'),
        comment: '',
        isPublic: false,
      }}
      onSubmit={(values: CreateWishListInput) => {
        handleCreateWishlist({ ...values, isPublic: !values.isPublic });
      }}
      validationSchema={schema}
    >
      {(props: FormikProps<CreateWishListInput>) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                autoFocus
                variant="outlined"
                margin="dense"
                id="name"
                name="name"
                label="Planning Sheet Name:"
                type="text"
                inputProps={{ maxLength: 75 }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                margin="dense"
                variant="outlined"
                id="comment"
                name="comment"
                label="Planning Sheet Description:"
                type="text"
                inputProps={{ maxLength: 150 }}
                fullWidth
                InputLabelProps={{ shrink: true }}
                rows={5}  
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            {/* <Field type="checkbox" name="isPublic" />
            {t(`myAccountPage.myList.makePrivate`)}
            <div className={classes.buttonSpacer} /> */}
            <Button onClick={handleCancel} variant="outlined" color="primary" aria-label="cancel">
              {t('cancel.btn')}
            </Button>
            &nbsp;&nbsp;
            <Button type="submit" color="primary" variant="contained" disableElevation aria-label="create" disabled={!props.isValid}>
              {t('create.btn')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
    // <form noValidate onSubmit={handleSubmit}>
    //     {/* <TextField
    //         autoFocus
    //         margin="dense"
    //         id="name"
    //         name="name"
    //         label="List Name"
    //         type="text"
    //         fullWidth
    //         value={formState.name.value}
    //         onChange={(e) => handleFormChange(e.target.value, 'name')}
    //         error={formState.name.hasError && formState.name.isTouched}
    //         helperText={
    //             formState.name.hasError && 'Please enter a valid name'
    //         }
    //     /> */}
    //     {/* <TextField
    //         margin="dense"
    //         id="description"
    //         name="description"
    //         label="List Description"
    //         type="text"
    //         value={formState.description.value}
    //         onChange={(e) =>
    //             handleFormChange(e.target.value, 'description')
    //         }
    //         error={
    //             formState.description.hasError &&
    //             formState.description.isTouched
    //         }
    //         helperText={
    //             formState.description.hasError &&
    //             'Please enter a valid description'
    //         }
    //         fullWidth
    //         rows={5}
    //     /> */}

    // </form>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonSpacer: {
    flex: '1 0 0px',
  },
  buttons: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export default CreateMyListForm;
