import { useReducer, useEffect, DispatchWithoutAction } from 'react';
import { useHistory } from 'react-router-dom';
import _pickBy from 'lodash/pickBy';
import _merge from 'lodash/merge';
import _omit from 'lodash/omit'
import Filters from 'components/Filters';

export interface ListFilter {
	search: string | null;
	sort: string | null;
	sortDesc: boolean | null;
	top: number | null;
	skip: number | null;
	filters?: any;
};

export const listFilterParams = {
    top: 50,
	first: 10,
	last: 10
};

export const useFilterToQueryString = (filter:ListFilter) => {
	let parsedFilter:any = _pickBy(filter);

	// if(parsedFilter.filters?.length == 1 && parsedFilter.filters[0].key == 'item' && parsedFilter.filters[0].value == "PRODUCT" ) {
	// 	parsedFilter.filters = [];
	// 	parsedFilter.item = null;
	// }

	if(!parsedFilter.sort && parsedFilter.sortDesc)
		{delete parsedFilter.sortDesc;}

	if(parsedFilter.filters) {
		for(let i = 0; i < parsedFilter.filters.length; i++)
			{parsedFilter[parsedFilter.filters[i].key] = parsedFilter.filters[i].value;}

		delete parsedFilter.filters;
	}

	return Object.keys(parsedFilter).map(key => `${key}=${parsedFilter[key]}`).join('&');
};

export const useQueryStringToFilter = () => {
	let queryStringObj:any = Object.fromEntries(new URLSearchParams(useHistory().location.search).entries());

	const reservedNames = '|top|skip|last|first|before|after|sort|sortDesc|search|';
	const numberNames = '|top|skip|last|first|';

	let filter:any = {};

	for(let key in queryStringObj) {
		if(reservedNames.indexOf(`|${key}|`) !== -1) {
			filter[key] = (numberNames.indexOf(`|${key}|`) !== -1) ? parseInt(queryStringObj[key], 10) : queryStringObj[key];
		}
		else {
			filter.filters = filter.filters || [];
			filter.filters.push({ key, value: queryStringObj[key] });
		}
	}

	return filter;
};

export function useFilterReducer (filterReducer:any, initialState:any = {}, queryString:boolean = false) {
	
	if(queryString)
		// eslint-disable-next-line react-hooks/rules-of-hooks
		{initialState = _merge({}, useQueryStringToFilter(), initialState);}

	let [filter, dispatch]:[any,DispatchWithoutAction] = useReducer(filterReducer, initialState);
	const history = useHistory();

	if(filter?.filters?.find((item: any) => item.key == 'product') && filter.filters.length == 1) {
		filter.filters = []
	}

	useEffect(() => {
		history.replace({
			pathname: history.location.pathname,
			// eslint-disable-next-line react-hooks/rules-of-hooks
			search: useFilterToQueryString(filter as ListFilter),
		});
	},[filter]);

	return [initialState, dispatch];
};
