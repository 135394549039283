import React, { useEffect, useRef } from 'react';

import { useSnackbar } from './../SnackbarProvider';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { clearTimeout } from 'timers';
interface Props {
  id: string;
  name: string;
  message?: string;
  handleOkAction?: (props: any) => void;
}

const HeaderSnackbar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const timeout = useRef<NodeJS.Timeout|null>(null);

  const { dismiss } = useSnackbar();

  useEffect(() => {
    timeout.current = setTimeout(() => {
      dismiss(props.id);
    }, 5000);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);
  const getInitials = (name: string) => {
    if (!name) return '';

    const names = name.split(' ');
    return names[0].substr(0, 1) + names[names.length - 1].substr(0, 1);
  };

  const handleClick = () => {
    if (typeof props.handleOkAction === 'function') {
      props.handleOkAction(props);
    }
  };
  return (
    <Box
      display="flex"
      flexGrow={1}
      alignItems="center"
      style={{ cursor: 'default' }}
      onClick={() => handleClick()}
    >
      <Avatar className={classes.avatar}>{getInitials(props.name)}</Avatar>
      <div>
        <Typography variant="subtitle2">
          <b>{props.name}</b>
        </Typography>
        <Typography variant="subtitle1" className={classes.message}>
          {props.message}
        </Typography>
      </div>
      <Box flexGrow={1} width={8} />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '400px',
    },
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(2),
    padding: 0,
    minWidth: 50,
  },
  message: {
    marginTop: theme.spacing(-0.5),
  },
}));

export default HeaderSnackbar;
