import React, { useEffect, useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import noop from 'lodash/noop';

import { SiteContext } from 'utils/SiteProvider';

import DefaultLayout from 'pages/_layouts/default';
import AuthLayout from 'pages/_layouts/auth';
import InnerLayout from 'pages/_layouts/inner';
import LoginLayout from 'pages/_layouts/login';
import CheckoutLayout from 'pages/_layouts/checkout';
import BlankLayout from 'pages/_layouts/blank';
import { messageMutations } from 'operations/mutations/messages';

import { ClaimBase } from 'interfaces/claim'

const layouts = new Map();
layouts.set('default', DefaultLayout);
layouts.set('auth', AuthLayout);
layouts.set('inner', InnerLayout);
layouts.set('checkout', CheckoutLayout);
layouts.set('blank', BlankLayout);
layouts.set('login', LoginLayout);

interface Props {
	component: any;
	isPrivate?: boolean;
	claims?: ClaimBase[];
	path?: string;
	exact?: boolean;
	layout?: string;
	location?: any;
	recursive?: boolean;
	getPropsFromPage?: (title: string) => void;
}

const RouteWrapper: React.FC<Props> = ({
	component: Component,
	isPrivate,
	claims,
	layout,
	location,
	recursive,
	getPropsFromPage,
	...rest
}: Props) => {
	const site:any = useContext(SiteContext);
	// const { data, loading, error } = useQuery(IS_LOGGED_IN);
	// const authenticated = currentUser && currentUser.exp > Date.now() / 1000;

	const userClaims = site?.session?.user?.claims;

	const authenticated = site?.session?.isLoggedIn;
	/** Redirect user to Login page if he tries to access a private route
	 * without authentication
	 */
	let watchPath = useLocation();

	useEffect(() => {
		messageMutations.popAddToCartMessage();
	}, [watchPath.pathname]);

	if (isPrivate && !authenticated) {
		return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
	}

	if(claims && claims.length > 0) {
		var hasClaim = false;
		for(let i = 0; i < userClaims.length; i++) {
			if(
				claims.filter(claim => userClaims[i].action === claim.action && userClaims[i].subject === claim.subject).length > 0
			) {
				hasClaim = true;
				break;
			}
		}
		if(!hasClaim) {
			return <Redirect to={{ pathname: '/404', state: { from: location } }} />;
		}
	}
	
	let Layout = AuthLayout;
	if (layout && layouts.has(layout)) {
		Layout = layouts.get(layout);
	}

	if(layout === undefined) {
		return (
			<Route
				{...rest}
				render={(props) => <Component {...props} getPropsFromPage={getPropsFromPage} />}
			/>
		);
	}

	return (
		<Route
			{...rest}
			render={(props) => (
				<Layout>
					<Component {...props} getPropsFromPage={getPropsFromPage} />
				</Layout>
			)}
		/>
	);
};

RouteWrapper.defaultProps = {
	getPropsFromPage: noop,
};
export default RouteWrapper;
