import React from 'react';
import isFunction from 'lodash/fp/isFunction';

// Local
import { Message, Notification, Multiple } from './SnackbarItems';
import { useSnackbar } from './SnackbarProvider';
// Icons
import CloseIcon from '@material-ui/icons/Close';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';

type TransitionProps = Omit<SlideProps, 'direction'>;
const transition = (props: TransitionProps) => (
  <Slide {...props} direction="down" />
);

interface Props {
  onAlertClose?: () => void;
}

const HeaderSnackbar: React.FC<Props> = (props) => {
  const { notificationList, notificationCount, dismiss, clearAll } =
    useSnackbar();

  const classes = useStyles();

  const handleCloseAlert = (event: any, reason: string) => {
    // if (reason === 'clickaway') return;
    if (isFunction(props.onAlertClose)) {
      props.onAlertClose();
    }
  };

  const notificationComponentFactory = (notification: any) => {
    if (notification.type === 'message') {
      return (
        <Message
          key={notification.id}
          id={notification.id}
          name={notification.title}
          message={notification.body}
          handleOkAction={notification.okAction}
        />
      );
    } else {
      return (
        <Notification
          id={notification.id}
          name={notification.title}
          type={notification.type?.code}
          orderId={notification.extendedFields?.orderId}
		  handleOpenNotifications={notification.handleOkAction}
		  timestamp={notification.created}
        />
      );
    }
  };
  const handleClose = (id: string) => {
    dismiss(id);
  };

  const handleClearAll = () => {
    clearAll();
  };
  const renderNotifications = (notificationList: Set<any>) => {
    const notificationArray = Array.from(notificationList);

    if (notificationList.size > 1) {
      return (
        <>
          <Multiple count={notificationList.size}>
            {notificationArray.map((notification) =>
              notificationComponentFactory(notification)
            )}
          </Multiple>
          <IconButton
            size="small"
            className={classes.closeButton}
            onClick={() => handleClearAll()}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      );
    } else {
      return notificationArray.map((notification) => (
        <>
          {notificationComponentFactory(notification)}
          <IconButton
            size="small"
            className={classes.closeButton}
            onClick={() => handleClose(notification.id)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      ));
    }
  };
  return (
    <Snackbar
      open={notificationCount > 0}
      onClose={handleCloseAlert}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={transition}
    >
      <div className={classes.alert}>
      {renderNotifications(notificationList)}
      </div>
    </Snackbar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    minWidth: 325,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
  },
  closeButton: {
    margin: theme.spacing(0, -0.5, 0, 0.5),
  },
  spacer: {
    width: theme.spacing(2),
  },
}));

export default HeaderSnackbar;
