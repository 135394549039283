import { useMutation } from '@apollo/client';

import { INITIAL_LOAD } from 'operations/queries/initalLoad';
import { GET_WISH_LIST } from 'operations/queries/getWishListList';

import {
  SET_SOBO_MODE as SELECT_SOBO,
  EXIT_SOBO_MODE as UNSELECT_SOBO,
} from 'operations/mutations/setSoboModeDrawer';
import { GET_MINI_CART } from 'operations/queries/getMiniCart';
import { GET_ACCOUNT_CONTACTS } from 'operations/queries/GET_ACCOUNT_WITH_CONTACTS';
import { ACCOUNT_STATEMENT_LIST } from 'operations/queries/accountStatement';

export const useSelectSobo = () => {
  const [mutate, { data, loading, error }] = useMutation(SELECT_SOBO);

  const customMutate = (id: string) => {
    return new Promise((resolve, reject) => {
      const variables = {
        id,
      };
      mutate({
        variables,
        refetchQueries: [
          { query: INITIAL_LOAD }, 
          { query: GET_WISH_LIST }, 
          { query: GET_MINI_CART }, 
          { query: GET_ACCOUNT_CONTACTS },
          { query: ACCOUNT_STATEMENT_LIST }
        ],
      })
        .then(resolve)
        .catch(reject);
    });
  };

  return { mutate: customMutate, data, loading, error };
};

export const useUnselectSobo = () => {
  const [mutate, { data, loading, error }] = useMutation(UNSELECT_SOBO);

  const customMutate = () => {
    return new Promise((resolve, reject) => {
      mutate({
        refetchQueries: [
          { query: INITIAL_LOAD }, 
          { query: GET_WISH_LIST }, 
          { query: GET_MINI_CART }, 
          { query: GET_ACCOUNT_CONTACTS },
          { query: ACCOUNT_STATEMENT_LIST }
        ],
      })
        .then(resolve)
        .catch(reject);
    });
  };

  return { mutate: customMutate, data, loading, error };
};
