import React, { useState } from 'react';
import _debounce from 'lodash/debounce';

// Local
import SoboSearchInput from './SoboSearchInput';

// Operations
import { useSearchSobo } from 'operations/queries/searchSobo';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';


interface Props {
	handleSelect: Function;
};

const SoboSearch: React.FC<Props> = (props) => {
	const classes = useStyles();

	// State
	const [keyword, setKeyword] = useState('');

	// Operations
	const { data:list, loading } = useSearchSobo();


	const getInitials = (name:string) => {
		if(!name || name.indexOf(' ') === -1)
			{return '';}
		
		const nameSplit = name.split(' ');
		return `${nameSplit[0]?.substr(0, 1) || ''}${nameSplit[1]?.substr(0, 1) || ''}`;
	};
	
	const keywordUp = keyword.toUpperCase();
	const filteredList = list.filter((obj:any) => {
		if(obj.name?.toUpperCase().indexOf(keywordUp) !== -1)
			{return true;}
		if(obj.code?.toUpperCase().indexOf(keywordUp) !== -1)
			{return true;}
		return false;
	});

	if(loading) {
		return (
			<div className={classes.loading}>
				<Skeleton variant="rect" height={40}/>
				<Skeleton variant="rect" height={40}/>
				<Skeleton variant="rect" height={40}/>
				<Skeleton variant="rect" height={40}/>
			</div>
		);
	};

	return (<>
		<Box padding={1.5}>
			<SoboSearchInput
				count={filteredList?.length}
				handleSearch={(value:string) => setKeyword(value)}
			/>
		</Box>
	
		{filteredList?.length > 0 ? (
			<List className={classes.list} disablePadding>
				{filteredList?.map((option:any) => (
					<ListItem key={option?.id} button divider dense onClick={() => props.handleSelect(option?.id)}>
						<ListItemAvatar className={classes.avatar}><Avatar>{getInitials(option?.name)}</Avatar></ListItemAvatar>
						<ListItemText
							primaryTypographyProps={{ variant: 'body1' }}
							secondaryTypographyProps={{ component: 'div' }}
							primary={option?.name || ''}
							secondary={<>
								<div>
									<Typography component="div" variant="subtitle2" display="inline" color="textSecondary">Account #:</Typography>&nbsp;&nbsp;
									<Typography component="div" variant="subtitle2" display="inline" color="textPrimary">{option?.code}</Typography>
								</div>
								<div>
									<Typography component="div" variant="subtitle2" display="inline" color="textSecondary">Location:</Typography>&nbsp;&nbsp;
									<Typography component="div" variant="subtitle2" display="inline" color="textPrimary">{option?.defaultBillingAddress?.city}, {option?.defaultBillingAddress?.stateName}</Typography>
								</div>
							</>}
						/>
					</ListItem>
				))}
			</List>
		):(
			<Box paddingTop={2} paddingBottom={4}>
				<Typography variant="subtitle2" color="textSecondary" display="block" align="center"><i>No records found</i></Typography>
			</Box>
		)}
	</>);
};

const useStyles = makeStyles((theme: Theme) => ({
	loading: {
		padding: theme.spacing(2),
		'& > .MuiSkeleton-root': {
			marginBottom: theme.spacing(1),
			'&:last-child': {
				marginBottom: 0,
			},
		},
	},
	list: {
		maxHeight: 'calc(100vh - 300px)',
		overflow: 'auto',
		borderTop: `1px solid ${theme.palette.divider}`,
	},
	avatar: {
		'& > .MuiAvatar-root': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		}
	},
	selectedDetails: {
		'& .MuiTypography-colorTextSecondary': {
			marginRight: theme.spacing(1),
		},
	},
}));

export default SoboSearch;
