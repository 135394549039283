import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import GlobalLoading from 'components/GlobalLoading';
import RouteGuard from './Route';

// TODO: In the next iteration make it more dynamic.
// Redis can be used for serving the page definition
const pages = [
  {
    id: 1,
    name: 'Home',
    path: '/',
    componentPath: 'home',
    isPrivate: true,
    claims: [],
    exact: true,
    layout: '',
  },
  {
    id: 2,
    name: 'Login',
    componentPath: 'login',
    path: '/login',
    isPrivate: false,
    claims: [],
    layout: 'login',
  },
  {
    id: 3,
    name: 'Registration',
    componentPath: 'register',
    path: '/registration',
    isPrivate: false,
    claims: [],
    layout: 'default',
  },
  {
    id: 3,
    name: 'Sign-Up',
    componentPath: 'sign-up',
    path: '/sign-up',
    isPrivate: false,
    claims: [],
    layout: 'default',
  },
  {
    id: 4,
    name: 'ForgotPassword',
    componentPath: 'forgot-password',
    path: '/forgot-password',
    isPrivate: false,
    claims: [],
    layout: 'default',
  },
  {
    id: 5,
    name: 'ResetPassword',
    componentPath: 'reset-password',
    path: '/reset-password',
    isPrivate: false,
    claims: [],
    layout: 'default',
  },
  {
    id: 6,
    name: 'ProductList',
    componentPath: 'product-list',
    path: '/product-list/:categoryId+',
    isPrivate: true,
    claims: [],
    layout: 'auth',
  },
  {
    id: 7,
    name: 'ProductDetail',
    componentPath: 'product-detail',
    path: '/product-detail/:id',
    isPrivate: true,
    claims: [],
    layout: 'auth',
  },
  {
    id: 8,
    name: 'SearchResults',
    componentPath: 'search-results',
    path: '/search',
    isPrivate: true,
    claims: [],
    layout: 'auth',
  },
  {
    id: 9,
    name: 'Cart',
    componentPath: 'cart',
    path: '/cart',
    isPrivate: true,
    claims: [
      {
        subject: 'Cart',
        action: 'create',
      },
    ],
    layout: 'auth',
  },
  {
    id: 10,
    name: 'Checkout',
    componentPath: 'checkout',
    path: '/checkout',
    isPrivate: true,
    claims: [
      {
        subject: 'Order',
        action: 'create',
      },
    ],
    layout: 'checkout',
  },
  {
    id: 11,
    name: 'Confirmation',
    componentPath: 'confirmation',
    path: '/confirmation/:orderNumber',
    isPrivate: true,
    claims: [
      {
        subject: 'Order',
        action: 'create',
      },
    ],
    layout: 'checkout',
  },
  {
    id: 12,
    name: 'Account',
    componentPath: 'account',
    path: '/account',
    isPrivate: true,
    claims: [],
    layout: '',
  },
  {
    id: 13,
    name: 'MediaGallery',
    componentPath: 'media-gallery',
    path: '/media-library',
    isPrivate: true,
    claims: [],
    layout: '',
  },
  {
    id: 14,
    name: 'AboutUs',
    componentPath: 'about-us',
    path: '/about-us',
    isPrivate: true,
    claims: [],
    layout: '',
  },
  {
    id: 15,
    name: 'ContactUs',
    componentPath: 'contact-us',
    path: '/contact-us',
    isPrivate: true,
    claims: [],
    layout: '',
  },
  {
    id: 16,
    name: 'PrivacyPolicy',
    componentPath: 'privacy-policy',
    path: '/privacy-policy',
    isPrivate: true,
    claims: [],
    layout: '',
  },
  {
    id: 17,
    name: '',
    componentPath: 'NotFound',
    path: '/404',
    isPrivate: false,
    claims: [],
    layout: 'checkout',
  },
  {
    id: 18,
    name: 'FAQ',
    componentPath: 'faq',
    path: '/faq',
    isPrivate: true,
    claims: [],
    layout: '',
  },
  {
    id: 19,
    name: 'Tutorial',
    componentPath: 'tutorial',
    path: '/tutorial',
    isPrivate: true,
    claims: [],
    layout: '',
  },
  {
    id: 19,
    name: 'Chat',
    componentPath: 'chat',
    path: '/chat',
    isPrivate: false,
    claims: [],
    layout: 'blank',
  },
];
const LoadingMessage = () => <GlobalLoading delay={0}>Loading</GlobalLoading>;

export default function Routes() {
  return (
    <Suspense fallback={<LoadingMessage />}>
      <Switch>
        {pages.map((page) => (
          <RouteGuard
            key={page.id}
            path={page.path}
            layout={page.layout}
            component={React.lazy(() => import(`pages/${page.componentPath}`))}
            isPrivate={page.isPrivate}
            claims={page.claims}
            exact={page.exact}
          />
        ))}
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}
