import { gql } from '@apollo/client';

import { cartLinesFragment, orderAddressFragment } from '../fragments';

export const GET_CHECKOUT_PAGE = gql`
    query checkout {
        currentPayment {
            transaction_type
            card_number
            oar_data
            txn_id
            ps2000_data
            exp_date
            result_message
            card_short_description
            token
        }
        isLoggedIn @client
        user @client {
            id
            firstName
            lastName
			email
        }
        cart {
            id
            itemCount
            subtotal
            total
            shippingMethodId
            taxTotal
            freightTotal
            email
            selectedShipping @client {
				id
			}
            orderShippingAddressId
            orderBillingAddressId
            orderShippingAddress @client {
                ...OrderAddressFragment
            }
            orderBillingAddress @client {
                ...OrderAddressFragment
            }
            addresses {
                ...OrderAddressFragment
            }
            lines {
                ...CartLinesFragment
            }
        }
    }
    ${cartLinesFragment},
    ${orderAddressFragment}
`;
