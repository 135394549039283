import { gql } from '@apollo/client';

export const GET_SORT = gql`
  query GET_SORT($handle: String) {
    settingEnumByHandle(handle: $handle) {
      id
      DisplayName
      Code
    }
  }
`;
