import { gql, useMutation } from '@apollo/client';
import * as setCartPaymentTypes from '__generated__/setCartPayment';

export const SET_CART_PAYMENT = gql`
  mutation setCartPayment($payment: CartPaymentTokenInput!) {
    setCartPayment(payment: $payment) {
      transaction_type
    }
  }
`;

export const useSetCartPayment = () => {
  const [mutate, { data, error }] = useMutation<
    setCartPaymentTypes.setCartPayment,
    setCartPaymentTypes.setCartPaymentVariables
  >(SET_CART_PAYMENT);

  return { mutate, data, error };
};
