type Action =
  | { type: 'dismissNotification'; payload: { id: string } }
  | { type: 'addNotification'; payload: Notification }
  | { type: 'clearAll' };

interface Notification {
  id: string;
  title: string;
  body?: string;
  type: 'notification' | 'message';
  timeout?: number; // If provided, notification will auto dismiss after specific timeout
  okAction?: (args: any[]) => void;
  cancelAction?: (args: any[]) => void;
}

export type State = {
  notificationList: Set<Notification>;
  notificationCount: Readonly<number>;
};

export const initialState: State = {
  notificationList: new Set(),
  get notificationCount() {
    return this.notificationList.size;
  },
};

const snackbarReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'addNotification': {
      state.notificationList.add(action.payload);

      return {
        ...state,
        notificationList: state.notificationList,
        notificationCount: state.notificationList.size,
      };
    }
    case 'dismissNotification': {
      const { id } = action.payload;
      const toRemove = Array.from(state.notificationList).find(
        (notification: any) => notification.id === id
      );
      if (toRemove) {
        state.notificationList.delete(toRemove);
      }
      return { ...state, notificationCount: state.notificationList.size };
    }
    case 'clearAll': {
      state.notificationList.clear();
      return {...state, notificationCount: state.notificationList.size}
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};

export default snackbarReducer;
