import React from 'react';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Logo from 'components/Logo';

// Material-UI
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import useQueryString from 'utils/useQueryString';


import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


interface Props {
	children: React.ReactNode;
};

const LoginLayout: React.FC<Props> = ({ children }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const infoRef = useRef<null | HTMLDivElement>(null)

	const executeScroll = () => infoRef?.current?.scrollIntoView();

	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));

	const queryString: any = useQueryString();

	useEffect(() => {
		if(mobile && queryString.signUpLink) {
			setTimeout(() => {
				executeScroll();
			}, 100);
		}
	});

	//if(mobile && queryString.signUpLink) {

	//}

	return (
		<div className={classes.root}>
			<Container maxWidth="lg">
				<Grid container spacing={4} className={classes.grid}>
					<Grid item md={4} xs={12} className={classes.info}>
						<div ref={infoRef}>
							<Paper className={classes.paper}>
								<Typography variant="h5" align="center" gutterBottom>{t('loginPage.info.title.a')} <span className={classes.appName}>{t('app.name')}</span> {t('loginPage.info.title.b')}</Typography>
								<Divider />
								<List>
									<ListItem>
										<ListItemIcon>
											<FiberManualRecordIcon fontSize="inherit" />
										</ListItemIcon>
										{t('loginPage.info.text.a')}
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<FiberManualRecordIcon fontSize="inherit" />
										</ListItemIcon>
										{t('loginPage.info.text.b')}
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<FiberManualRecordIcon fontSize="inherit" />
										</ListItemIcon>
										{t('loginPage.info.text.c')}
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<FiberManualRecordIcon fontSize="inherit" />
										</ListItemIcon>
										{t('loginPage.info.text.d')}
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<FiberManualRecordIcon fontSize="inherit" />
										</ListItemIcon>
										{t('loginPage.info.text.e')}
									</ListItem>
								</List>
								<div className={classes.buttonContainer}>
									<Link className={classes.fullWidth} component={RouterLink} to={`/sign-up`} underline="none" tabIndex={-1}>
										<Button
											variant="contained"
											color="primary"
											// size="small"
											disableElevation
											aria-label="sign up"
											className={classes.fullWidth}
										>Sign Up</Button>
									</Link>
								</div>
							</Paper>
						</div>
					</Grid>
					<Grid item md={4} xs={12}>
						<div className={classes.logoWrapper}>
							<Logo className={classes.logo} />
						</div>
					</Grid>
					<Grid item md={4} xs={12}>
						{children}
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		minHeight: '100vh',
		display: 'grid',
		placeItems: 'center',
		boxSizing: 'border-box',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	grid: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	logoWrapper: {
		display: 'grid',
		placeItems: 'center',
		height: '80%',
		padding: theme.spacing(0),
	},
	logo: {
		maxWidth: 300,
		maxHeight: 300,
		width: '60%',
	},
	paper: {
		padding: theme.spacing(4),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(4, 2),
		}
	},
	info: {
		[theme.breakpoints.down('md')]: {
			order: 3
		},
		'& li > div': {
			minWidth: '32px'
		}
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	appName: {
		whiteSpace: 'nowrap'
	},
	fullWidth: {
		width: '100%'
	}
}));

export default LoginLayout;
