import { gql, useQuery } from '@apollo/client';
import _get from 'lodash/get';

// Utils
import { ListFilter, listFilterParams } from 'utils/listFilter';

export const ACCOUNT_STATEMENT_LIST = gql`
	query accountStatementRead(
		$top: Int
		$skip: Int
		$search: String
		$sort: String
		$sortDesc: Boolean
		$first: Int
		$after: String
	) {
		accountStatementRead {
			id
			balance
			creditLimit
			totalBalance
			created
			termCode
			accountStatementItems(
				top: $top,
				skip: $skip,
				search: $search,
				sort: $sort,
				sortDesc: $sortDesc,
				first: $first,
				after: $after
			) {
				recordCount
				pageInfo {
					top
					skip
					startCursor
					endCursor
					hasPreviousPage
					hasNextPage
				}
				edges {
					cursor
					node {
						id
						balance
						amount 
						type {
							id
							code
							name
						}
						referenceNumber
						createdDate
						confirmedDate
						extendedFields
						created
					}
				}
			}
		}
	}
`;

export const filterReducer = (state: any, action: any) => {
	switch (action.type) {
		case 'reset':
			return action.payload || {}
		case 'top':
			return { ...state, top: action.payload }
		case 'skip':
			return { ...state, skip: action.payload }
		case 'before':
			return { ...state, before: action.payload, last: listFilterParams.last }
		case 'after':
			return { ...state, after: action.payload, first: listFilterParams.first }
		case 'sort':
			return { ...state, sort: action.payload }
		case 'sortDesc':
			return { ...state, sortDesc: action.payload }
		case 'search':
			return { ...state, search: action.payload, skip: 0 }
		case 'filters':
			return { ...state, filters: action.payload }
	}
};


export const useAccountStatement = (filter?: ListFilter) => {
	const variables = {...filter };

	const { data, loading, error, fetchMore } = useQuery(ACCOUNT_STATEMENT_LIST, { variables, fetchPolicy: 'cache-and-network' });

	const accountStatement = _get(data, 'accountStatementRead', {});

	const loadMore = () => {
		const newVariables = {...variables, first: listFilterParams.first, after: accountStatement?.accountStatementItems?.pageInfo.endCursor};

		return new Promise((resolve, reject) => {
			fetchMore({ variables: newVariables }).then(resolve).catch(reject);
		});
	};

	return { data:accountStatement, loading, error, loadMore };
};
