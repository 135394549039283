import { gql, useQuery } from '@apollo/client';
import { accountWithContacts } from '__generated__/accountWithContacts';
import _get from 'lodash/get';

export const GET_ACCOUNT_CONTACTS = gql`
  query accountWithContacts {
    account {
      id
      name
      contacts {
        pageInfo {
          top
          skip
        }
        nodes {
          id
          fullName
          lastName
          email
          aspNetUserId
          roles {
            id
            name
            normalizedName
          }
          type {
            id
            name
          }
        }
      }
      contactsWithSobo {
        pageInfo {
          top
          skip
        }
        nodes {
          id
          fullName
          lastName
          email
          aspNetUserId
          roles {
            id
            name
            normalizedName
          }
          type {
            id
            name
          }
        }
      }
    }
    pendingUsers {
      id
      extendedFields
    }
  }
`;

export const useAccountWithContacts = () => {
  const { data, loading, error } = useQuery<accountWithContacts>(GET_ACCOUNT_CONTACTS);

  const contacts = _get(data, 'account.contacts', {});
  const pendingUsers = _get(data, 'pendingUsers', []);

  return { data:contacts, pendingUsers, loading, error };
};
