import { gql } from '@apollo/client';

export const meUserFragment = gql`
	fragment MeUserFragment on Me {
		id
		account {
			id
			code
			name
			email
			defaultBillingAddress {
				id
				firstName
				lastName
				address1
				city
				postalCode
				stateName
				countryName
				phoneNumber
			}
			defaultShippingAddress {
				id
				firstName
				lastName
				address1
				city
				postalCode
				stateName
				countryName
				phoneNumber
			}
		}
		code
		email
		contact {
			id
			fullName
			firstName
			lastName
		}
		claims {
			id
			actionId
			action
			subjectId
			subject
			isCan
			isPassThrough
		}
		defaultSalesRep {
			id
			code
			name
			dbaName
			defaultPhone {
				id
				phoneNumber
			}
			defaultEmail {
				id
				email
			}
			company {
				id
				code
				name
			}
		}
	}
`;
