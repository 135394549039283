import React, { useState, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Icons
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';


interface Props {
	catalog: any[];
	selected: any;
	handleSelect: Function;
	handleClose: Function;
};

const CatalogMenu: React.FC<Props> = (props) => {
	const classes = useStyles();

	// State
	const [expanded, setExpanded] = useState<any|null>(null);


	const handleBack = () => {
		props.handleSelect((props.selected.tier === 2) ? null : props.catalog.find((obj:any) => obj.id === props.selected.parentId));
	};

	const getMenu = (selected:any, catalog:any[]) => {
		if(!selected || !catalog)
			{return [];}

		return catalog.filter((obj:any) => obj.parentId === selected.id);
	};

	const handleSelect = (item:any) => {
		if(item.tier === 4)
			{setExpanded((expanded?.id === item.id) ? null : item);}
		else
			{props.handleSelect(item);}
	};

	return (
		<div className={classes.root}>
			<List disablePadding className={classes.list}>
				<ListItem button divider className={classes.listHeader}>
					<ListItemIcon>
						<IconButton onClick={() => handleBack()}><ArrowBackIcon/></IconButton>
					</ListItemIcon>
					<Link color="inherit" className={classes.headerLink} underline="none" component={RouterLink} to={`/product-list/${props.selected?.seoCrumb}`} onMouseDown={() => props.handleClose()}>
						<ListItemText>
							<Typography variant="body1"><b>View All {props.selected?.name}</b></Typography>
						</ListItemText>
						<Tooltip title={`View All ${props.selected?.name}`}>
							<Button variant="text" color="primary" size="small" startIcon={<ShoppingBasketOutlinedIcon/>}>View</Button>
						</Tooltip>
					</Link>
				</ListItem>
				
				{getMenu(props.selected, props.catalog).map((item:any) => (
					<Fragment key={item.id}>
						<ListItem button divider selected={expanded?.id === item?.id} onClick={() => handleSelect(item)}>
							<ListItemText>
								{(expanded?.id === item?.id) ? <b>{item.name}</b> : <>{item.name}</>}
							</ListItemText>
							<ChevronRightIcon color="disabled" data-open={expanded?.id === item.id}/>
							<Link component={RouterLink} to={`/product-list/${item?.seoCrumb}`} onMouseDown={() => props.handleClose()}>
								<Tooltip title={`View All ${item.name}`}>
									<Button variant="text" color="primary" size="small" startIcon={<ShoppingBasketOutlinedIcon/>}>View</Button>
								</Tooltip>
							</Link>
						</ListItem>
						{(props.selected.tier === 3) && (
							<Collapse in={expanded?.id === item.id}>
								{getMenu(item, props.catalog).map((subItem:any) => (
									<ListItem key={subItem.id} button divider className={classes.subItem} component={RouterLink} to={`/product-list/${subItem?.seoCrumb}`} onMouseDown={() => props.handleClose()}>
										<ListItemIcon>
											<SubdirectoryArrowRightIcon color="disabled" fontSize="small"/>
										</ListItemIcon>
										<ListItemText>{subItem.name}</ListItemText>
										<Tooltip title={`View All ${item.name}`}>
											<Button variant="text" color="primary" size="small" startIcon={<ShoppingBasketOutlinedIcon/>}>View</Button>
										</Tooltip>
									</ListItem>
								))}
							</Collapse>
						)}
					</Fragment>
				))}
			</List>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {

	},
	list: {
		minWidth: 250,
		minHeight: 300,
		paddingBottom: theme.spacing(2),
		'& .MuiListItemIcon-root': {
			minWidth: 28,
		},
		'& .MuiListItem-root': {
			padding: theme.spacing(0.75, 0.5, 0.75, 1.5),
			'& > .MuiSvgIcon-root': {
				transition: 'transform 100ms ease-in-out',
				'&[data-open="true"]': {
					transform: 'rotate(90deg)',
				},
			},
			'& .MuiButton-sizeSmall': {
				padding: theme.spacing(0.25, 0.75),
				'& .MuiButton-startIcon': {
					marginRight: theme.spacing(0.5),
				}
			},
		},
		'& .MuiListItemSecondaryAction-root': {
			right: 0,
		},
	},
	listHeader: {
		padding: `${theme.spacing(0.5)}px 0 !important`,
	},
	headerLink: {
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		paddingRight: theme.spacing(0.5),
	},
	subItem: {
		backgroundColor: theme.palette.grey[50],
	},
}));

export default CatalogMenu;
