import { gql } from '@apollo/client';

export const cartHeaderFragment = gql`
	fragment CartHeaderFragment on Cart {
		id
		itemCount
		salesRep {
			id
			name
			code
		}
		lines {
			...CartLinesFragment
		}
		requestedDeliveryDate
		subtotal
		discountTotal
		freightTotal
		taxTotal
		total
	}
`;
