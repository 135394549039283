import React, { useState, KeyboardEvent, ChangeEvent } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import _debounce from 'lodash/debounce';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';


interface Props {
	count: number;
	loading: boolean;
	handleSearch: Function;
};

const HeaderSearch: React.FC<Props> = (props) => {
	const classes = useStyles();
	const history = useHistory();
	
	const fetchTypeahead = async (value:string) => {
		props.handleSearch(value);
	};

	// State
	const [keyword, setKeyword] = useState('');
	const [debouncedCall] = useState(() => _debounce(fetchTypeahead, 300));

	const handleInputChange = (e:ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setKeyword(e.currentTarget.value);
		debouncedCall(e.currentTarget.value);
	};

	const handleKeyDown = (e:any) => {
		if(e.key === 'Enter') {
			history.push({
				pathname: '/search',
				search: `search=${keyword}`
			});
		}
	};

	return (<>
		<InputBase
			autoFocus
			fullWidth
			defaultValue={keyword}
			className={classes.inputBase}
			onKeyUp={handleKeyDown}
			placeholder="Search products..."
			onChange={handleInputChange}
			endAdornment={props.loading ? <CircularProgress size={28} className={classes.progress}/> : null}
		/>
		<Button fullWidth variant="outlined" color="primary" disableElevation size="small" component={RouterLink} to={`/search?search=${keyword}`}>Advanced Search</Button>
	</>);
};

const useStyles = makeStyles((theme: Theme) => ({
	inputBase: {
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: 4,
		paddingRight: theme.spacing(0.5),
		marginBottom: theme.spacing(1),
		'& input': {
			borderRadius: 4,
			backgroundColor: theme.palette.common.white,
			padding: 8,
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			
			fontSize: 14,
		},
	},
	progress: {
		position: 'absolute',
		top: 2,
		right: theme.spacing(0.5),
	}
}));

export default HeaderSearch;
