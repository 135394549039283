import React, { useState } from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

interface Props {
	count: number;
};

const Multiple: React.FC<Props> = (props) => {
	const classes = useStyles();

	// State
	const [expanded, setExpanded] = useState(false);

	return (<>
		<Box display="flex" flexGrow={1} alignItems="center" style={{ cursor: 'pointer' }} onClick={() => setExpanded(value => !value)}>
			<Avatar className={classes.avatar}>{props.count}</Avatar>
			<Typography variant="subtitle1">New notifications</Typography>
			<Box flexGrow={1} width={8}/>
		</Box>
		<div className={classes.expand}>
			<Collapse in={expanded} timeout="auto" unmountOnExit>{props.children}</Collapse>
		</div>
	</>);
};

const useStyles = makeStyles((theme: Theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.primary,
		marginRight: theme.spacing(1),
	},
	expand: {
		position: 'absolute',
		top: 60,
		left: 0,
		width: '100%',
		'& > .MuiCollapse-root >.MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiBox-root': {
			padding: theme.spacing(1),
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[3],
			borderRadius: theme.shape.borderRadius,
			marginBottom: theme.spacing(0.5),
		},
	},
}));

export default Multiple;
