import React from 'react';
import castArray from 'lodash/castArray';
// Local
import UnitOfMeasureItem from './UnitOfMeasureItem';


interface Props {
	productId: string;
	pricing: any;
	quantity?: number;
	available: number;
	allocated: number;
	bottlesPerCase: number;
	variant?: 'add' | 'addBig' | 'edit' | 'readonly';
	canAddToCart: boolean;
	expired?: boolean | undefined;
};

const UnitOfMeasure: React.FC<Props> = (props) => {

	const variant = props.variant || 'add';
	const pricing = castArray(props.pricing);

	return (<>
		{pricing?.map((price:any) => (
			<UnitOfMeasureItem
				key={price?.unitOfMeasure?.id}
				productId={props.productId}
				variant={variant}
				price={price}
				quantity={price?.quantity}
				available={props.available}
				allocated={props.allocated}
				bottlesPerCase={props.bottlesPerCase}
				canAddToCart={props.canAddToCart}
				unitOfMeasureId={price?.unitOfMeasure?.id}
				expired={props.expired}
			/>
		))}
	</>)
};

export default UnitOfMeasure;
