import { gql } from '@apollo/client';

// TODO: Convert selections to qgl fragment as we are using it also in queries/getCart
export const CREATE_ORDER_ADDRESS = gql`
  mutation createOrderAddress($address: AddressInput!) {
    createOrderAddress(address: $address) {
      id
      addressTypeName
      address1
      address2
      city
      stateName
      countryName
      postalCode
      phoneNumber
    }
  }
`;
