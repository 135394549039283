import React from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';

import LowAttentionMessageHandler from './components/Handlers/LowAttentionMessageHandler';
import HighAttentionMessageHandler from './components/Handlers/HighAttentionMessageHandler';
import MediumAttentionMessageHandler from './components/Handlers/MediumAttentionMessageHandler';
import AddToCartMessageHandler from './components/Handlers/AddToCartMessageHandler';

interface Props {}

const GlobalMessages: React.FC<Props> = React.memo(() => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.addToCartContainer}>
        <div>
          <AddToCartMessageHandler timeOut={7000} />
        </div>
      </div>
      <HighAttentionMessageHandler />
      <div className={classes.mediumAttention}>
        <LowAttentionMessageHandler timeOut={7000} />
        <MediumAttentionMessageHandler />
      </div>
    </div>
  );
});

//Navigation sub menus appear above popup 
//Change: Lower z-index
//DELETE comment in chores

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    left: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
  },
  highAttention: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    background: 'rgba(355,355,355, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mediumAttention: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  addToCartContainer: {
    position: 'relative',
    overflow: 'hidden',
  },
  addToCartIn: {
    position: 'relative',
    display: 'block',
    transform: 'translateY(-100%)',
    animation: '$down 500ms linear forwards',
    zIndex: 1,
  },
  '@keyframes down': {
    '100%': {
      transform: 'translateY(0)',
    },
  },

  addToCartOut: {
    position: 'relative',
    display: 'block',
    transform: 'translateY(0)',
    animation: '$up 100ms linear forwards',
    zIndex: 1,
  },
  '@keyframes up': {
    '100%': {
      transform: 'translateY(-100%)',
    },
  },
  addToCartHide: {
    display: 'none',
  },
}));

export default GlobalMessages;
