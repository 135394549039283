import { ApolloClient, ApolloLink, HttpLink, split } from '@apollo/client';
import { JwtRefreshLink, errorLink, authLink } from './graphql';
import { cache } from 'Cache';
import { resolvers, typeDefs } from 'resolvers';
import {
  getAuthToken,
  getCurrentTokenExpiresIn,
} from 'utils/auth';
import { refreshToken } from 'utils/graphql/refreshTokenRequest';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { applicationInformation } from 'applicationInfo'

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: 'include',
  headers: {
    ...applicationInformation
  },
});

let shouldRefreshToken = false;
let tokenExpiryTimeout: number | undefined;
const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_WS_URL}/graphql`,
  options: {
    reconnect: true,
    timeout: 30000,
    connectionParams: async () => {
      if (shouldRefreshToken) {
        await refreshToken();
      }

      const token = getAuthToken();
      return {
        authorization: `Bearer ${token}`,
      };
    },
/*     connectionCallback: (error) => {
      const authToken = getAuthToken();
      if (error && authToken) {
      }
    }, */
  },
});

// subscriptionClient is privet and not exposed on the wsLink
// We need to listen to the events to correctly refresh JWT token
// After we update core project to ApolloServer 3.0 and graphql-ws
// this code must be modified to adhere to the new interface.
// The refresh token routing will be different as well.
// Link: https://github.com/enisdenjo/graphql-ws#auth-token
// @ts-ignore
const subscriptionClient = wsLink.subscriptionClient;
subscriptionClient.onConnecting(() => {
  // console.info('Sub client connecting....');
});
subscriptionClient.onConnected(() => {
  clearTimeout(tokenExpiryTimeout);
  // @ts-ignore
  tokenExpiryTimeout = setTimeout(() => {
    if (subscriptionClient.status === 1) {
      shouldRefreshToken = true;
    }
  }, getCurrentTokenExpiresIn());
});
/* 
subscriptionClient.onReconnecting(() => {
  // console.info('Sub client onReconnecting....');
});

subscriptionClient.onReconnected(() => {
  // console.info('Sub client onReconnected....');
});

subscriptionClient.onDisconnected(() => {
  console.info('Sub client onDisconnected....');
});
subscriptionClient.onError((error: Error) => {
  console.log('error in subscription client ', error);
});
setInterval(() => {
  console.info('subscription status --> ', subscriptionClient.status);
}, 1000);
 */
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const link = ApolloLink.from([
  new JwtRefreshLink(),
  authLink,
  errorLink,
  splitLink,
]);

const client = new ApolloClient({ cache, link, typeDefs, resolvers });

export { client };
