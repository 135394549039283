import {
  gql,
  useQuery,
  useLazyQuery,
  useSubscription,
  ApolloCache,
} from '@apollo/client';
import _get from 'lodash/get';
import _some from 'lodash/some';
import {
  groupChatFragment,
  lastMessageFragment,
  chatMessageFragment,
} from 'operations/fragments/chat';
import { chatGroups, chatGroups_chatGroups } from '__generated__/chatGroups';

export enum ChatEventType {
  NEWCHATMESSAGE = 'NEWCHATMESSAGE',
  UNREADMESSAGECHANGE = 'UNREADMESSAGECHANGE',
}

export const MESSAGE_SUB = gql`
  subscription chatMessagesSubscription {
    messages {
      ...ChatMessageFragment
    }
  }
  ${chatMessageFragment}
`;

// We need to fetch the single chat group with the latest messages
// when selecting specific group.
export const CHAT_GROUP = gql`
  query chatGroup($id: ID!, $skip: Int, $top: Int) {
    chatGroup(id: $id) {
      ...GroupChatFragment
      lastMessage @client {
        ...LastChatMessageFragment
      }
      messages(input: { skip: $skip, top: $top }) {
        pageInfo {
          top
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
          currentPage
        }
        nodes {
          ...ChatMessageFragment
        }
      }
    }
  }
  ${lastMessageFragment}
  ${groupChatFragment}
  ${chatMessageFragment}
`;

// We need all the chat groups that user belongs to on the page load.
// This is used to setup initial list of available chat groups
// with information about last message
export const CHAT_GROUPS_LIST = gql`
  query chatGroups($input: GroupChatQueryInput!) {
    chatGroups(input: $input) {
      nodes {
        ...GroupChatFragment
        lastMessage @client {
          ...LastChatMessageFragment
        }
        hasUnreadMessages @client
        messages {
          nodes {
            ...ChatMessageFragment
          }
        }
      }
    }
  }
  ${lastMessageFragment}
  ${groupChatFragment}
  ${chatMessageFragment}
`;

export const useMyChatGroups = () => {
  const { data, loading, error } = useQuery<chatGroups>(CHAT_GROUPS_LIST, {
    variables: {
      input: { top: 1000 },
    },
  });
  const defaultResponse: chatGroups_chatGroups = {
    nodes: [],
    __typename: 'ChatGroupConnectionResponse',
  };
  const myChatGroups = _get(data, 'chatGroups', defaultResponse);
  return { data: myChatGroups, loading, error };
};

export const hasUnreadMessages = (cache: ApolloCache<any>) => {
  const result = cache.readQuery({
    query: CHAT_GROUPS_LIST,
    variables: {
      input: {
        top: 1000,
      },
    },
  });
  const chatGroups = _get(result, 'chatGroups.nodes', []);
  const hasGroupWithUnreadMessages = _some(
    chatGroups,
    (chatGroup) => chatGroup.hasUnreadMessages === true
  );

  return hasGroupWithUnreadMessages;
};

export const useLazyChatGroup = () => {
  const [getChatGroupById, { data, loading, error, fetchMore }] =
    useLazyQuery(CHAT_GROUP);

  let chatGroupData = _get(data, 'chatGroup', {});

  // Initialize subscription for new messages
  const { error: subscriptionError } = useSubscription(MESSAGE_SUB, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      const newMessage = _get(subscriptionData, 'data.messages', {});
      let id = client.cache.identify(newMessage.group);

      // Post message to notify consumer about new messages
      const payload = {
        event: 'new-chat-message',
        payload: {
          newMessage
        }
      } 
      if (window && window.postMessage) {
        window.postMessage(payload, '*');
      }

      client.cache.modify({
        id: id,
        fields: {
          messages(existingMessageRefs = [], { readField, toReference }) {
            let newMessageRef = toReference(newMessage);

            // Quick safety check - if the new comment is already
            // present in the cache, we don't need to add it again.
            if (
              false
              // existingMessageRefs.some(
              //   (ref: any) => readField('id', ref) === newMessage.id
              // )
            ) {
              return existingMessageRefs;
            }
            let nodes = [newMessageRef, ...existingMessageRefs.nodes];
            let modifiedData = { ...existingMessageRefs, nodes };
            return modifiedData;
          },
        },
      });
      if (window && window.top?.postMessage) {
        const result = client.cache.readQuery({
          query: CHAT_GROUPS_LIST,
          variables: {
            input: {
              top: 1000,
            },
          },
        });
        const chatGroups = _get(result, 'chatGroups.nodes', []);
        const hasGroupWithUnreadMessages = hasUnreadMessages(client.cache);
        const count = chatGroups.reduce((prev: number, current: any) => {
          const isUnread = _get(current, 'lastMessage.isRead', false);
          if (isUnread) {
            // Count unread messages in a group
            const groupUnreadCount = current.messages.nodes.reduce(
              (acc: number, current: any) => {
                if (!current.isRead) {
                  acc = acc + 1;
                }
                return acc;
              },
              0
            );
            prev = prev + groupUnreadCount;
          }
          return prev;
        }, 0);

        // Post message to notify consumer about new messages
        const payload = {
          event: ChatEventType.NEWCHATMESSAGE,
          payload: {
            newMessage,
            hasUnreadMessages: hasGroupWithUnreadMessages,
          },
        };
        window.top?.postMessage(payload, '*');
      }
    },
  });
  if (subscriptionError) {
    console.error('Error in subscription : ', error);
  }

  return {
    query: getChatGroupById,
    data: chatGroupData,
    loading,
    error,
    fetchMore,
  };
};

// In the context of HSC account, logged in user has access to only one chat group.
// This will be the first group in the list at index 0.
export const useMyChatGroup = () => {
  const { data, loading, error } = useMyChatGroups();

  const myChatGroup = _get(data, 'nodes.0', {});

  return { data: myChatGroup, loading, error };
};
