import React, { createContext, useContext } from 'react';

// Operations
import { useInitialLoad } from 'operations/queries/initalLoad';

// Root
import themeBuilder from 'buildMuiTheme';

// Material UI
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

const SiteContext = createContext({});

interface Props {}

const SiteProvider: React.FC<Props> = (props) => {
  // Operations
  const { site, loading } = useInitialLoad();

  if (loading) {
    return null;
  }

  return (
    <MuiThemeProvider theme={themeBuilder(site?.settings?.selectedTheme)}>
      <SiteContext.Provider value={site}>{props.children}</SiteContext.Provider>
    </MuiThemeProvider>
  );
};

export const useSiteContext = () => {
  const context = useContext(SiteContext);

  if (context === null) {
    throw new Error('useSiteContext must be used within SiteContext.');
  }
  
  return context;
};
export { SiteContext };

export default SiteProvider;
