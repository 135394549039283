import { gql, useQuery } from '@apollo/client';
import { GET_WISHLIST_LIST } from '__generated__/GET_WISHLIST_LIST';

export const GET_WISH_LIST = gql`
  query GET_WISHLIST_LIST {
    wishlistList {
      edges {
        node {
          id
          name
          comment
          isPublic
          created
          isDefault
          isEditable
          totalProducts
          account {
            id
          }
          products {
            edges {
              node {
                id
                name
                price
                listPrice
                created
                img {
                  id
                  cdnURL
                  alt
                }
                seoName
              }
              unitOfMeasure {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetWishListList = () => {
  const { data, loading, error } = useQuery<GET_WISHLIST_LIST>(GET_WISH_LIST,{fetchPolicy: 'cache-and-network'});

  return { data, loading, error };
};
