import React from 'react';

// Local
import Copyright from './Copyright';

// Components
import Logo from 'components/Logo';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


interface Props {
	
};

const Footer: React.FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Container disableGutters>
				<div className={classes.logoContainer}>
					<Logo/>
				</div>
			</Container>
			<Copyright />
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		backgroundColor: theme.palette.primary.main,
	},
	logoContainer: {
		display: 'flex',
		justifyContent: 'center',
		height: 100,
	},
}));

export default Footer;
