import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Utils
import { useGlobalContext } from 'utils/globalContext';

// Components
import Logo from 'components/Logo';
import Footer from 'components/Footer';
import SnackbarAlert from 'components/SnackbarAlert';
import ElevationScroll from 'components/ElevationScroll';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';


interface Props {
	
};

const CheckoutLayout: React.FC<Props> = (props) => {
	const classes = useStyles();
	const { state:message } = useGlobalContext();

	return (<>
		<ElevationScroll>
			<AppBar position="sticky">
				<Box display="flex" alignItems="center" justifyContent="center">
					<ButtonBase component={RouterLink} to="/" className={classes.logo}>
						<Logo/>
					</ButtonBase>
				</Box>
			</AppBar>
		</ElevationScroll>
		<main className={classes.main}>
			{props.children}
		</main>
		<Footer/>
		<SnackbarAlert open={message.open} severity={message?.severity} message={message?.message}/>
	</>);
};

const useStyles = makeStyles((theme: Theme) => ({
	logo: {
		height: 88,
		margin: theme.spacing(0.5),
		padding: theme.spacing(0.5),
		borderRadius: theme.shape.borderRadius,
		'&:focus': {
			backgroundColor: 'rgba(255, 255, 255, 0.35)',
		},
	},
	main: {
		flex: '1 0 100%',
		padding: theme.spacing(2, 0),
	},
}));

export default CheckoutLayout;
