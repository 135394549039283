import React from 'react';
import _isFunction from 'lodash/isFunction';

import { MessageItem } from './MessageItem';
// Material-UI
import { makeStyles, Theme, lighten } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

interface Props {
	userId: string;
	messages: any;
	onScreenView?: (message: any) => void;
};

const MessageList: React.FC<Props> = (props) => {
	const { onScreenView } = props;
	const classes = useStyles();

	const handleMessageOnScreen = (message: any) => {
		if (_isFunction(onScreenView)) {
			onScreenView(message);
		}
	};
	return (
		<div className={classes.root} data-empty={props.messages?.length === 0}>
			{(props.messages?.length > 0) ? (
				<div className={classes.list}>
					{/* We are reversing the messages to show the latest at the bottom. */}
					{[...props.messages].reverse().map((item:any) => (
						<MessageItem onScreenView={handleMessageOnScreen} message={item} userId={props.userId} />
					))}
				</div>
			):(
				<Box minHeight="130px" display="flex" alignItems="center" justifyContent="center">
					<Typography variant="subtitle2" color="textSecondary"><i>No Messages</i></Typography>
				</Box>
			)}
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		'&[data-empty="false"]': {
			minHeight: 130,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
		},

	},
	list: {
		padding: theme.spacing(1, 1, 1, 0),
		flexGrow: 0,
		'& > .item': {
			display: 'flex',
			alignItems: 'center',
			marginBottom: theme.spacing(1.5),
			wordBreak: 'break-word',
			[theme.breakpoints.down('xs')]: {
				marginBottom: theme.spacing(1),
			},
			'&:last-child': {
				marginBottom: 0,
			},
			'& > .dot': {
				height: 8,
				width: 8,
				borderRadius: '50%',
				margin: theme.spacing(0, 0.5),
				backgroundColor: theme.palette.primary.main,
				[theme.breakpoints.up('sm')]: {
					margin: theme.spacing(0, 1, 0, 0),
				},
				flexShrink: 0,
				'&[data-read="true"]': {
					backgroundColor: theme.palette.grey[100],
				}
			},
			'& > .detail': {
				flexGrow: 1,
				backgroundColor: lighten(theme.palette.secondary.main, 0.9),
				borderRadius: theme.shape.borderRadius,
				padding: theme.spacing(0.75),
				display: 'grid',
				gridTemplateColumns: 'auto 1fr auto',
				gridTemplateRows: 'auto auto',
				gridTemplateAreas: `'avatar name date' 'avatar message message'`,
				[theme.breakpoints.down('xs')]: {
					gridTemplateAreas: `'avatar name date' 'message message message'`,
				},
				'&[data-me="true"]': {
					backgroundColor: lighten(theme.palette.primary.main, 0.9),
				},
				'& > .avatar': {
					gridArea: 'avatar',
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.text.primary,
					marginRight: theme.spacing(1),
					fontSize: 18,
					'&[data-me="true"]': {
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
					},
					[theme.breakpoints.down('xs')]: {
						height: 21,
						width: 21,
						lineHeight: 21,
						paddingTop: 2,
						fontSize: 10,
						fontWeight: 'bold',
						marginBottom: theme.spacing(0.25),
					},
				},
				'& > .name': {
					gridArea: 'name',
				},
				'& > .date': {
					gridArea: 'date',
				},
				'& > .message': {
					gridArea: 'message',
					lineHeight: '20px',
				},
			},
		},
	},
}));

export default MessageList;

