import React from 'react';
import { useTranslation } from 'react-i18next';
// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

interface Props {}

const Copyright: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography className={classes.copyright}>{t('copyright.text')}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    //borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main
  },
  copyright: {
      fontSize: '12px'
  }
}));

export default Copyright;
