import { gql } from '@apollo/client';

import { addressFragment } from '../fragments';

export const GET_ADDRESSES_ACCOUNT = gql`
  query accountAddresses($orderBy: String, $offset: Int, $limit: Int) {
    getAddresses(orderBy: $orderBy, offset: $offset, limit: $limit) {
      ...AddressFragment
    }
    getAddressCount
  }
  ${addressFragment}
`;
