import { createTheme, ThemeOptions, darken, lighten } from '@material-ui/core/styles';

import set from 'lodash/set';
import merge from 'lodash/merge';
import { ThemeOverride } from './interfaces/theme';

const themeBuilder = (themeObject?: any, options?: ThemeOverride[]) => {
  const option: ThemeOverride[] = options || [];
  const created = option.reduce((prev, curr) => {
    set(prev, curr.name, curr.value);
    return prev;
  }, {} as any);
  const theme: ThemeOptions = {
    breakpoints: {
      values: {
        xs: themeObject.xsBreakpoint,
        sm: themeObject.smBreakpoint,
        md: themeObject.mdBreakpoint,
        lg: themeObject.lgBreakpoint,
        xl: themeObject.xlBreakpoint,
      },
    },
    palette: {
      background: {
        default: themeObject.background,
        paper: themeObject.panelBackground,
      },
      primary: {
        main: themeObject.colorPrimary,
        light: lighten(themeObject.colorPrimary, 0.05),
        dark: darken(themeObject.colorPrimary, 0.05),
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: themeObject.colorSecondary,
        light: lighten(themeObject.colorSecondary, 0.05),
        dark: darken(themeObject.colorSecondary, 0.05),
        contrastText: '#333333',
      },
      text: {
        primary: themeObject.textPrimary,
        secondary: themeObject.textSecondary,
      },
      warning: {
        main: themeObject.warning,
        light: lighten(themeObject.warning, 0.1),
        dark: darken(themeObject.warning, 0.1),
      },
      success: {
        main: themeObject.success,
        light: lighten(themeObject.success, 0.1),
        dark: darken(themeObject.success, 0.1),
      },
      error: {
        main: themeObject.error,
        light: lighten(themeObject.error, 0.1),
        dark: darken(themeObject.error, 0.1),

      },
      info: {
        main: themeObject.info,
        light: lighten(themeObject.info, 0.1),
        dark: darken(themeObject.info, 0.1),
      },
    },
    typography: {
      fontFamily: 'Verdana, sans-serif',
      h1: {
        fontSize: `${themeObject.h1}rem`,
        // fontFamily: themeObject.h1Font || themeObject.fontFamily,
        fontFamily: 'Verdana, sans-serif',
      },
      h2: {
        fontSize: `${themeObject.h2}rem`,
        // fontFamily: themeObject.h2Font || themeObject.fontFamily,
        fontFamily: 'Verdana, sans-serif',
      },
      h3: {
        fontSize: `${themeObject.h3}rem`,
        // fontFamily: themeObject.h3Font || themeObject.fontFamily,
        fontFamily: 'Verdana, sans-serif',
      },
      h4: {
        fontSize: `${themeObject.h4}rem`,
        // fontFamily: themeObject.h4Font || themeObject.fontFamily,
        fontFamily: 'Verdana, sans-serif',
      },
      h5: {
        fontSize: `${themeObject.h5}rem`,
        // fontFamily: themeObject.h5Font || themeObject.fontFamily,
        fontFamily: 'Verdana, sans-serif',
      },
      h6: {
        fontSize: `${themeObject.h6}rem`,
        // fontFamily: themeObject.h6Font || themeObject.fontFamily,
        fontFamily: 'Verdana, sans-serif',
      },
      body1: {
        fontSize: `${themeObject.body1}rem`,
        //fontFamily: themeObject.body1Font || themeObject.fontFamily,
        fontFamily: 'Verdana, sans-serif'
      },
      body2: {
        fontSize: `${themeObject.body2}rem`,
        //fontFamily: themeObject.body2Font || themeObject.fontFamily,
        fontFamily: 'Verdana, sans-serif'
      },
      subtitle1: {
        fontFamily: 'Verdana, sans-serif'
      },
      subtitle2: {
        fontFamily: 'Verdana, sans-serif'
      },
      button: {
        //fontFamily: themeObject.fontFamily,
        fontFamily: 'Georgia, sans-serif',
        fontWeight: 700
      },
      caption: {
        //fontFamily: themeObject.fontFamily,
        fontFamily: 'Verdana, sans-serif'
      },
    },
  };

  // Default theme needed in case the generate theme has undefined values.
  const defaultTheme = createTheme();
  const mergedTheme = merge(defaultTheme, theme || {}, created);
  return createTheme(mergedTheme);
};

export default themeBuilder;
