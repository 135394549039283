import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  MouseEvent,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Local
import './pulse.css';

// Utils
import { SiteContext } from 'utils/SiteProvider';

// Icons
import CutoffIcon from '@material-ui/icons/LocalShipping';

// Material-UI
import { makeStyles, Theme, darken } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Ability from 'components/Ability';

const initialState: Countdown = {
  severity: 'expired',
  percentage: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

interface Countdown {
  severity: 'low' | 'medium' | 'high' | 'expired';
  percentage: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface Props {
  open: boolean;
  handleOpen: (isOpen: boolean) => void;
  onTimeExpired?: (ts: number) => void;
}

const CountdownTimer: React.FC<Props> = (props) => {
  const { open, handleOpen, onTimeExpired } = props;
  const classes = useStyles();
  const site: any = useContext(SiteContext);
  const anchorEl = useRef(null);
  const saveDate = new Date(site?.alerts?.cutoff?.cutoffTime);
  const to = new Date().setHours(
    saveDate.getHours(),
    saveDate.getMinutes(),
    0,
    0
  );

  // State
  const [countdown, setCountdown] = useState<Countdown>(initialState);

  useEffect(() => {
    calcTimer(null);

    const countdownLoop: NodeJS.Timeout = setInterval(() => {
      calcTimer(countdownLoop);
    }, 1000);

    return () => clearInterval(countdownLoop);
  }, []);

  const calcTimer = (countdownLoop: NodeJS.Timeout | null) => {
    const medium = 1800000; // 30 minutes = 6000 * 30
    const high = 300000; // 5 minutes = 6000 * 5
    const start = new Date(new Date()).setHours(0, 0, 0, 0);

    const now = new Date().getTime();
    const distance = to - now;
    const percentage = ((to - start) / (now - start) - 1) * 100;
    const severity =
      distance < 1000
        ? 'expired'
        : distance < high
          ? 'high'
          : distance < medium
            ? 'medium'
            : 'low';

    // Time calculations for days, hours, minutes and seconds
    setCountdown({
      severity,
      percentage,
      days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((distance % (1000 * 60)) / 1000),
    });

    if (distance < 0 && countdownLoop) {
      setCountdown(initialState);
      clearInterval(countdownLoop);

      if (typeof onTimeExpired === 'function') {
        onTimeExpired(now);
      }
    }
  };

  return (
    <>
      <Button
        ref={anchorEl}
        aria-label="cutoff time"
        className={classes.button}
        variant="contained"
        disableElevation
        size="small"
        color="primary"
        data-severity={countdown.severity}
        onClick={(e: MouseEvent<HTMLButtonElement>) => handleOpen(true)}
      >
        {countdown.severity === 'expired' ? (
          <>
            <Box marginRight={0.5}>PASSED</Box>
            <CutoffIcon fontSize="small" />
          </>
        ) : (
          <>
            <CutoffIcon fontSize="small" />
            <div>
              {countdown.days ? `${countdown.days}d ` : ''}
              {countdown.hours ? `${countdown.hours}h ` : ''}
              {`${countdown.minutes}m `}
              {countdown.severity === 'high' ? `${countdown.seconds}s ` : ''}
            </div>
          </>
        )}
      </Button>
      <Popover
        anchorEl={anchorEl.current}
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => handleOpen(false)}
      >
        <div className={classes.popoverContent}>
          <Typography variant="body1" gutterBottom>
            <b>Order Cutoff:</b>
          </Typography>
          <Typography variant="body2" paragraph>
            Orders entered after the cutoff time are processed the next business
            day.
          </Typography>
          {countdown.severity === 'expired' ? (
            <div className={classes.circle} data-severity={countdown.severity}>
              <b>PASSED</b>
            </div>
          ) : (
            <div className={classes.circle} data-severity={countdown.severity}>
              <b>
                {countdown.days ? `${countdown.days}d ` : ''}
                {countdown.hours ? `${countdown.hours}h ` : ''}
                {`${countdown.minutes}m `}
                {countdown.severity === 'high' ? `${countdown.seconds}s ` : ''}
              </b>
              <CircularProgress
                variant="determinate"
                value={countdown.percentage}
                size={150}
                thickness={2}
                className={classes.progress}
              />
            </div>
          )}
          {/* <Ability subject="Cart" action="create">
            <Button
              aria-label="proceed to checkout"
              component={RouterLink}
              to="/checkout"
              variant="contained"
              color="primary"
              fullWidth
              disableElevation
              size="small"
            >
              Proceed to Checkout
            </Button>
          </Ability> */}
        </div>
      </Popover>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    textTransform: 'initial',
    padding: theme.spacing(0.5, 1),
    backgroundColor: darken(theme.palette.primary.main, 0.1),
    transform: 'scale(0.9)',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.dark, 0.15),
    },
    '& > .MuiButton-label > .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '&[data-severity="expired"]': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: darken(theme.palette.secondary.dark, 0.1),
      },
    },
    '&[data-severity="high"]': {
      animation: 'pulse-secondary 2s infinite',
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: darken(theme.palette.secondary.dark, 0.1),
      },
    },
    '&[data-severity="medium"]': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: darken(theme.palette.secondary.dark, 0.1),
      },
    },
  },
  popoverContent: {
    padding: theme.spacing(2),
    maxWidth: 250,
  },
  circle: {
    position: 'relative',
    borderRadius: '50%',
    height: 150,
    width: 150,
    display: 'grid',
    placeItems: 'center',
    margin: `${theme.spacing(3)}px auto`,
    backgroundColor: theme.palette.background.default,
    fontSize: theme.typography.h5.fontSize,
    '&[data-severity="expired"]': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
    '&[data-severity="high"]': {
      animation: 'pulse-secondary 2s infinite',
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
    '&[data-severity="medium"]': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  progress: {
    position: 'absolute',
  },
}));

export default CountdownTimer;
