import React from 'react';

// Operations
import { useSettings } from 'operations/queries/settings';

// Utils
import { useGlobalContext } from 'utils/globalContext';

// Components
import Header from './Header';
import Footer from 'components/Footer';
import GlobalMessages from 'components/GlobalMessages';
import SnackbarAlert from 'components/SnackbarAlert';
import SnackbarProvider from 'pages/_layouts/components/HeaderSnackbar/SnackbarProvider';
// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {}

const AuthLayout: React.FC<Props> = (props) => {
  const classes = useStyles();
  const maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'lg';
  const { state: message } = useGlobalContext();

  // Operations
  const { data: settings, loading: loadingSettings, error } = useSettings();

  return (
    <>
      <SnackbarProvider>
        <Header maxWidth={maxWidth} settings={settings} />
      </SnackbarProvider>
      <main className={classes.main}>
        {props.children}
        <GlobalMessages />
      </main>
      <Footer />
      <SnackbarAlert
        open={message.open}
        severity={message?.severity}
        message={message?.message}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    flex: '1 0 100%',
    padding: theme.spacing(2, 0),
  },
}));

export default AuthLayout;
