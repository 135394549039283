import { gql, useMutation } from '@apollo/client';

// Fragments
import { chatMessageFragment } from 'operations/fragments/chat';

export const POST_MESSAGE = gql`
	mutation postMessage($input:ChatMessagePayload!) {
		postMessage(input: $input) {
			...ChatMessageFragment
		}
	}
	${chatMessageFragment}
`;


export const usePostMessage = (chatGroupId:string) => {

	const [mutate, { data, loading, error }] = useMutation(POST_MESSAGE);

	const customMutate = (message:string) => {
		return new Promise((resolve, reject) => {
			const variables:any = {
				input: {
					groupId: chatGroupId,
					body: message,
				},
			};
			mutate({ variables }).then(resolve).catch(reject);
		});
	};

	return { mutate: customMutate, data, loading, error };
};
