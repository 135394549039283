import React, { useState, useEffect, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Local
import HeaderSearchInput from './HeaderSearchInput';

// Operations
import { useProductTypeahead } from 'operations/queries/productSearch';

// Icons
import SearchIcon from '@material-ui/icons/Search';

// Material-UI
import { darken, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';


interface Props {

};

const HeaderSearch: React.FC<Props> = (props) => {
	const classes = useStyles();

	// Operations
	const { products, loading, query:search } = useProductTypeahead();

	// State
	const [list, setList] = useState(products?.edges || []);
	const [showList, setShowList] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


	useEffect(() => {
		if(products?.edges)
			{setList(products?.edges || [])}
	},[products?.edges]);


	const fetchTypeahead = async (input:string) => {
		let value = input;
		if(value.charAt(value.length - 1) == ' ') {
			value = value.substring(0, value.length - 1);
		} else {
			value = value + '*';
		}
		if(value !== '') {
			search({ variables: { search: value} });
			setShowList(true);
		}
		else {
			setList([]);
			setShowList(false);
		}
	};

	return (<>
		<IconButton
			className={classes.iconButton}
			data-open={Boolean(anchorEl)}
			color="inherit"
			aria-label="search"
			onClick={(e:MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
		>
			<SearchIcon/>
		</IconButton>

		<Popover
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={() => setAnchorEl(null)}
			elevation={3}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<Box component={Paper} width="90vw" maxWidth={350}>
				<Box padding={1.5}>
					<HeaderSearchInput
						count={products?.recordCount}
						loading={loading}
						handleSearch={fetchTypeahead}
					/>
				</Box>

				{showList && (<>
					{list.length > 0 ? (
						<List className={classes.list} disablePadding>
							{list.map((option:any) => (
								<ListItem key={option?.node?.id} button divider dense component={RouterLink} to={`/product-detail/${option?.node?.sku}`}>
									<ListItemText
										primary={option?.node?.name || ''}
										secondary={`#${option?.node?.sku || ''}`}
										primaryTypographyProps={{ variant: 'subtitle2' }}
										secondaryTypographyProps={{ variant: 'caption' }}
									/>
								</ListItem>
							))}
						</List>
					):(
						<Box paddingTop={2} paddingBottom={4}>
							<Typography variant="subtitle2" color="textSecondary" display="block" align="center"><i>No records found</i></Typography>
						</Box>
					)}
				</>)}
			</Box>
		</Popover>
	</>);
};

const useStyles = makeStyles((theme: Theme) => ({
	iconButton: {
		'&[data-open="true"]': {
			display: 'inline-block',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: darken(theme.palette.primary.dark, 0.05),
		},
	},
	inputBase: {
		paddingRight: theme.spacing(0.5),
		borderRadius: 4,
		border: `1px solid ${theme.palette.divider}`,
		
		backgroundColor: theme.palette.common.white,
		'& input': {
			padding: 8,
			fontSize: 14,
		},
		'& .MuiChip-root': {
			borderRadius: 3,
		}
	},
	list: {
		maxHeight: 'calc(100vh - 300px)',
		overflow: 'auto',
		borderTop: `1px solid ${theme.palette.divider}`,
		'& .MuiListItemText-root': {
			display: 'flex',
			flexDirection: 'column-reverse',
		},
	},
	progress: {
		position: 'absolute',
		top: 2,
		right: theme.spacing(0.5),
	}
}));

export default HeaderSearch;
