import React from 'react';

// Material-UI
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

interface Props {
	children: any;
	elevation?: number;
	target?: any;
};

const ElevationScroll: React.FC<Props> = (props) => {
	const { children, elevation, target } = props;

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target,
	});

	return React.cloneElement(children, {
		elevation: trigger ? (elevation || 4) : 0,
	});
};

export default ElevationScroll;
