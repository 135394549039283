import { useContext } from 'react';
import { PureAbility, AbilityBuilder } from '@casl/ability';

// Local
import { SiteContext } from './SiteProvider';


function useAbility(subject:string, action:string) {
	const ability = new PureAbility();

	const site:any = useContext(SiteContext);

	const claims:any = site?.session?.user?.claims;

	if(!claims) {
		return false;
	}

	const { can, cannot, rules } = new AbilityBuilder();

	claims.forEach((claim: any) => {
		if(claim.isCan)
			{can(claim.action, claim.subject);}
		else 
			{cannot(claim.action, claim.subject);}
	});

	ability.update(rules);

	return ability.can(action, subject);
}

export default useAbility;
