import { gql, useMutation } from '@apollo/client';
import _get from 'lodash/get';

export const MARK_NOTIFICATION_READ = gql`
  mutation markAsRead($input: ChangeReadStatusInput) {
    markAsRead(input: $input) {
      code
      success
      message
      notification {
        id
        title
        body
        readAt
        isRead
      }
      notificationUnReadCount
    }
  }
`;

export const useMarkNotificationRead = () => {
  const [mutate, { data, loading, error }] = useMutation(
    MARK_NOTIFICATION_READ,
    {
      update(cache, { data }) {
        console.log('data -> ', data);
      },
    }
  );

  const customMutate = (contactId: string, notificationId: string) => {
    return new Promise((resolve, reject) => {
      const variables: any = {
        input: {
          contactId,
          notificationId,
        },
      };
      mutate({
        variables,
        update(cache, { data: { markAsRead } }) {
          cache.modify({
            fields: {
              notificationUnReadCount(existingCount) {
                return markAsRead.notificationUnReadCount;
              },
            },
          });
        },
      })
        .then((response: any) => {
          resolve(_get(response, 'data.markAsRead', {}));
        })
        .catch(reject);
    });
  };

  return { mutate: customMutate, data, loading, error };
};
