import { gql, useMutation } from '@apollo/client';

// Fragments
import { chatMessageFragment } from 'operations/fragments/chat';

// Queries
import { MESSAGE_LIST } from 'operations/queries/chatMessages';

export const MARK_CHAT_MESSAGE_READ = gql`
  mutation markChatMessageAsRead($input: MarkAsReadInput) {
    markChatMessageAsRead(input: $input) {
      ...ChatMessageFragment
    }
  }
  ${chatMessageFragment}
`;

export const useMarkChatMessageRead = (chatGroupId?: string) => {
  const [mutate, { data, loading, error }] = useMutation(
    MARK_CHAT_MESSAGE_READ,
    {
      update(cache, { data: { createCategories } }) {
        const variables = { input: { chatGroupId } };
        const cachedData: any =
          cache.readQuery({ query: MESSAGE_LIST, variables }) || {};

        if (cachedData.messages?.nodes !== undefined) {
          const list = cachedData.messages.nodes.map((obj: any) => {
            return { ...obj, isRead: true };
          });

          cache.writeQuery({
            query: MESSAGE_LIST,
            variables,
            data: { messages: { ...cachedData.messages, nodes: list } },
          });
        }
      },
    }
  );

  const customMutate = (chatGroupId: string, chatMessageId: string) => {
    return new Promise((resolve, reject) => {
      const variables: any = {
        input: {
          chatGroupId,
          chatMessageId,
        },
      };
      mutate({ variables }).then(resolve).catch(reject);
    });
  };

  return { mutate: customMutate, data, loading, error };
};
