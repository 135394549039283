import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import { useMiniCart } from 'operations/queries/getMiniCart';
// Local
import MiniCartLine from './MiniCartLine';

// Helpers
import { formatCurrency } from 'helpers/currency';

// Components
import ElevationScroll from 'components/ElevationScroll';
import EmptyCart from 'components/EmptyCart';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useAbility from 'utils/useAbility';

interface Props {
  open: boolean;
  handleOpen: Function;
}

const transformCart = (cart: any) => {
  // Internal
  const buildPriceNode = (product: any) => {
    return {
      price: product.price,
      amount: product.amount,
      discount: product.discount || 0,
      quantity: product.quantity,
      unitOfMeasure: { ...product.unitOfMeasure },
    };
  };
  let transformedLines = [];
  if (cart && !!cart?.lines?.length) {
    let groupped = _groupBy(cart.lines, (line) => {
      return _get(line, 'product.id');
    });

    transformedLines = Object.values(groupped).map((group) => {
      if (group.length === 1) {
        let item = group[0];
        item.pricing = [buildPriceNode(item)];
        return item;
      } else {
        return group.reduce((prev, current) => {
          if (_isEmpty(prev)) {
            prev = { ...current };
            prev.pricing = [buildPriceNode(current)];

            return prev;
          } else {
            prev.pricing.push(buildPriceNode(current));
            return prev;
          }
        }, {});
      }
    });
  }

  return {
    ...cart,
    lines: transformedLines,
  };
};
const MiniCart: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { data, loading } = useMiniCart();
  let cart: any = {};

  const canCreateCart = useAbility('Cart', 'create');

  // State 
  const [scrollTarget, setScrollTarget] = useState<any>();

  if (loading) { 
	  return null;
  }

  cart = transformCart(_cloneDeep(_get(data, 'cart', {})));
    const getTotalQuantity = (cart: any) => {
    if (cart && cart?.lines) {
      return cart.lines.reduce(
        (total: number, line: any) => line.quantity + total,
        0
      );
    } else {
      return 0;
    }
  };

	return (<>
		<IconButton aria-label="shopping cart" color="inherit" onClick={() => props.handleOpen(true)}><Badge badgeContent={getTotalQuantity(cart)} color="secondary"><ShoppingCartIcon/></Badge></IconButton>

		<Drawer anchor="right" open={props.open || false} onClose={() => props.handleOpen(false)} elevation={4} classes={{ paper: classes.drawerPaper }}>
			<div className={classes.scroll} ref={node => (node)? setScrollTarget(node) : undefined }>
				<ElevationScroll elevation={3} target={scrollTarget}>
					<AppBar position="sticky" component="div" elevation={0} className={classes.appBar}>
						<Toolbar variant="dense" className={classes.toolbar}>
							<Typography variant="h6">Order Preview</Typography>
							<Box flexGrow={1}/>
							<IconButton edge="end" color="inherit" onClick={() => props.handleOpen(false)}><CloseIcon/></IconButton>
						</Toolbar>
					</AppBar>
				</ElevationScroll>
				{cart?.itemCount === 0 ? (
					<Box display="flex" alignItems="center" justifyContent="center" flexGrow="1">
						<EmptyCart/>
					</Box>
				):(<>
					<div>
						{cart?.lines?.map((item:any) => <MiniCartLine key={item?.id} item={item} canAddToCart={canCreateCart}/>)}
					</div>
				
					<div className={classes.totals}>
						<Box display="grid" gridTemplateColumns="1fr auto" paddingLeft={8}>
							<Typography variant="body2" color="textSecondary">Sub Total (List price):</Typography>
							<Typography variant="body2" color="textPrimary">{formatCurrency(cart.subtotal)}</Typography>
						</Box>
						<Box display="grid" gridTemplateColumns="1fr auto" paddingLeft={8}>
							<Typography variant="body2" color="textSecondary">Discount:</Typography>
							<Typography variant="body2" color="textPrimary">{formatCurrency(cart.discountTotal, true)}</Typography>
						</Box>
						<Box display="grid" gridTemplateColumns="1fr auto" paddingLeft={8} marginTop={1}>
							<Typography variant="body1" color="textPrimary">Total:</Typography>
							<Typography variant="body1" color="textPrimary"><b>{formatCurrency(cart.total)}</b></Typography>
						</Box>
					</div>
				</>)}
			</div>
			<div className={classes.footer}>
				<Link component={RouterLink} to="/cart">
					<Button variant="contained" color="primary" disableElevation fullWidth>Go To Order Entry</Button>
				</Link>
			</div>
		</Drawer>
	</>);
};

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: '90vw',
    maxWidth: 400,
    flex: '1 0 100%',
    display: 'flex',
    flexDirection: 'column',
  },
  scroll: {
    flex: '1 0 0px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    overflow: 'hidden',
  },
  toolbar: {
    padding: theme.spacing(0, 2),
  },
  footer: {
    borderTop: `3px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  totals: {
    margin: theme.spacing(3, 2),
  },
}));

export default MiniCart;
