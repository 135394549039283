import { gql } from '@apollo/client';

export const GET_MEDIUM_ATTENTION_MESSAGES = gql`
  query GetMediumAttentionMessages {
    mediumAttentionMessages @client {
      id
      text
      variant
      severity
    }
  }
`;
