import React from 'react';

// Components
import Logo from 'components/Logo';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';


interface Props {
	children: React.ReactNode;
};

const DefaultLayout: React.FC<Props> = ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Container maxWidth="lg">
				<Grid container spacing={4} className={classes.grid}>
					<Grid item md={7} xs={12}>
						<div className={classes.logoWrapper}>
							<Logo className={classes.logo}/>
						</div>
					</Grid>
					<Grid item md={5} xs={12}>
						{children}
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		minHeight: '100vh',
		display: 'grid',
		placeItems: 'center',
		boxSizing: 'border-box',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	grid: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	logoWrapper: {
		display: 'grid',
		placeItems: 'center',
		height: '80%',
		padding: theme.spacing(0),
	},
	logo: {
		maxWidth: 300,
		maxHeight: 300,
		width: '60%',
	},
}));

export default DefaultLayout;
