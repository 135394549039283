import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField, ErrorMessage } from 'formik';
import clsx from 'clsx';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  name: string;
  label: string;
  [key: string]: any;
  className?: string;
}

export const Input = ({ label, ...props }: Props) => {
	const classes = useStyles();
  const [field, meta] = useField(props);

  return (
    <TextField
      label={label}
      {...field}
      {...props}
      className={clsx(classes.root, props.className)}
      onBlur={(e) => {
        if (props.onBlur) {
          props.onBlur(e);
        }
        if (field.onBlur) {
          field.onBlur(e);
        }
      }}
      error={!!meta.touched && !!meta.error}
      helperText={<ErrorMessage name={field.name} />}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
    '& input[readonly]': {
      '& + fieldset': {
        borderStyle: 'dashed',
      },
    },
	},
}));