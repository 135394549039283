import { gql, useMutation } from '@apollo/client';
import * as updateCartLineTypes from '__generated__/updateCartLine';

// Utils
import { useGlobalContext } from 'utils/globalContext';

// TODO: Convert selections to qgl fragment as we are using it also in queries/getCart
export const UPDATE_CART_LINE = gql`
	mutation updateCartLine($input: UpdateCartLineInput!) {
		updateCartLine(input: $input) {
			code
			success
			message
			cart {
				id
				subtotal
				itemCount
				total
				taxTotal
				freightTotal
				lines {
					productId
					name
					quantity
					listPrice
					amount
				}
			}
		}
	}
`;

export const useUpdateCartLine = () => {
	const { dispatch } = useGlobalContext();

	const [mutate, { data, loading, error }] = useMutation(UPDATE_CART_LINE);

	const customMutate = (
		productId: string,
		unitOfMeasureId: string,
		quantity: number,
		salesRepId?: string
	) => {
		return new Promise((resolve, reject) => {
			const variables = {
				input: {
					productId,
					unitOfMeasureId,
					quantity,
					salesRepId
				},
			};
			mutate({ variables }).then((response:any) => {
				if (response?.data?.updateCartLine?.success) {
					resolve(null);
				}
				else {
					dispatch({
						type: 'toast',
						payload: {
							open: true,
							severity: 'error',
							message: response?.data?.updateCartLine?.message,
						},
					});
					reject(response?.data?.updateCartLine?.message);
				}
			}).catch((error:any) => {
				dispatch({
					type: 'toast',
					payload: {
						open: true,
						severity: 'error',
						message: error.message,
					},
				});
				reject(error);
			});
		});
	};

	return { mutate: customMutate, data, loading, error };
};
