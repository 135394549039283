import React, { useState } from 'react';

import { addItemToWishList } from 'services/wish-list.service';
import { AddToMyListRequest, SavedList } from 'interfaces/myList';

// #region Material-UI
import { makeStyles, Theme, alpha, useTheme } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden';
import CreateMyListForm from './createMyListForm';
import { useMediaQuery } from '@material-ui/core';

import AddToSheetIcon from '@material-ui/icons/PostAdd';
import { useGetWishListList } from 'operations/queries/getWishListList';

import { useAddToWishlist } from 'operations/mutations/addToWishList';
import AddToMyListButton from './addToMyListButton';
import { Popover } from '@material-ui/core';

// #endregion Material-UI

interface Props {
  productId: number | null;
  unitOfMeasureId: number | null;
  quantity?: number | null;
  addBig?: boolean | null;
  disabled?: boolean | undefined;
}

const AddToMyList: React.FC<Props> = (props) => {
  const classes = useStyles();
  //   const myList: any[] = [];
  const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState<SavedList[]>([]);
  const [pendingValue, setPendingValue] = useState<SavedList[]>([]);
  const [viewCreate, setViewCreate] = useState(false);

  const { mutate: addToWishlist } = useAddToWishlist();

  const { data, loading: wishlistsLoading, error } = useGetWishListList();

  const myList: any = data && data.wishlistList ? data.wishlistList : [];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setViewCreate(false);
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
    // dispatch(fetchMyList(null));
  };

  const handleViewCreate = (value: boolean) => {
    setViewCreate(value);
  };

  const handleClose = () => {
    setValue(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleViewCreate(false);
  };

  const handleSuccess = () => {
    handleViewCreate(false);
  };

  //   const handleAddItem = (list: any) => {
  //     const addObj: AddToMyListRequest = {
  //       ProductId: props.productId || -1,
  //       AccountProductListId: list.id,
  //       Quantity: 1,
  //     };
  //     addItemToWishList(addObj).then((data: any) => {
  //     });
  //   };

  //   const handleAddToWishList = async (wishlistId: any) => {
  // await useAddToWishlists({
  //   variables: {
  // 	productId: props.productId,
  // 	wishlistId: wishlistId,
  //   }
  // });
  // 	  addToWishlist({
  // 		variables:{
  // 			input: {
  // 				productId: props.productId,
  // 				wishlistId: wishlistId,
  // 				quantityRequested: props.quantity,
  // 				unitOfMeasureId: props.unitOfMeasureId
  // 			}
  // 		}
  // 		// ,refetchQueries: [
  // 		//   {
  // 		// 	query: GET_WISH_LIST,
  // 		//   }
  // 		// ]
  // 	  }).then(() => {
  // 	  }).catch(()=>{});
  // };

  const containsProduct = (productId: any, unitOfMeasureId: any, list: any) => {
    let filteredProducts = list.products.edges.filter(
      (product: any) =>
        product.node.id == productId &&
        product.unitOfMeasure?.id == unitOfMeasureId
    );
    return filteredProducts.length > 0;
  };

  const open = Boolean(anchorEl);
  const id = open ? 'my-list' : undefined;

  const formatName = (name: string) => {
    if(name.length > 20) {
      return name.substring(0, 17) + '...'
    } else {
      return name;
    }
  }

  return (
    <div>
      {/* <Button color="primary" >
        <PostAddIcon />
        Add to My List
      </Button> */}
      {/*<Hidden smUp>*/}
      {mobile || !props.addBig?(
        <Button
          aria-label="add to planning sheet"
          color="primary"
          variant="outlined"
          className={classes.addButton}
          onClick={handleClick}
          disabled={!props.productId || props.disabled}
          fullWidth>
          <AddToSheetIcon />
        </Button>
      ):(
        <Button color="primary" variant="outlined" disabled={!props.productId || props.disabled} className={classes.addButton} fullWidth startIcon={<AddToSheetIcon />}  onClick={handleClick}>Planning Sheet</Button>
      )}
      {/*</Hidden>
      <Hidden xsDown>
        <Button color="primary" variant="outlined" className={classes.addButton} fullWidth startIcon={<AddToSheetIcon />}  onClick={handleClick}>Planning Sheet</Button>
      </Hidden>*/}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        //placement="bottom-start"
        className={classes.popper}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <div className={classes.header}>
          <div className={classes.text}>My Planning Sheets</div>
          <IconButton aria-label="close planning sheets" size="small" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>

        {viewCreate ? (
          <div className={classes.createForm}>
            <CreateMyListForm
              handleCancel={handleCancel}
              handleSuccess={handleSuccess}
            />
          </div>
        ) : (
          <Paper>
            {myList?.edges?.length
              ? myList.edges.filter((option: any) => option.node.isEditable).map((option: any) => (
                <div key={option.node.id} className={classes.listItem}>
                  <Typography variant="body1" className={classes.text}>
                    {formatName(option.node.name)}
                  </Typography>
                  <AddToMyListButton
                    containsProduct={containsProduct(
                      props.productId,
                      props.unitOfMeasureId,
                      option.node
                    )}
                    productId={props.productId}
                    unitOfMeasureId={props.unitOfMeasureId}
                    quantity={props.quantity}
                    wishlistId={option.node.id}
                    isEditable={option.node.isEditable}
                  />
                </div>
              ))
              : ''}
            <div className={classes.createButton}>
              <Button
                color="primary"
                size="small"
                aria-label="create planning sheet"
                onClick={(e) => handleViewCreate(true)}
              >
                Create Planning Sheet
              </Button>
            </div>
          </Paper>
        )}
      </Popover>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  clickAwayContainer: {
    display: 'inline-block',
  },
  popper: {
    // border: '1px solid rgba(27,31,35,.15)',
    // boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    // borderRadius: 3,
    // width: 300,
    // zIndex: 1,
    // fontSize: 13,
    // color: '#586069',
    // backgroundColor: '#f6f8fa',
    // padding: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #e1e4e8',
    padding: '2px 2px 2px 10px',
    fontWeight: 600,
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
  },
  options: {
    padding: 0,
    margin: 0,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'center',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  createButton: {
    borderTop: '1px solid #e1e4e8',
    padding: '8px 10px 4px 10px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#f6f8fa',
  },
  createForm: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  loading: {
    margin: '2rem auto',
    display: 'block',
  },
  success: {
    textAlign: 'center',
    color: theme.palette.success.main,
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingRight: 2,
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderTopWidth: 0,
    },
  },
  text: {
    flexGrow: 1,
  },
  addButton: {
    padding: theme.spacing(0.25, 1),
    minWidth: 0,
    height: 30,
    '&.MuiButton-outlined': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiButton-startIcon': {
      marginLeft: theme.spacing(-0.25),
      marginRight: theme.spacing(0.5),
    },
  },
  addedCheck: {
    marginRight: '3px',
  },
}));

export default AddToMyList;
