import { gql, useQuery } from '@apollo/client';
import _get from 'lodash/get';

import {
  chatMessages,
  chatMessages_messages,
} from '__generated__/chatMessages';
// Fragments
import { chatMessageFragment } from 'operations/fragments/chat';

export const MESSAGE_LIST = gql`
  query chatMessages($input: ChatMessageQueryInput!) {
    messages(input: $input) {
      pageInfo {
        top
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
        currentPage
      }
      nodes {
        ...ChatMessageFragment
      }
    }
  }
  ${chatMessageFragment}
`;

export const MESSAGE_SUB = gql`
  subscription messages {
    messages {
      ...ChatMessageFragment
    }
  }
  ${chatMessageFragment}
`;

export const useMessageList = (chatGroupId: string) => {
  const variables = { input: { chatGroupId, sort: 'Created', sortDesc: true } };

  const { data, loading, error, subscribeToMore, fetchMore } =
    useQuery<chatMessages>(MESSAGE_LIST, {
      variables,
    });

  const messages: chatMessages_messages | null = _get(
    data,
    'messages',
    {} as chatMessages_messages
  );

  return { data: messages, loading, error, subscribeToMore, fetchMore };
};
