import { gql } from '@apollo/client';

export const GET_COUNTRY = gql`
  query country($id: String!) {
    country(id: $id) {
      id
      name
      state {
        id
        name
      }
    }
  }
`;
