import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

// Utils
import { SiteContext } from 'utils/SiteProvider';



interface Props {
	
};

const SiteMetaData: React.FC<Props> = (props) => {
	const site:any = useContext(SiteContext);

	return (<>
		<Helmet>
			<title>{site.siteName}</title>
			<meta name="description" content="This is my page description" />
		</Helmet>
	</>);
};

export default SiteMetaData;
