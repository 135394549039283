import { gql } from '@apollo/client';

export const GET_HIGH_ATTENTION_MESSAGES = gql`
  query GetHighAttentionMessages {
    highAttentionMessages @client {
      id
      text
      variant
      severity
    }
  }
`;
