import {gql, useMutation} from '@apollo/client';
import { GET_WISH_LIST } from 'operations/queries';


const ADD_TO_WISHLIST = gql`
        mutation addToWishlist($input: AddToWishlistInput) {
            addItemToWishlist(input: $input) {
                id
				totalProducts
				products {
					edges {
              			node {
                			id
                			name
                			price
                			listPrice
                			created
                			img {
                  				id
                  				cdnURL
                  				alt
                			}
                			seoName
              			}
			  			unitOfMeasure {
							id
			  			}
            		}
				}
            }
        }
    `
export const useAddToWishlist = () => {
	const [mutate, { data, error }] = useMutation<any>(ADD_TO_WISHLIST, {
	  update(cache, { data: { addItemToWishlist } }) {
		const variables = {
		  tree: false
		};
		const cachedData: any =
		  cache.readQuery({ query: GET_WISH_LIST, variables }) || {};
  
		if (cachedData.wishListList !== undefined) {
			cachedData.wishListList.map((wishlist: any) => wishlist.id == addItemToWishlist.id ? addItemToWishlist : wishlist);
		  cache.writeQuery({
			query: GET_WISH_LIST,
			variables,
			data: cachedData,
		  });
		}
	  },
	});
  
	// const [mutate, { data, error }] = useMutation<any>(ADD_TO_WISHLIST, {
	// 	refetchQueries: [
	// 		{ query: GET_WISH_LIST },
	// 	],

	// });

	return { mutate, data, error };
  };