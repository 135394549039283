import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Components
import UnitOfMeasure from 'components/UnitOfMeasure';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';


interface Props {
	item: any;	
	canAddToCart: boolean;
};

const MiniCartLine: React.FC<Props> = ({ item, canAddToCart }:any) => {
	const classes = useStyles();


	const handleImageError = (e:any) => {
		const noImage = (entityType === 'wines' || entityType === 'beers' || entityType === 'spirits') ? entityType : 'other';
		e.target.src = `/no-image-${noImage}.svg`;
	};

	const getAttribute = (attribute:string) => {
		return item?.product?.attributes?.find((obj:any) => obj.Name === attribute)?.Value?.trim();
	};

	const entityType = getAttribute('DWCommodityId').toLowerCase() || 'other';
	const bottlesPerCase = parseInt(getAttribute('DWBottlesPerCase') || '0', 10);
	const available = parseInt(item?.product?.inventory?.available || '0', 10);
	const allocated = parseInt(item?.product?.inventory?.onAllocation || '0', 10);

	return (
		<div className={classes.item}>
			<div className="listItemInfo">
				{/* <Link component={RouterLink} to={`/product-detail/${item?.product?.sku}`} color="textPrimary">
					<img className={classes.image} src={item?.cdnUrl || ''} alt="product" onError={handleImageError}/>
				</Link> */}
				<div className="listItemDetail">
					<Box display="flex">
						<Typography variant="subtitle2" display="block">#{item?.product?.combo[0]?.code || item?.product?.sku || ''}</Typography>
						<Box flexGrow={1}/>
						<Typography variant="subtitle2" display="inline" color="textSecondary">Size:</Typography>&nbsp;&nbsp;
						<Typography variant="subtitle2" display="block">{getAttribute('DWSize')}</Typography>
					</Box>
					<Link component={RouterLink} to={`/product-detail/${item?.product?.sku}`} color="textPrimary">
						<Typography variant="body1" display="block">{item?.product?.name}</Typography>
					</Link>
					{!item?.product?.combo || !item?.product?.combo.length || !(item?.product?.combo.length > 0) || (!item?.product?.combo[0]?.code)  ? <Grid container>
						<Grid item xs={12}>
							<Typography variant="subtitle2" display="inline" color="textSecondary">Units / Case:</Typography>&nbsp;&nbsp;
							<Typography variant="body2" display="inline" color="textPrimary">{getAttribute('DWBottlesPerCase')}</Typography>
						</Grid>
					</Grid> : null}
				</div>
			</div>
			{item.message && (
				<Alert variant="filled" severity="info" className={classes.alert}>{item.message}</Alert>
			)}
			<div>
				<UnitOfMeasure
					variant="edit"
					productId={item?.product?.id}
					pricing={item?.pricing}
					quantity={item?.quantity}
					available={available}
					allocated={allocated}
					bottlesPerCase={bottlesPerCase}
					canAddToCart={canAddToCart}
				/>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	item: {
		padding: theme.spacing(1),
		borderBottom: `1px solid ${theme.palette.divider}`,
		'& > .listItemInfo': {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0.5, 0),
			'& > .listItemDetail': {
				flex: '1 0 0px',
				marginLeft: theme.spacing(1),
			},
		},
		'&:last-child': {
			borderBottomWidth: 3,
		},
	},
	image: {
		objectFit: 'contain',
		width: 50,
		height: 50,
	},
	alert: {
		margin: theme.spacing(0.5, 0),
		padding: theme.spacing(0.5, 1),
		'& > *': {
			padding: 0,
		},
	},
}));

export default MiniCartLine;
