import { gql, useQuery } from '@apollo/client';
import _get from 'lodash/get';

import {
  getNotifications,
  getNotifications_notifications,
} from '__generated__/getNotifications';

export const pollInterval = 10000;

export const NOTIFICATION_LIST = gql`
  query getNotifications($first: Int, $after: String) {
    notifications(first: $first, after: $after) {
      pageInfo {
        resultsReturned
        top
        skip,
        hasNextPage,
        hasPreviousPage
        endCursor
      }
      recordCount
      edges {
        cursor
        node {
          id
          title
          body
          readAt
          isRead
          type {
            id
            code
          }
          extendedFields
          created
        }
      }
    }
    notificationUnReadCount
  }
`;

export const useNotificationList = () => {
  const { data, loading, error, startPolling, stopPolling, fetchMore } =
    useQuery<getNotifications>(NOTIFICATION_LIST, {
      pollInterval,
      variables: {
        first: 12,
      },
    });

  const notifications = _get<
    getNotifications,
    'notifications',
    getNotifications_notifications
  >(data, 'notifications', {} as getNotifications_notifications);
  const unreadCount = _get<getNotifications, 'notificationUnReadCount', number>(
    data,
    'notificationUnReadCount',
    0
  );
  return {
    data: notifications,
    unreadCount,
    fetchMore,
    loading,
    error,
    startPolling,
    stopPolling,
  };
};
