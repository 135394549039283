import { gql } from '@apollo/client';


export const lastMessageFragment = gql`
  fragment LastChatMessageFragment on ChatMessage {
    id
    created
    body
    isRead
  }
`;
