import React, { useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

interface Props {
  position: 'start' | 'end';
  aria: string;
  onChange: (isOn: boolean) => void;
}

export const PasswordInputToggle = (props: Props) => {
  const [visible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!visible);
    props.onChange(!visible);
  };
  return (
    <InputAdornment position={props.position}>
      <IconButton
        aria-label={props.aria}
        edge={props.position}
        onClick={toggle}
      >
        {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </InputAdornment>
  );
};

PasswordInputToggle.defaultProps = {
  position: 'end',
  aria: 'Toggle password visibility',
  onChange: (visible = false) => {},
};
