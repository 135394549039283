import { gql, useMutation, useApolloClient } from '@apollo/client';
import * as logoutTypes from '__generated__/logout';

// Utils
import { clearToken } from 'utils/auth';

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const useLogout = () => {
  const client = useApolloClient();
  const [mutate, { data, error }] = useMutation<logoutTypes.logout>(LOGOUT);

  const customMutate = () => {
		return new Promise((resolve, reject) => {
			mutate().then((data) => {
        clearToken();
        client.cache.reset();

        return resolve(data);
      }).catch(reject);
		});
	}

  return { mutate:customMutate, data, error };
};
