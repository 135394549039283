import { useState, useEffect } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { pageInfoFragment } from 'operations/fragments/pageInfoFragment'
import _get from 'lodash/get'; 

// Utils
import { sendGoogleAnalyticsEvent } from 'utils/GoogleAnalytics';
import { ListFilter, listFilterParams } from 'utils/listFilter';


export const PRODUCT_SEARCH = gql`
	query productSearch(
			$categoryId:String,
			$filters:[ODataFilter],

			$top: Int
			$skip: Int
			$search: String
			$sort: String
			$sortDesc: Boolean
			$first: Int
			$after: String
			$before: String
		) {
		settingEnumByHandle(handle:"products.sortOptions") {
			Code
			DisplayName
		}
		productList(
			categoryId:$categoryId,
			filters:$filters,
			top: $top,
			skip: $skip,
			search: $search,
			sort: $sort,
			sortDesc: $sortDesc,
			first: $first,
			after: $after,
			before: $before,
		) {
			recordCount
			pageInfo {
				...PageInfoFragment
			}
			facets {
				id
				name
				value {
					id
					attributeId
					valueId
					value
					count
					selected
					rank
				}
			}
			edges {
				cursor,
				node {
					id
					name
					sku
					listPrice
					productTypeName
					img {
						cdnUrl
						alt
					}
					category {
						id
						name
					}
					attributes {
						AttributeId
						Name
						Value
					}
					inventory {
						available
					}
					price:advancedPrice {
						id
						value
						unitOfMeasure {
							id
							name
							displayName
						}
					}
					combo {
						id
						code
						allowMultiple
					}
				}
			}
		}
	}
	${pageInfoFragment}
`;

export const filterReducer = (state: any, action: any) => {
	switch (action.type) {
		case 'reset':
			return action.payload || {}
		case 'top':
			return { ...state, top: action.payload }
		case 'skip':
			return { ...state, skip: action.payload }
		case 'before':
			return { ...state, before: action.payload, last: listFilterParams.last }
		case 'after':
			return { ...state, after: action.payload, first: listFilterParams.first }
		case 'sort':
			return { ...state, sort: action.payload }
		case 'sortDesc':
			return { ...state, sortDesc: action.payload }
		case 'search':
			return { ...state, search: action.payload, skip: 0 }
		case 'filters':
			return { ...state, filters: action.payload }
	}
};

export const useProductSearch = (filter?: ListFilter) => {

	const [search, setSearch] = useState('');

	const variables:any = { ...filter };

	if(!variables?.filters?.find((item: any) => item.key == 'product')) {
		if(variables?.filters?.length && variables.filters.length > 0) {
		  variables.filters.push({key: 'product', value: 'PRODUCT'});
		} else {
		  variables.filters = [{key: 'product', value: 'PRODUCT'}]
		}
	}

	if(!variables.search) {
		variables.search = '';
	}
	else if(variables?.search?.charAt(variables.search.length - 1) == ' ') {
		variables.search = variables.search.substring(0, variables.search.length - 1);
	} else {
		variables.search = variables.search + '*';
	}
	
	if(variables.search) {
		variables.search = variables.search
	}

	const { data, loading, error, fetchMore } = useQuery(PRODUCT_SEARCH, { 
		variables: {...variables},
		fetchPolicy: 'network-only'
	});

	const productList:any = _get(data, 'productList', {});
	const sortOptions:any = _get(data, 'settingEnumByHandle', []).map((obj:any) => { return { value: obj.Code, text: obj.DisplayName }});
	
	useEffect(() => {
		if(filter?.search && search !== filter?.search)
			{setSearch(filter?.search || '');}
	},[filter]);

	useEffect(() => {
		if(search !== '')
			{sendGoogleAnalyticsEvent('view_search_results', { 'search_term': search });}
	},[search]);

	const loadMore = () => {
		const newVariables = {...variables, first: listFilterParams.first, after: productList.pageInfo.endCursor};

		return new Promise((resolve, reject) => {
			fetchMore({ variables: newVariables }).then(resolve).catch(reject);
		});
	};

	return { productList, sortOptions, loading, error, loadMore };
};


export const PRODUCT_TYPEAHEAD = gql`
	query productTypeAheadSearch(
			$categoryId:String,
			$filters:[ODataFilter],

			$top: Int
			$skip: Int
			$search: String
			$sort: String
			$sortDesc: Boolean
			$first: Int
			$after: String
			$before: String
		) {
		productList(
			categoryId:$categoryId,
			filters:$filters,
			top: $top,
			skip: $skip,
			search: $search,
			sort: $sort,
			sortDesc: $sortDesc,
			first: $first,
			after: $after,
			before: $before,
		) {
			recordCount
			edges {
				cursor,
				node {
					id
					name
					sku
				}
			}
		}
	}
`;

export const useProductTypeahead = () => {

	const [query, { data, loading, error }] = useLazyQuery(PRODUCT_TYPEAHEAD, { fetchPolicy: 'no-cache' });

	const products:any = _get(data, 'productList', {});

	return { products, loading, error, query };
};