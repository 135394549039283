import { AUTH_TOKEN_NAME, REFRESH_TOKEN_NAME } from 'helpers/constants';
import { decodeToken } from 'services';
import { DecodedToken } from '../interfaces';
import { login_login } from '__generated__/login';
import { PureAbility, AbilityBuilder } from '@casl/ability';

const tokenStorage: Storage = localStorage;
interface clearTokenOption {
  redirectToRoot: boolean;
}

const setAuthInfo = (loginData: login_login) => {
  // TODO: Modify graphql schema to correct type
  tokenStorage.setItem(AUTH_TOKEN_NAME, loginData?.accessToken?.token || '');
};
const getAuthToken = () => {
  const token = tokenStorage.getItem(AUTH_TOKEN_NAME);
  return token;
};

export const getUserFromToken = (token: string | null): DecodedToken | null => {
  if (!token) {
    return null;
  }
  const user = decodeToken(token);
  return user;
};

/**
 * Reads current access token and is possible returns number of miliseconds
 * until the expiry. 
 * @returns number representing miliseconds until token expires
 */
export const getCurrentTokenExpiresIn = () => {
  let token = getAuthToken();
  let decodedToken = null;
  try {
    decodedToken = decodeToken(token);
    if (typeof decodedToken?.exp === 'number') {
      const expiryInMiliseconds = (new Date(decodedToken.exp * 1000).getTime() - new Date().getTime());
      return expiryInMiliseconds;
    }
    return 0;
  } catch (error) {
    return 0;
  }
};
const getRefreshToken = () => {
  const refreshToken = tokenStorage.getItem(REFRESH_TOKEN_NAME);
  return refreshToken;
};
const clearToken = (options: clearTokenOption = { redirectToRoot: true }) => {
  tokenStorage.removeItem(AUTH_TOKEN_NAME);
  if (options.redirectToRoot && window.location.pathname !== '/') {
    if (window !== undefined) {
      window.location.href = '/';
    }
  }
};

const tryRefreshToken = (fn: Function) => {
  const token = getAuthToken();
  const refreshToken = getRefreshToken();

  if (token && refreshToken) {
    fn({
      variables: {
        accessToken: token,
      },
    });
  } else {
    console.log('token issue in try refresh token');
  }
};

const getClaims = (claims: any[] | null) => {
  const ability = new PureAbility();
  if (!claims) {
    return ability;
  }

  const { can, cannot, rules } = new AbilityBuilder();
  claims.forEach((claim: any) => {
    if (claim.isCan) {
      can(claim.action, claim.subject);
    } else {
      cannot(claim.action, claim.subject);
    }
  });
  return ability.update(rules);
};

export { getAuthToken, clearToken, tryRefreshToken, setAuthInfo, getClaims };
