import {gql, useMutation} from '@apollo/client';
import { CreateWishListInput } from '__generated__/globalTypes';
import {GET_WISH_LIST} from '../queries/getWishListList';


const UPSERT_WISHLIST = gql`
    mutation upsertWishlist($wishlist: CreateWishListInput!) {
        upsertWishlist(wishList: $wishlist)
        {
            id
            name
            comment
            isPublic
        }
    }
`

export const useUpsertWishlist = () => {
    const [mutate, { data, error }] = useMutation<CreateWishListInput>(UPSERT_WISHLIST , {refetchQueries: [{query: GET_WISH_LIST}]});
    return { mutate, data, error };
};
