// @ts-ignore
import React, { useState, useContext, useEffect, useRef } from 'react';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _keyBy from 'lodash/fp/keyBy';
import _values from 'lodash/values';

// Local
import MessageList from './MessageList';
import NewMessage from './NewMessage';

// Operations
import { useMessageList, MESSAGE_SUB } from 'operations/queries/chatMessages';
import { useMarkChatMessageRead } from 'operations/mutations/markChatMessageRead';

// Utils
import { SiteContext } from 'utils/SiteProvider';
import { useSnackbar } from './../HeaderSnackbar/SnackbarProvider';
// Icons
import MessageIcon from '@material-ui/icons/Message';

// Material-UI
import { makeStyles, Theme, darken, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Popover from '@material-ui/core/Popover';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFavIconNotification } from './hooks';

interface Props {
  chatGroupId: string;
}

const Messages: React.FC<Props> = (props) => {
  const { chatGroupId } = props;
  const { addNotification } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const anchorEl: any = useRef(null);
  const scrollEl: any = useRef(null);
  const site: any = useContext(SiteContext);
  const [open, setOpen] = useState(false);
  const [mute, setMute] = useState(false);
  const { setUnreadCount, hasNewMessages } = useFavIconNotification(0);
  const userId = site?.session?.user?.contact?.id;
  // Operations
  const {
    data: messages,
    loading: loadingList,
    subscribeToMore,
    fetchMore,
  } = useMessageList(props.chatGroupId);
  const { mutate: markRead } = useMarkChatMessageRead(props.chatGroupId);

  const loading = loadingList;

  useEffect(() => {
    if (open) {
      requestAnimationFrame(() => {
        scrollEl.current.scrollTop = scrollEl.current.scrollHeight;
      });
    }
  }, [open, messages]);

  /**
   * @description 
   * Checks if the received message is an outgoing message. 
   * This is required because we trigger new message on the server even for the
   * outgoing messages to keep the UI in sync.
   * 
   * @param newMessage {Object} - message received or sent
   * @returns {boolean} - True if message is an outgoing message.
   */
  const isOwnMessage = (newMessage: any): boolean => {
    const currentUserId = site?.session?.user?.contact?.id;
    const messageSenderId = newMessage?.from.id;

    return currentUserId !== null && currentUserId === messageSenderId;
  }
  useEffect(() => {
    const variables = {
      input: { chatGroupId: chatGroupId, sort: 'Created' },
    };
    const unsubscribeFromMessages = subscribeToMore({
      document: MESSAGE_SUB,
      variables,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const newMessage = _get(subscriptionData, 'data.messages', {});
        if (newMessage && !isOwnMessage(newMessage)) {
          addNotification({
            title: newMessage.from.fullName,
            body: newMessage.body,
            type: 'message',
          });
        }

        const nodes = prev?.messages?.nodes || [];
        return Object.assign({}, prev, {
          messages: {
            pageInfo: { ...prev?.messages?.pageInfo },
            nodes: [newMessage, ...nodes],
          },
        });
      },
      onError: (err) => {
        console.error('subscribe to more error : try token refresh::: ', err);
      },
    });
    return () => {
      if (typeof unsubscribeFromMessages === 'function') {
        unsubscribeFromMessages();
      }
    };
  }, []);

  const hasUnreadMessages = (): boolean => {
    return messages?.nodes?.some((message) => message.isRead === false) || false;
  };
  const handleClose = async () => {
    try {
      if (!!messages && messages?.nodes?.length > 0) {
        const lastMessage = messages.nodes[messages.nodes.length - 1];
        if (lastMessage.isRead === false) {
          setUnreadCount(0);
          await markRead(chatGroupId, lastMessage.id);
        }
      }
    } catch {}
    setOpen(false);
  };
  const handleScroll = (event: any) => {
    const element = event.target;
    const scrollTop = element.scrollTop;
    const scrollThreshold = 20;
    let loadingMessages = false;
    if (scrollTop < scrollThreshold) {
      if (!loadingMessages && messages?.pageInfo.hasNextPage) {
        const skip = Math.floor(
          (messages?.pageInfo.top || 12) *
            (messages?.pageInfo?.currentPage || 1)
        );
        fetchMore({
          variables: {
            input: {
              chatGroupId: chatGroupId,
              skip,
            },
          },
          updateQuery(prev, { fetchMoreResult }) {
            if (!fetchMoreResult?.messages) return prev;

            const newMessages = _get(fetchMoreResult, 'messages.nodes', []);
            const nodes = prev?.messages?.nodes || [];
            const mergedMessages = _merge(
              _keyBy('id')(nodes),
              _keyBy('id')(newMessages)
            );
            const messages = _values(mergedMessages);
            return Object.assign({}, prev, {
              messages: {
                pageInfo: {
                  ...prev?.messages?.pageInfo,
                  ...fetchMoreResult?.messages.pageInfo,
                },
                nodes: messages,
              },
            });
          },
        });
      }
      loadingMessages = true;
    }
  };
  const handleMessageOnScreen = (message: any) => {
    if (message.isRead === false) {
      markRead(props.chatGroupId, message.id).catch((error) => {
        console.error(`There is an error marking ${message.id} as read.`);
      });
    }
  };
  return (
    <>
      <Button
        ref={anchorEl}
        arial-label="messages"
        className={classes.smallButton}
        data-new={hasUnreadMessages()}
        variant="contained"
        disableElevation
        size="small"
        color="primary"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <MessageIcon fontSize="small" />
        Messages
      </Button>

      {anchorEl.current && (
        <Popover
          anchorEl={anchorEl.current}
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: mobile ? 'center' : 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: mobile ? 'center' : 'right',
          }}
          onClose={() => handleClose()}
        >
          <Box display="flex" alignItems="center" paddingLeft={1.5} height={40}>
            <Typography variant="h6">Messages</Typography>
            <Box flexGrow={1} />
            <FormControlLabel
              label="Mute"
              classes={{ label: classes.muteLabel }}
              control={
                <Switch
                  checked={mute}
                  color="primary"
                  size="small"
                  onChange={() => setMute(!mute)}
                />
              }
            />
          </Box>
          <Divider />
          <div
            ref={scrollEl}
            className={classes.scroll}
            onScroll={handleScroll}
          >
            {loading ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                minHeight="130px"
              >
                <CircularProgress size={30} />
              </Box>
            ) : (
              <MessageList
                onScreenView={handleMessageOnScreen}
                userId={userId}
                messages={messages?.nodes || []}
              />
            )}
          </div>
          <Divider />
          <NewMessage chatGroupId={chatGroupId} />
        </Popover>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  smallButton: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: darken(theme.palette.primary.dark, 0.05),
    transform: 'scale(0.9)',
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.dark, 0.15),
    },
    '& > .MuiButton-label > .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiBadge-root': {
      marginRight: theme.spacing(0.5),
    },
    '&[data-new="true"]': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      animation: 'pulse-secondary 2s infinite',
    },
  },
  muteLabel: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  scroll: {
    maxHeight: 400,
    overflow: 'auto',
    width: 450,
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1),
    },
  },
}));

export default Messages;
