import { gql } from '@apollo/client';

export const SET_SOBO_MODE = gql`
    mutation setSoboModeDrawer($id: ID!) {
        setSoboMode(id: $id) {
            contactId
        }
    }
`;

export const EXIT_SOBO_MODE = gql`
mutation exitSoboModeDrawer {
	exitSoboMode
}
`;