import { gql } from '@apollo/client';

// Fragments
import { productFragment } from 'operations/fragments/product';

export const cartLinesFragment = gql`
  fragment CartLinesFragment on CartLine {
    id
    productId
    quantity
    available
    discount
    listPrice
    price
    amount
    salesRep {
      id,
      name,
      code
    }
    unitOfMeasure: uom {
      id
      displayName
      quantityInBaseUnit
    }
    product {
      ...ProductFragment
    }
  }
  ${productFragment}
`;
