import React, { useState, useContext, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

// Local
import SoboSearch from './SoboSearch';

// Operations
import {
  useSelectSobo,
  useUnselectSobo,
} from 'operations/mutations/selectSobo';

// Utils
import { SiteContext } from 'utils/SiteProvider';

// Icons
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

// Material-UI
import { makeStyles, Theme, darken } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';

interface Props {}

const Sobo: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const site: any = useContext(SiteContext);
  const sobo = site?.session?.sobo;

  // State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Operations
  const { mutate: selectSobo } = useSelectSobo();
  const { mutate: unselectSobo } = useUnselectSobo();

  const getInitials = (name: string) => {
    if (!name || name.indexOf(' ') === -1) {
      return '';
    }

    const nameSplit = name.split(' ');
    return `${nameSplit[0]?.substr(0, 1) || ''}${
      nameSplit[1]?.substr(0, 1) || ''
    }`;
  };

  const handleIconButton = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSelectSobo = async (id: string) => {
    try {
      setAnchorEl(null);
      await selectSobo(id);

      history.push('/account');
    } catch {}
  };

  const handleUnselectSobo = async () => {
    try {
      await unselectSobo();
      history.push('/account');

    } catch {}
  };

  return (
    <>
      {sobo ? (
        <IconButton
          className={classes.iconButton}
          data-open={Boolean(anchorEl)}
          data-selected
          color="inherit"
          aria-label="account selection"
          onClick={handleIconButton}
        >
          <Badge
            overlap="circle"
            badgeContent={
              <Avatar className={classes.avatarBadge}>
                {getInitials(sobo?.account?.name)}
              </Avatar>
            }
          >
            <GroupIcon />
          </Badge>
        </IconButton>
      ) : (
        <IconButton
          className={classes.iconButton}
          data-open={Boolean(anchorEl)}
          color="inherit"
          aria-label="account selection"
          onClick={handleIconButton}
        >
          {!!sobo ? <GroupIcon /> : <GroupAddIcon />}
        </IconButton>
      )}

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={3}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box component={Paper} width="90vw" maxWidth={350}>
          {sobo ? (
            <Box padding={1.5}>
              <Typography variant="body1" gutterBottom>
                <b>Account</b>
              </Typography>
              <Divider />
              <Box paddingY={2} display="flex">
                <Avatar className={classes.bigAvatar}>
                  {getInitials(sobo?.account?.name)}
                </Avatar>
                <Box className={classes.selectedDetails}>
                  <Typography variant="h6" display="block">
                    {sobo?.account?.name}
                  </Typography>
                  <div>
                    <Typography
                      variant="subtitle2"
                      display="inline"
                      color="textSecondary"
                    >
                      Account #:
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {sobo?.account?.code}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="subtitle2"
                      display="inline"
                      color="textSecondary"
                    >
                      Location:
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {sobo?.account?.defaultBillingAddress?.city},{' '}
                      {sobo?.account?.defaultBillingAddress?.stateName}
                    </Typography>
                  </div>
                </Box>
              </Box>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disableElevation
                onClick={() => handleUnselectSobo()}
              >
                Exit Account
              </Button>
            </Box>
          ) : (
            <SoboSearch handleSelect={handleSelectSobo} />
          )}
        </Box>
      </Popover>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    '&[data-open="true"]': {
      display: 'inline-block',
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
      backgroundColor: darken(theme.palette.primary.dark, 0.05),
    },
    '&[data-selected="true"]': {
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
      backgroundColor: darken(theme.palette.primary.dark, 0.05),
    },
  },
  list: {
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  inputBase: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1, 1, 1, 0.5),
      transition: theme.transitions.create(['border-color', 'box-shadow']),

      fontSize: 14,
    },
  },
  progress: {
    position: 'absolute',
    top: 2,
    right: theme.spacing(0.5),
  },
  avatar: {
    '& > .MuiAvatar-root': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  bigAvatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: theme.spacing(7),
    height: theme.spacing(7),
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(2),
  },
  avatarBadge: {
    width: theme.spacing(3.25),
    height: theme.spacing(3.25),
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    transform: 'translate(10px, -10px)',
  },
  selectedDetails: {
    '& .MuiTypography-colorTextSecondary': {
      marginRight: theme.spacing(1),
    },
  },
}));

export default Sobo;
