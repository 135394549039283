import React, { useState, useRef, useEffect } from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';

import AlertMessage from 'components/GlobalMessages/components/Messages/AlertMessage';
import { useQuery } from '@apollo/client';

import { messageMutations } from 'operations/mutations/messages';

import { GET_HIGH_ATTENTION_MESSAGES } from 'operations/queries/messages/getHighAttentionMessages';

/**
 * Handles the logic for high attention messages
 */
interface Props {}

const HighAttentionMessageHandler: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { loading, data, error } = useQuery(GET_HIGH_ATTENTION_MESSAGES);

  const handleOk = (id: string) => {
    messageMutations.popHighAttentionMessage();
  };

  return (
    <div className={classes.root}>
      {!loading &&
      !error &&
      data &&
      data.highAttentionMessages &&
      data.highAttentionMessages.length > 0 ? (
        <div
          className={`${classes.highAttention} ${classes.animationInBackdrop}`}
        >
          <div className={classes.animationInMessage}>
            <AlertMessage
              variant={
                data.highAttentionMessages[
                  data.highAttentionMessages.length - 1
                ].variant
              }
              severity={
                data.highAttentionMessages[
                  data.highAttentionMessages.length - 1
                ].severity
              }
              handleOk={handleOk}
            >
              {
                data.highAttentionMessages[
                  data.highAttentionMessages.length - 1
                ].text
              }
            </AlertMessage>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  highAttention: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    background: 'rgba(355,355,355, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alertMarginBottom: {
    marginBottom: theme.spacing(1),
  },
  animationInMessage: {
    position: 'relative',
    display: 'block',
    transform: 'translateY(15%)',
    animation: '$fadeInUp 100ms linear forwards',
    zIndex: 1,
  },
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(15%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  animationInBackdrop: {
    animation: '$fadeIn 100ms linear forwards',
    zIndex: 1,
    opacity: 1,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

export default HighAttentionMessageHandler;
