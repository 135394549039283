import React, { useState } from 'react';

// Material-UI
import { makeStyles, Theme, lighten } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { useAddToWishlist } from 'operations/mutations/addToWishList'
import CircularProgress from '@material-ui/core/CircularProgress';

import AddToSheetIcon from '@material-ui/icons/PostAdd';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';



interface Props {
	productId: number | null;
	unitOfMeasureId: number | null;
	quantity?: number | null;
	wishlistId: number | null;
	isEditable: boolean;
	containsProduct: boolean;
}
  
const AddToMyListButton: React.FC<Props> = (props) => {
	const classes = useStyles();

	const [updateProgress, setUpdateProgress] = useState('');

	const { mutate: addToWishlist } = useAddToWishlist();

	const handleAdd = async () => {
		setUpdateProgress('loading');
		try {
			await  addToWishlist({
				variables:{
					input: {
						productId: props.productId,
						wishlistId: props.wishlistId,
						quantityRequested: props.quantity,
						unitOfMeasureId: props.unitOfMeasureId
					}
				}
				// ,refetchQueries: [
				//   {
				// 	query: GET_WISH_LIST,
				//   }
				// ]
			  });
			setTimeout(() => {
				setUpdateProgress('success');
				setTimeout(() => {
					setUpdateProgress('');
				}, 2000);
			}, 500);
		}
		catch(error) {
			setUpdateProgress('error');
			setTimeout(() => {
				setUpdateProgress('');
			}, 2000);
		}
	};

	if(props.containsProduct) {
		return <Button variant="contained" color="secondary" className={classes.addButton + ' ' + classes.buttonHover} size="small" disableRipple disableElevation aria-label="added to planning sheet"><CheckIcon fontSize="small"/></Button>
	}

	return (
		<div>
			{updateProgress === 'loading' && (<Button color="primary" variant="outlined" className={classes.addButton} size="small" disableRipple disableElevation aria-label="loading"><CircularProgress color="inherit" size={20}/></Button>)}
			{updateProgress === 'success' && (<Button variant="contained" color="secondary" className={classes.addButton + ' ' + classes.buttonHover} size="small" disableRipple disableElevation aria-label="success"><CheckIcon fontSize="small"/></Button>)}
			{updateProgress === 'error'   && (<IconButton color="secondary" size="small" aria-label="error"><ErrorIcon fontSize="small"/></IconButton>)}
			{updateProgress === '' 		  && (<Button variant="outlined" color="primary" className={classes.addButton} size="small" disableRipple disableElevation disabled={!props.isEditable} onClick={handleAdd} aria-label="add to planning sheet"><AddToSheetIcon fontSize="small"/></Button>)}
		</div>
	)
}

const useStyles = makeStyles((theme:Theme) => ({
	addButton: {
		padding: theme.spacing(0.1),
		minWidth: 0,
		'&.MuiButton-outlined': {
			backgroundColor: theme.palette.background.paper,
		},
		'&:focus': {
			backgroundColor: lighten(theme.palette.primary.main, 0.8),
		},
		marginRight: '3px',
		width: '23px',
		height: '23px',
	},
	buttonHover: {
		cursor: 'default',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			cursor: 'default'
		}
	}
}));

export default AddToMyListButton;