import { gql, useQuery } from '@apollo/client';
import _get from 'lodash/get';

export const GET_USER = gql`
	query user {
		me {
			id
			firstName
			lastName
			fullName
			email
			defaultShippingAddressId
			defaultBillingAddressId
			cutoff {
				stateId
				stateName
				cutoffTime
			}
            account {
        		id
        		name
        		code
        		dbaName
      		}
			addresses {
				id
				name
				address1
				city
				stateId
				stateName
				countryId
				countryName
				isPrimary
				firstName
				lastName
			}
			claims {
				id
				action
				actionId
				subject
				subjectId
				field
				fieldId
				isCan
				isPassThrough
			}
			stateCode
		}
	}
`;

export const useUser = () => {
	const { data, loading, error } = useQuery(GET_USER);

	const me = _get(data, 'me', {});

	return { data: me, loading, error };
};


export const USER_CACHE = gql`
	query userCache {
		me @client {
			id
			firstName
			lastName
			fullName
			email
			defaultShippingAddressId
			defaultBillingAddressId
			cutoff {
				stateId
				stateName
				cutoffTime
			}
			account {
        		id
        		name
        		code
        		dbaName
      		}
			addresses {
				id
				name
				address1
				city
				stateId
				stateName
				countryId
				countryName
				isPrimary
				firstName
				lastName
			}
			claims {
				id
				action
				actionId
				subject
				subjectId
				field
				fieldId
				isCan
				isPassThrough
			}
			stateCode
		}
	}
`;

export const useUserCache = () => {
	const { data, loading, error } = useQuery(USER_CACHE);

	const me = _get(data, 'me', {});

	return { data: me, loading, error };
};
