import { gql, useMutation } from '@apollo/client';
import * as updateCartTypes from '__generated__/updateCart';

export const UPDATE_CART = gql`
	mutation updateCart($input: UpdateCartInput) {
		updateCart(input: $input) {
			id
			email
			salesRep {
				id
				code
				name
			}
		}
	}
`;

export const useUpdateCart = () => {
	const [mutate, { data, loading, error }] = useMutation<updateCartTypes.updateCart, Partial<updateCartTypes.updateCartVariables>>(UPDATE_CART);

	const customMutate = (input:Partial<updateCartTypes.updateCartVariables>) => {
		return new Promise((resolve, reject) => {
			const variables:any = {
				input,
			};
			mutate({ variables }).then(resolve).catch(reject);
		});
	};

	return { mutate: customMutate, data, loading, error };
};


