import React, { useRef, useState, useEffect } from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';

import AddToCartMessage from 'components/GlobalMessages/components/Messages/AddToCartMessage';
import { useQuery } from '@apollo/client';

import { messageMutations } from 'operations/mutations/messages';

import { GET_ADD_TO_CART_MESSAGES } from 'operations/queries/messages/getAddToCartMessages';

/**
 * Handles the logic for Add to Cart messages
 * @prop {number} timeOut The time that a message is viewable for
 */
interface Props {
  timeOut: number;
}

const AddToCartMessageHandler: React.FC<Props> = (props) => {
  const classes = useStyles();

  const addToCartTimeout: React.MutableRefObject<any> = useRef();
  const poppingAddToCart: React.MutableRefObject<boolean> = useRef(false);

  const messageId: React.MutableRefObject<number> = useRef(-1);

  const [animationClass, setAnimationClass] = useState(classes.addToCartHide);

  const animationTimeout: React.MutableRefObject<any> = useRef();

  // Start in animation
  const animateIn = () => {
    // Set the animation class to the reset class
    setAnimationClass(classes.animationNone);
  };

  // Start the out animation
  const animateOut = () => {
    setAnimationClass(classes.addToCartOut);
  };

  // Hide the component after it animates out
  const animateHide = () => {
    setAnimationClass(classes.addToCartHide);
  };

  // Start in animation
  useEffect(() => {
    // If animation class is the reset class, start the animation.
    // This is necessary for smooth reseting of the animation.
    if (animationClass === classes.animationNone) {
      clearTimeout(animationTimeout.current);
      animationTimeout.current = setTimeout(() => {
        setAnimationClass(classes.addToCartIn);
      }, 10);
    }
  }, [animationClass]);

  const { loading, data, error } = useQuery(GET_ADD_TO_CART_MESSAGES);

  const handlePopAddToCartMessage = () => {
    
    poppingAddToCart.current = true;
    clearTimeout(addToCartTimeout.current);
    addToCartTimeout.current = setTimeout(() => {
      animateOut();
      addToCartTimeout.current = setTimeout(() => {
        animateHide();
        poppingAddToCart.current = false;
        messageMutations.popAddToCartMessage();
      }, 190);
    }, props.timeOut);
  };

  const handleCloseMessage = () => {
    clearTimeout(addToCartTimeout.current);
    messageMutations.popAddToCartMessage();
  };

  const pausePopTimer = () => {
    clearTimeout(addToCartTimeout.current);
  };

  const restartPopTimer = () => {
    clearTimeout(addToCartTimeout.current);
    handlePopAddToCartMessage();
  };

  if (
    data?.addToCartMessages.length > 0 &&
    data?.addToCartMessages[data?.addToCartMessages.length - 1] !==
      messageId.current
  ) {
    animateIn();
    messageId.current =
      data?.addToCartMessages[data.addToCartMessages.length - 1];
    handlePopAddToCartMessage();
  }


  return (
    <div
      className={`${classes.root} ${animationClass}`}
      onMouseEnter={pausePopTimer}
      onMouseLeave={restartPopTimer}
      onTouchStart={pausePopTimer}
      onTouchEnd={restartPopTimer}
      
    >
      {!loading &&
      !error &&
      data &&
      data.addToCartMessages &&
      data.addToCartMessages.length > 0 ? (
        <AddToCartMessage
          message={data.addToCartMessages[0]}
          handleContinueShopping={handleCloseMessage}
        />
      ) : null}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // position: 'absolute'
  },
  alertMarginBottom: {
    marginBottom: theme.spacing(1),
  },
  addToCartIn: {
    position: 'relative',
    display: 'block',
    transform: 'translateX(-15%)',
    animation: '$fadeInRight 200ms linear forwards',
    zIndex: 1,
  },
  addToCartOut: {
    position: 'relative',
    display: 'block',
    transform: 'translateX(0)',
    animation: '$fadeOutRight 200ms linear forwards',
    zIndex: 1,
  },
  '@keyframes fadeInRight': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-15%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOutRight': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(15%)',
    },
  },
  addToCartHide: {
    display: 'none',
  },
  animationNone: {
    animation: 'none',
    opacity: 0,
  },
}));

export default AddToCartMessageHandler;
