import { gql } from '@apollo/client';

export const GET_LOW_ATTENTION_MESSAGES = gql`
  query GetLowAttentionMessages {
    lowAttentionMessages @client {
      id
      text
      variant
      severity
    }
  }
`;
