import React from 'react';
import clsx from 'clsx';

// Icons
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

interface Props {
	className?: string;
};

const EmptyCart: React.FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, props.className)}>
			<ShoppingCartOutlinedIcon color="disabled"/>
			<Typography variant="subtitle2" color="textSecondary" display="block" align="center"><b><i>Empty Order</i></b></Typography>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: 200,
		width: 200,
		borderRadius: '50%',
		backgroundColor: theme.palette.grey[200],
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		opacity: 0.7,
		'& > .MuiSvgIcon-root': {
			marginBottom: theme.spacing(2),
			fontSize: 80,
		},
	},
}));

export default EmptyCart;
