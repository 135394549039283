import React, { useState, useRef, useEffect } from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  delay?: number;
}

const GlobalLoading: React.FC<Props> = (props) => {
  const classes = useStyles();
  const timeOut = useRef<any>();
  const [show, setShow] = useState(false);

  if (show === false && !timeOut.current) {
    timeOut.current = setTimeout(
      () => {
        setShow(true);
      },
      props.delay ? props.delay : 100,
    );
  }

  // This is needed to avoid state update on an unmounted component.
  // If missing the following error is thrown:
  // ***********************************************************************
  // Can't perform a React state update on an unmounted component.        **
  // This is a no-op, but it indicates a memory leak in your application. **
  // ***********************************************************************
  useEffect(() => () => {
    clearTimeout(timeOut.current);
  }, []);

  return (
    <div className={`${classes.root} ${show ? '' : classes.hide}`}>
      <div
        className={`${classes.highAttention} ${classes.animationInBackdrop}`}
      >
        <CircularProgress />
        <p>{props.children}</p>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  highAttention: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    background: 'rgba(355,355,355, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 10000,
  },
  alertMarginBottom: {
    marginBottom: theme.spacing(1),
  },
  animationInMessage: {
    position: 'relative',
    display: 'block',
    transform: 'translateY(15%)',
    animation: '$fadeInUp 100ms linear forwards',
    zIndex: 1,
  },
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(15%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  animationInBackdrop: {
    animation: '$fadeIn 100ms linear forwards',
    zIndex: 1,
    opacity: 1,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  hide: {
    display: 'none',
  },
}));

export default GlobalLoading;
