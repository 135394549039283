import React, { useRef, useEffect, useState } from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';

import AlertMessage from 'components/GlobalMessages/components/Messages/AlertMessage';
import { useQuery } from '@apollo/client';

import { messageMutations } from 'operations/mutations/messages';

import { GET_MEDIUM_ATTENTION_MESSAGES } from 'operations/queries/messages/getMediumAttentionMessages';

interface Props {}

const MediumAttentionMessageHandler: React.FC<Props> = () => {
  const classes = useStyles();

  const { loading, data, error } = useQuery(GET_MEDIUM_ATTENTION_MESSAGES);

  const poppingLowAlert: React.MutableRefObject<boolean> = useRef(false);
  const lowAlertTimeout: React.MutableRefObject<any> = useRef();

  const currentLength: React.MutableRefObject<any> = useRef();

  const [animationClass, setAnimationClass] = useState(classes.animationHide);

  const animationTimeout: React.MutableRefObject<any> = useRef();
  const messageId = useRef(-1);
  const previousMessageId = useRef(-1);

  const handlePopMessage = () => {
    // Pop the message and trigger the animations
    if (!poppingLowAlert.current) {
      poppingLowAlert.current = true;
      // Trigger the out animation
      animateOut();
      // Do the logic after the animation has played
      lowAlertTimeout.current = setTimeout(() => {
        /* If there are still items, show the component, otherwise hide it
                   This is done because on the last message in the stack,
                   the component will flash because the pop operation has not been run yet */
        if (currentLength.current > 1) {
          animateShow();
        } else {
          animateHide();
        }
        poppingLowAlert.current = false;
        messageMutations.popMediumAttentionMessage();
      }, 200);
    }
  };

  // Start in animation
  const animateIn = () => {
    // Set the animation class to the reset class
    setAnimationClass(classes.animationNone);
  };

  // Start the out animation
  const animateOut = () => {
    setAnimationClass(classes.animationOut);
  };

  // Hide the component after it animates out
  const animateHide = () => {
    setAnimationClass(classes.animationHide);
  };

  // Hide the component after it animates out
  const animateShow = () => {
    setAnimationClass('');
  };

  // Start in animation
  useEffect(() => {
    /* If animation class is the reset class, start the animation.
          This is necessary for smooth reseting of the animation.
          The css animation will only reset if there is a delay between
          the class changes. This is done so that when a new item is added
          during the in animation, the animation is reset */
    if (animationClass === classes.animationNone) {
      clearTimeout(animationTimeout.current);
      animationTimeout.current = setTimeout(() => {
        setAnimationClass(classes.animationIn);
      }, 10);
    }
  }, [animationClass]);

  // Handle the animations if there has been a change in the messages list
  if (
    data.mediumAttentionMessages.length > 0 &&
    data.mediumAttentionMessages[data.mediumAttentionMessages.length - 1].id !==
      messageId.current
  ) {
    currentLength.current = data.mediumAttentionMessages.length;
    messageId.current =
      data.mediumAttentionMessages[data.mediumAttentionMessages.length - 1].id;

    // If the topmost id had changed, it means a new message is on the stack, animate in
    if (messageId.current !== previousMessageId.current) {
      animateIn();
    }

    // Store the id of the previous message in the stack.
    // This is done so that if a message is popped, the next one does not animate in,
    // since it was not newly added, but already in the stack
    if (data.mediumAttentionMessages.length > 1) {
      previousMessageId.current =
        data.mediumAttentionMessages[
          data.mediumAttentionMessages.length - 2
        ].id;
    } else {
      previousMessageId.current = -1;
    }
  } else if (!(data.mediumAttentionMessages.length > 0)) {
    messageId.current = -1;
  }

  return (
    <div className={classes.root}>
      <div>
        {!loading &&
        !error &&
        data &&
        data.mediumAttentionMessages &&
        data.mediumAttentionMessages.length > 0 ? (
          <div className={`${classes.alertMarginBottom} ${animationClass}`}>
            <AlertMessage
              variant={
                data.mediumAttentionMessages[
                  data.mediumAttentionMessages.length - 1
                ].variant
              }
              severity={
                data.mediumAttentionMessages[
                  data.mediumAttentionMessages.length - 1
                ].severity
              }
              handleOk={handlePopMessage}
            >
              {
                data.mediumAttentionMessages[
                  data.mediumAttentionMessages.length - 1
                ].text
              }
            </AlertMessage>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  alertMarginBottom: {
    marginBottom: theme.spacing(1),
  },
  animationIn: {
    position: 'relative',
    display: 'block',
    transform: 'translateX(-15%)',
    animation: '$fadeInRight 200ms linear forwards',
    zIndex: 1,
  },
  animationOut: {
    position: 'relative',
    display: 'block',
    transform: 'translateX(15%)',
    animation: '$fadeOutRight 200ms linear forwards',
    zIndex: 1,
    opacity: 0,
  },
  '@keyframes fadeInRight': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-15%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOutRight': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(15%)',
    },
  },
  animationHide: {
    display: 'none',
  },
  animationNone: {
    animation: 'none',
    opacity: 0,
  },
}));

export default MediumAttentionMessageHandler;
