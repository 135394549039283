import { gql, useMutation } from '@apollo/client';
import * as setOrderShippingTypes from '__generated__/setOrderShipping';

export const SET_ORDER_SHIPPING = gql`
  mutation setOrderShipping($shippingId: String!) {
    setOrderShipping(shippingId: $shippingId)
  }
`;

export const useSetOrderShipping = () => {
  const [mutate, { data, error }] = useMutation<
    setOrderShippingTypes.setOrderShipping,
    setOrderShippingTypes.setOrderShippingVariables
  >(SET_ORDER_SHIPPING);

  return { mutate, data, error };
};
