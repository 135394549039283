import {
  gql,
  ReactiveVar,
  makeVar,
  FieldFunctionOptions,
  TypePolicies,
} from '@apollo/client';
import first from 'lodash/fp/first';

import { relayStylePagination } from '@apollo/client/utilities';
import { SHIPPING_BY_CARRIERS } from 'operations';
import { AddToCartMessage, AttentionMessage } from 'interfaces/messages';

import {
  chatGroups_chatGroups_nodes_messages,
  chatGroups_chatGroups_nodes_messages_nodes,
} from '__generated__/chatGroups';

export const typePolicyFields: TypePolicies = {
  ChatGroup: {
    fields: {
      hasUnreadMessages: {
        read(current: any, { readField }: FieldFunctionOptions) {
          const lastMessage =
            readField<Partial<chatGroups_chatGroups_nodes_messages_nodes> | null>(
              'lastMessage'
            );
          return lastMessage?.isRead === false;
        },
      },
      lastMessage: {
        read(
          current: any,
          { readField, isReference }: FieldFunctionOptions
        ): Partial<chatGroups_chatGroups_nodes_messages_nodes> | null {
          let messages =
            readField<chatGroups_chatGroups_nodes_messages>('messages');
          const lastMessageRef = first(messages?.nodes);

          if (isReference(lastMessageRef)) {
            const id = readField<string>('id', lastMessageRef);
            const body = readField<string>('body', lastMessageRef);
            const isRead = readField<boolean>('isRead', lastMessageRef);
            const created = readField<boolean>('created', lastMessageRef);

            return { id, body, created, isRead, __typename: 'ChatMessage' };
          } else {
            return null;
          }
        },
      },
    },
  },
  AccountStatement: {
    fields: {
      accountStatementItems: relayStylePagination(['sort']),
    },
  },
  Cart: {
    fields: {
      selectedShipping: {
        read(curr: any, args: any) {
          try {
            const carriers: any = args.cache.readQuery({
              query: SHIPPING_BY_CARRIERS,
              variables: {
                id: args.variables?.id,
              },
            });
            const cart: any = args.cache.readQuery({
              query: gql`
                query CART_IN {
                  cart {
                    id
                    shippingMethodId
                  }
                }
              `,
            });
            const shippingMethodId = cart?.cart?.shippingMethodId;

            if (
              shippingMethodId &&
              Array.isArray(carriers?.shippingbyCarrier)
            ) {
              const selected = carriers?.shippingbyCarrier.find(
                (method: any) => method.id === shippingMethodId
              );

              if (selected) {
                return selected;
              }
              return null;
            }
          } catch (error) {
            console.debug('cache not populated yet.');
            return null;
          }

          return null;
        },
      },
    },
  },
  Query: {
    fields: {
      notifications: relayStylePagination(),
      productList: relayStylePagination([
        'categoryId',
        'filters',
        'sort',
        'sortDesc',
        'search',
      ]),

      lowAttentionMessages: {
        read() {
          return lowAttentionMessagesVar();
        },
      },
      mediumAttentionMessages: {
        read() {
          return mediumAttentionMessagesVar();
        },
      },
      highAttentionMessages: {
        read() {
          return highAttentionMessagesVar();
        },
      },
      addToCartMessages: {
        read() {
          return addToCartMessagesVar();
        },
      },
    },
  },
};

export const lowAttentionMessagesVar: ReactiveVar<AttentionMessage[]> = makeVar<
  AttentionMessage[]
>([]);

export const mediumAttentionMessagesVar: ReactiveVar<AttentionMessage[]> =
  makeVar<AttentionMessage[]>([]);

export const highAttentionMessagesVar: ReactiveVar<AttentionMessage[]> =
  makeVar<AttentionMessage[]>([]);

export const addToCartMessagesVar: ReactiveVar<AddToCartMessage[]> = makeVar<
  AddToCartMessage[]
>([]);
