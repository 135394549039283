import { gql } from '@apollo/client';

import { addressFragment } from '../fragments';

export const GET_ADDRESS = gql`
  query addresses($id: String) {
    getAddress(id: $id) {
      ...AddressFragment
    }
  }
  ${addressFragment}
`;
