import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';

// Utils
import { SiteContext } from 'utils/SiteProvider';



declare global {
    interface Window {
        gtag:any;
    }
};

export const sendGoogleAnalyticsEvent = (eventName:string, eventParams?:any) => {
	window.gtag('event', eventName, eventParams);
};

interface Props {
	
};

const GoogleAnalytics: React.FC<Props> = (props) => {
	const site:any = useContext(SiteContext);

	//process.env.GOOGLEANALYTICSID

	useEffect(() => {
		window.gtag('config', site?.settings?.googleAnalyticsId, { 'debug_mode': true, 'user_id': site?.session?.user?.contact?.id });
	},[]);

	// useEffect(() =
	return null;
};

export default withRouter(GoogleAnalytics);
