import React, { useState, useEffect, useContext, useRef } from 'react';
import { formatDistance } from 'date-fns';
import { usePageVisibility } from 'react-page-visibility';
import _differenceWith from 'lodash/differenceWith';
import isFunction from 'lodash/fp/isFunction';

// Operations
import {
  useNotificationList,
  pollInterval,
} from 'operations/queries/notifications';
import { useMarkNotificationRead } from 'operations/mutations/markNotificationRead';
import { useMarkNotificationUnread } from 'operations/mutations/markNotificationUnread';

// Utils
import { SiteContext } from 'utils/SiteProvider';

// Icons
import NotificationsIcon from '@material-ui/icons/Notifications';
import NoteIcon from '@material-ui/icons/Notifications';

// Material-UI
import { makeStyles, Theme, darken, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

interface Props {
  openNotification?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onNewNotification?: (notification: any) => void;
}

const Notifications: React.FC<Props> = (props) => {
  const { onOpen, onClose, openNotification } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const anchorEl = useRef(null);
  const scrollEl = useRef<HTMLDivElement | null>(null);
  const prevNotification = useRef<any[] | null>(null);
  const isFetchingNotification = useRef<boolean>(false);
  const site: any = useContext(SiteContext);
  const isVisible = usePageVisibility();
  const [open, setOpen] = useState<boolean>(openNotification || false);
  const [mute, setMute] = useState<boolean>(false);

  // Operations
  const {
    data: notifications,
    unreadCount: listUnreadCount,
    loading: listLoading,
    fetchMore,
    startPolling,
    stopPolling,
  } = useNotificationList();

  const { mutate: markAsRead, loading: readLoading } =
    useMarkNotificationRead();
  const { mutate: markAsUnread, loading: unreadLoading } =
    useMarkNotificationUnread();

  const loading = listLoading || readLoading || unreadLoading;

  useEffect(() => {
    setOpen(openNotification || false);
  }, [openNotification]);

  const getUnreadNotifications = (notifications: any): any[] => {
    let unread = notifications.edges?.filter(
      (notification: any) => notification.node.isRead === false
    );
    return unread;
  };
  useEffect(() => {
    if (isVisible) {
      startPolling(pollInterval);
    } else {
      stopPolling();
    }
  }, [isVisible]);

  useEffect(() => {
    if ((notifications?.edges?.length || 0) > 0) {
      const unread = getUnreadNotifications(notifications);
      // Here we have a very naive way to check if we have any new notifications.
      // We are comparing previously fetched notifications with the latest notifications.
      // TODO: replace with push from the server.
      const difference = _differenceWith(
        unread,
        prevNotification.current || [],
        (newItem, prev) => {
          const result = newItem.node.id === prev.node.id;
          return result;
        }
      );

      // By default we set `prevNotification.current` to `null`,
      // we need to do that to show first notification that is pushed into the list
      if (unread.length > 0 && prevNotification.current != null) {
        if (typeof props.onNewNotification === 'function') {
          props.onNewNotification(difference);
        }
      }

      prevNotification.current = unread;
    }
  }, [notifications]);

  const fromNow = (value: string) => {
    return formatDistance(new Date(value), new Date(), {
      addSuffix: true,
    }).replace(/about /g, '');
  };

  const handleMarkRead = async (notificationId: string) => {
    try {
      await markAsRead(site?.session?.user?.contact?.id, notificationId);
    } catch (error) {
      console.error(
        'Unable to mark the notification as read. NotificationId: ',
        notificationId
      );
    }
  };

  const handleMarkUnread = async (notificationId: string) => {
    try {
      await markAsUnread(site?.session?.user?.contact?.id, notificationId);
    } catch (error) {
      console.error(
        'Unable to mark the notification as unread. NotificationId: ',
        notificationId
      );
    }
  };

  const handleScroll = async (event: any) => {
    const element = event.target;
    const { scrollTop, clientHeight, scrollHeight } = element;
    const scrollThreshold = 80;
    if (
      !isFetchingNotification.current &&
      notifications?.pageInfo.hasNextPage &&
      scrollTop + clientHeight >= scrollHeight - scrollThreshold
    ) {
      isFetchingNotification.current = true;
      try {
        await fetchMore({
          variables: {
            after: notifications?.pageInfo.endCursor,
          },
        });
      } catch (error) {
        console.error('Unable to fetch more notifications', error);
      } finally {
        isFetchingNotification.current = false;
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (isFunction(onClose)) {
      onClose();
    }
  };

  const toggleOpen = () => {
    setOpen(!open);
    if (!open) {
      if (isFunction(onOpen)) {
        onOpen();
      }
    } else {
      if (isFunction(onClose)) {
        onClose();
      }
    }
  };
  return (
    <>
      <Button
        ref={anchorEl}
        aria-label="notifications"
        className={classes.smallButton}
        variant="contained"
        disableElevation
        size="small"
        color="primary"
        onClick={() => {
          toggleOpen();
        }}
      >
        <Badge badgeContent={listUnreadCount || 0} color="secondary">
          <NotificationsIcon fontSize="small" />
        </Badge>
        Notifications
      </Button>

      {anchorEl.current && (
        <Popover
          anchorEl={anchorEl.current}
          open={open}
          marginThreshold={4}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: mobile ? 'right' : 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: mobile ? 'right' : 'right',
          }}
          onClose={() => {
            handleClose();
          }}
        >
          <Box width="350px" paddingTop={0.5} paddingBottom={1}>
            <Box display="flex" alignItems="center" paddingLeft={1.5}>
              <Typography variant="h6" gutterBottom>
                Notifications
              </Typography>
              <Box flexGrow={1} />
              <FormControlLabel
                label="Mute"
                classes={{ label: classes.muteLabel }}
                control={
                  <Switch
                    checked={mute}
                    color="primary"
                    size="small"
                    onChange={() => {
                      setMute(!mute);
                    }}
                  />
                }
              />
            </Box>
            <Divider />
            <div
              className={classes.scroll}
              ref={scrollEl}
              onScroll={handleScroll}
            >
              {(notifications?.edges?.length || 0) > 0 ? (
                <List disablePadding>
                  {notifications?.edges?.map((edge: any) => (
                    <ListItem
                      key={edge?.cursor}
                      disableGutters
                      divider
                      dense
                      className={classes.listItem}
                    >
                      {edge?.node?.isRead ? (
                        <Tooltip title="Mark as Unread">
                          <IconButton
                            className={classes.dot + ' ' + classes.unread}
                            onClick={() => handleMarkUnread(edge?.node?.id)}
                          >
                            <NoteIcon fontSize="large"></NoteIcon>
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Mark as Read">
                          <IconButton
                            className={classes.dot + ' ' + classes.read}
                            onClick={() => handleMarkRead(edge?.node?.id)}
                          >
                            <NoteIcon  fontSize="large"></NoteIcon>
                          </IconButton>
                        </Tooltip>
                      )}

                      {edge?.node?.type?.code === 'CustomNotification' && (
                        <ListItemText
                          primary={
                            <div>
                              <b>{edge?.node?.title}</b>
                            </div>
                          }
                          secondary={<i>{fromNow(edge?.node?.created)}</i>}
                          primaryTypographyProps={{ variant: 'body2' }}
                          secondaryTypographyProps={{
                            variant: 'caption',
                            component: 'div',
                          }}
                        />
                      )}
                      {edge?.node?.type?.code === 'OrderUpdated' && (
                        <ListItemText
                          primary={
                            <div>
                              <b>{edge?.node?.title}</b>
                              <br />
                              {edge?.node?.body}
                            </div>
                          }
                          secondary={<i>{fromNow(edge?.node?.created)}</i>}
                          primaryTypographyProps={{ variant: 'body2' }}
                          secondaryTypographyProps={{
                            variant: 'caption',
                            component: 'div',
                          }}
                        />
                      )}
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={100}
                  paddingTop={1}
                >
                  <Typography variant="subtitle2" color="textSecondary">
                    <i>No Notifications</i>
                  </Typography>
                </Box>
              )}
            </div>
          </Box>
        </Popover>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  smallButton: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: darken(theme.palette.primary.dark, 0.05),
    transform: 'scale(0.9)',
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.dark, 0.15),
    },
    '& > .MuiButton-label > .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiBadge-root': {
      marginRight: theme.spacing(0.5),
    },
  },
  listItem: {
    padding: 0,
    wordBreak: 'break-word',
    paddingRight: theme.spacing(2)
  },
  dot: {
    padding: 0,
    width: 42,
    height: 56,
    borderRadius: 0,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
  },
  viewButton: {
    padding: 0,
    minWidth: 50,
  },
  button: {
    padding: 0,
    minWidth: 34,
    marginLeft: theme.spacing(1),
  },
  muteLabel: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  scroll: {
    maxHeight: 600,
    width: 350,
    overflow: 'auto',
  },
  read: {
    color: theme.palette.primary.main
  },
  unread: {
    color: theme.palette.grey[400]
  }
}));

export default Notifications;
