import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import { cartLinesFragment } from '../fragments/cartLines';
import { orderAddressFragment } from '../fragments/orderAddressFragment';
import { cart, cart_cart, cart_cart_lines } from '__generated__/cart';

export interface cart_cart_lines_with_pricing extends cart_cart_lines {
  pricing: any;
}
export interface modified_cart extends Omit<cart_cart, 'lines'> {
  lines: cart_cart_lines_with_pricing[];
}
const transformCart = (cart: cart_cart): modified_cart => {
  let clonedCart = _cloneDeep(cart);
  // Internal
  const buildPriceNode = (product: cart_cart_lines) => {
    return {
      price: product.price,
      amount: product.amount,
      discount: product.discount || 0,
      quantity: product.quantity,
      unitOfMeasure: { ...product.unitOfMeasure },
    };
  };
  const lineOrder = _.map(cart.lines, 'id');
  let transformedLines: cart_cart_lines_with_pricing[] = [];
  if (clonedCart && !!clonedCart?.lines?.length) {
    let groupped = _(clonedCart.lines)
      .map((cartLine) => ({ ...cartLine, pricing: [] }))
      .groupBy((line) => {
        return _get(line, 'product.id');
      })
      .value();

    transformedLines = Object.values(groupped).map((group) => {
      if (group.length === 1 && group[0] != null) {
        let item: cart_cart_lines_with_pricing = { ...group[0], pricing: [] };
        item.pricing = [buildPriceNode(item)];
        return item;
      } else {
        return group.reduce((prev, current) => {
          if (_isEmpty(prev)) {
            prev = { ...current };
            prev.pricing = [buildPriceNode(current)];

            return prev;
          } else {
            prev.pricing.push(buildPriceNode(current));
            return prev;
          }
        }, {} as cart_cart_lines_with_pricing);
      }
    });
  }
  transformedLines = _.sortBy(transformedLines, line => {
    return lineOrder.indexOf(line.id);
  })
  return {
    ...clonedCart,
    lines: transformedLines,
  };
};

export const GET_CART = gql`
  query cart {
    cart {
      id
      itemCount
      subtotal
      discountTotal
      total
      requestedDeliveryDate
      messages {
        type
        text
      }
      taxTotal
      freightTotal
      orderShippingAddressId
      orderBillingAddressId
      email
      orderShippingAddress @client {
        ...OrderAddressFragment
      }
      orderBillingAddress @client {
        ...OrderAddressFragment
      }
      addresses {
        ...OrderAddressFragment
      }
      lines {
        ...CartLinesFragment
      }
      salesRep {
        id
        name
        code
      }
    }
  }
  ${cartLinesFragment}
  ${orderAddressFragment}
`;

export const useCart = () => {
  const { data, loading, error } = useQuery<cart>(GET_CART);

  let emptyCart: modified_cart = {
    id: '-1',
    subtotal: 0,
    itemCount: 0,
    discountTotal: 0,
    taxTotal: 0,
    total: 0,
    requestedDeliveryDate: null,
    messages: [],
    addresses: [],
    email: "",
    freightTotal: 0.00,
    orderBillingAddress: null,
    orderShippingAddress: null,
    orderShippingAddressId: null,
    orderBillingAddressId: null,
    salesRep: null,
    __typename: 'Cart',
    lines: [],
  };
  let cart = _get(data, 'cart', emptyCart);

  let transformedCart: modified_cart = emptyCart;
  if (cart) {
    transformedCart = transformCart(cart);
  }
  return { data: transformedCart, loading: loading, error };
};
