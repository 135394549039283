import React, { useEffect, useRef } from 'react';

import { useSnackbar } from './../SnackbarProvider';

// Icons
import CutoffPassedIcon from '@material-ui/icons/LocalShippingOutlined';
import OrderUpdatedIcon from '@material-ui/icons/AssignmentLateOutlined';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { formatDistance } from 'date-fns';

interface Props {
  id: string;
  type: string;
  name: string;
  orderId: string;
  onNotificationClick?: (notification: any) => void;
  handleOpenNotifications: (args: any) => void;
  timestamp: string;
}

const Notification: React.FC<Props> = (props) => {
  const {
    id,
    type,
    name,
    orderId,
    onNotificationClick,
    handleOpenNotifications,
    timestamp,
  } = props;
  const { dismiss } = useSnackbar();
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const classes = useStyles();

  useEffect(() => {
    timeout.current = setTimeout(() => {
      dismiss(id);
    }, 5000);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const fromNow = (value: string) => {
    return formatDistance(new Date(value), new Date(), {
      addSuffix: true,
    }).replace(/about /g, '');
  };

  const getIcon = (value: string) => {
    switch (value) {
      case 'ORDER-UPDATED':
        return <OrderUpdatedIcon />;
      case 'CUTOFF-PASSED':
        return <CutoffPassedIcon />;
      default:
        return <NotificationsIcon />;
    }
  };

  const handleNotificationClick = () => {
    if (typeof onNotificationClick === 'function') {
      onNotificationClick({ type, name, orderId });
    }
    if (typeof handleOpenNotifications === 'function') {
      handleOpenNotifications({ type, name, orderId });
    }
  };
  return (
    <Box
      display="flex"
      className={classes.root}
      flexGrow={1}
      alignItems="center"
      onClick={() => handleNotificationClick()}
    >
      <Avatar className={classes.avatar}>{getIcon(props.type)}</Avatar>
      <div>
        <Typography variant="subtitle1">{props.name}</Typography>
        <Typography variant="caption">{fromNow(timestamp)}</Typography>
      </div>
      <Box flexGrow={1} width={8} />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '400px',
      wordBreak: 'break-word',
    },
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(2),
    padding: 0,
    minWidth: 50,
  },
}));

export default Notification;
