/**
 * Adds `authorization` header with token if available
 */
import { getAuthToken } from 'utils/auth';
import { setContext } from '@apollo/client/link/context';

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
