import React, { useState, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

// Icons
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Material-UI
import { makeStyles, Theme, darken } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';


interface Props {
	catalog: any[];
};

const HeaderNav: React.FC<Props> = (props) => {
	const classes = useStyles();

	const menu = props.catalog.filter((obj:any) => obj.tier === 2);

	// State
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedLevel1, setSelectedLevel1] = useState<null|any>(null);
	const [selectedLevel2, setSelectedLevel2] = useState<null|any>(null);
	const [selectedLevel3, setSelectedLevel3] = useState<null|any>(null);
	const [selectedLevel4, setSelectedLevel4] = useState<null|any>(null);


	const handleSelectLevel1 = (item1:any) => (e:MouseEvent<HTMLButtonElement>) => {
		const newSelectedLevel1 = { item: item1, menu: props.catalog.filter((obj:any) => obj.parentId === item1.id) };
		setSelectedLevel1(newSelectedLevel1);

		handleSelectLevel2(newSelectedLevel1.menu[0]);
		setAnchorEl(e.currentTarget);
	};

	const handleSelectLevel2 = (item2:any = {}) => {
		const newSelectedLevel2 = { item: item2, menu: props.catalog.filter((obj:any) => obj.parentId === item2.id) };
		setSelectedLevel2(newSelectedLevel2);

		handleSelectLevel3(newSelectedLevel2.menu[0]);
	};

	const handleSelectLevel3 = (item3:any = {}) => {
		const newSelectedLevel3 = { item: item3, menu: props.catalog.filter((obj:any) => obj.parentId === item3.id) };
		setSelectedLevel3(newSelectedLevel3);

		handleSelectLevel4(newSelectedLevel3.menu[0]);
	};

	const handleSelectLevel4 = (item4:any = {}) => {
		const newSelectedLevel4 = { item: item4, menu: props.catalog.filter((obj:any) => obj.parentId === item4.id) };
		setSelectedLevel4(newSelectedLevel4);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className={classes.root}>
			{menu.map((item:any) => (
				<Button key={item.id} className={classes.button} data-open={Boolean(anchorEl) && (item.id === selectedLevel1?.item?.id)} color="inherit" onClick={handleSelectLevel1(item)}>{item.name}</Button>
			))}
			<Button aria-label="f a q"color="inherit" className={classes.button} component={RouterLink} to="/faq">FAQ</Button>
			<Button aria-label="tutorial" color="inherit" className={classes.button} component={RouterLink} to="/tutorial">Tutorial</Button>

			<Popover
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				onClose={() => setAnchorEl(null)}
			>
				<Box overflow="hidden">
					<Grid container>
						{selectedLevel1 && (
							<Grid item>
								<List disablePadding className={classes.list}>
									<ListItem button dense to={`/product-list/${selectedLevel1?.item?.seoCrumb}`} component={RouterLink} className={classes.listHeader} onMouseDown={handleClose}>
										<ListItemIcon>
											<ShoppingBasketOutlinedIcon color="primary" fontSize="small"/>
										</ListItemIcon>
										<ListItemText><b>View All {selectedLevel1?.item?.name}</b></ListItemText>
									</ListItem>
									<Divider/>
									{selectedLevel1?.menu?.map((item:any) => (
										<ListItem key={item.id} button dense selected={item.id === selectedLevel2?.item?.id} onClick={() => handleSelectLevel2(item)}>
											<ListItemText>{item.name}</ListItemText>
											<ChevronRightIcon color="disabled"/>
											<Link component={RouterLink} to={`/product-list/${item?.seoCrumb}`} onMouseDown={handleClose}>
												<Tooltip title={`View All ${item.name}`}>
													<Button variant="text" color="primary" size="small" startIcon={<ShoppingBasketOutlinedIcon/>}>View</Button>
												</Tooltip>
											</Link>
										</ListItem>
									))}
								</List>
							</Grid>
						)}
						{selectedLevel2 && (
							<Grid item>
								<List disablePadding className={clsx(classes.list, classes.middle)}>
									<ListItem button dense to={`/product-list/${selectedLevel2?.item?.seoCrumb}`} component={RouterLink} className={classes.listHeader} onMouseDown={handleClose}>
										<ListItemIcon>
											<ShoppingBasketOutlinedIcon color="primary" fontSize="small"/>
										</ListItemIcon>
										<ListItemText><b>View All {selectedLevel2?.item?.name}</b></ListItemText>
									</ListItem>
									<Divider/>
									{selectedLevel2?.menu?.map((item:any) => (
										<ListItem key={item.id} button dense selected={item.id === selectedLevel3?.item?.id} onClick={() => handleSelectLevel3(item)}>
											<ListItemText>{item.name}</ListItemText>
											<ChevronRightIcon color="disabled"/>
											<Link component={RouterLink} to={`/product-list/${item?.seoCrumb}`} onMouseDown={handleClose}>
												<Tooltip title={`View All ${item.name}`}>
													<Button variant="text" color="primary" size="small" startIcon={<ShoppingBasketOutlinedIcon/>}>View</Button>
												</Tooltip>
											</Link>
										</ListItem>
									))}
								</List>
							</Grid>
						)}
						{selectedLevel3 && (
							<Grid item>
								<List disablePadding className={classes.list}>
									<ListItem button dense to={`/product-list/${selectedLevel3?.item?.seoCrumb}`} component={RouterLink} className={classes.listHeader} onMouseDown={handleClose}>
										<ListItemIcon>
											<ShoppingBasketOutlinedIcon color="primary" fontSize="small"/>
										</ListItemIcon>
										<ListItemText><b>View All {selectedLevel3?.item?.name}</b></ListItemText>
									</ListItem>
									<Divider/>
									{selectedLevel3?.menu?.map((item:any) => (
										<ListItem key={item.id} button dense selected={item.id === selectedLevel4?.item?.id} onClick={() => handleSelectLevel4(item)}>
											<ListItemText>{item.name}</ListItemText>
											<Link component={RouterLink} to={`/product-list/${item?.seoCrumb}`} onMouseDown={handleClose}>
												<Tooltip title={`View All ${item.name}`}>
													<Button variant="text" color="primary" size="small" startIcon={<ShoppingBasketOutlinedIcon/>}>View</Button>
												</Tooltip>
											</Link>
										</ListItem>
									))}
								</List>
							</Grid>
						)}
					</Grid>
				</Box>
			</Popover>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flex: '1 0 0px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	button: {
		'&[data-open="true"]': {
			display: 'inline-block',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: darken(theme.palette.primary.dark, 0.05),
		},
		'&:hover': {
			backgroundColor: darken(theme.palette.primary.dark, 0.05),
		},
	},
	list: {
		minWidth: 250,
		minHeight: 300,
		height: '100%',
		paddingBottom: theme.spacing(2),
		'& .MuiListItemIcon-root': {
			minWidth: 28,
		},
		'& .MuiListItem-root': {
			padding: theme.spacing(0.75, 0.5, 0.75, 1.5),
			borderLeft: '4px solid transparent',
			'&.Mui-selected': {
				borderLeftColor: theme.palette.primary.main,
			},
			'& .MuiButton-sizeSmall': {
				zoom: 0.9,
				padding: theme.spacing(0.25, 0.75),
				'& .MuiButton-startIcon': {
					marginRight: theme.spacing(0.5),
				}
			},
		},
		'& .MuiListItemSecondaryAction-root': {
			right: 0,
		},
	},
	listHeader: {
		height: 50,
		borderLeftWidth: '0 !important',
	},
	middle: {
		borderLeft: `1px solid ${theme.palette.divider}`,
		borderRight: `1px solid ${theme.palette.divider}`,
	},
}));

export default HeaderNav;
