import React, { useContext } from 'react';
import clsx from 'clsx';

import { SiteContext } from 'utils/SiteProvider';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
	className?: string;
};

const Logo = (props: Props) => {
	const classes = useStyles(props);
	const site:any = useContext(SiteContext);
	
	return <img alt="logo" src={site?.settings?.logoPath || ''} className={clsx(classes.root, props.className)}/>;
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: '100%',
		width: '100%',
		objectFit: 'contain',
	},
}));

export default Logo;
