import React, { useRef, useEffect, useState } from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import AlertMessage from 'components/GlobalMessages/components/Messages/AlertMessage';
import { useQuery } from '@apollo/client';

import { messageMutations } from 'operations/mutations/messages';

import { GET_LOW_ATTENTION_MESSAGES } from 'operations/queries/messages//getLowAttentionMessages';

/**
 * Handles the logic for low attention messages
 * @prop {number} timeOut The time that a message is viewable for
 */
interface Props {
  timeOut: number;
}

const LowAttentionMessageHandler: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { loading, data, error } = useQuery(GET_LOW_ATTENTION_MESSAGES);

  const lowAlertTimeout: React.MutableRefObject<any> = useRef();

  const currentLength: React.MutableRefObject<any> = useRef();

  // Pop the message and trigger the animations
  const handlePopMessage = () => {
    clearTimeout(lowAlertTimeout.current);
    // After the viewing uration has passed
    lowAlertTimeout.current = setTimeout(() => {
      // Trigger the out animation
      animateOut();

      // Do the logic after the animation has played
      lowAlertTimeout.current = setTimeout(() => {
        /* If there are still items, show the component, otherwise hide it
                   This is done because on the last message in the stack,
                   the component will flash because the pop operation has not been run yet */
        if (currentLength.current > 1) {
          animateShow();
        } else {
          animateHide();
        }
        messageMutations.popLowAttentionMessage();
      }, 200);
    }, props.timeOut);
  };

  const [animationClass, setAnimationClass] = useState(classes.animationHide);

  const animationTimeout: React.MutableRefObject<any> = useRef();
  const messageId = useRef(-1);
  const previousMessageId = useRef(-1);

  // Start in animation
  const animateIn = () => {
    // Set the animation class to the reset class
    setAnimationClass(classes.animationNone);
  };

  // Start the out animation
  const animateOut = () => {
    setAnimationClass(classes.animationOut);
  };

  // Hide the component after it animates out
  const animateHide = () => {
    setAnimationClass(classes.animationHide);
  };

  // Hide the component after it animates out
  const animateShow = () => {
    setAnimationClass('');
  };

  // Start in animation
  useEffect(() => {
    /* If animation class is the reset class, start the animation.
           This is necessary for smooth reseting of the animation.
           The css animation will only reset if there is a delay between
           the class changes. This is done so that when a new item is added
           during the in animation, the animation is reset */
    if (animationClass === classes.animationNone) {
      clearTimeout(animationTimeout.current);
      animationTimeout.current = setTimeout(() => {
        setAnimationClass(classes.animationIn);
      }, 10);
    }
  }, [animationClass]);

  // Start the pop countdown to auto pop the message if a message it found
  if (
    data.lowAttentionMessages.length > 0 &&
    data.lowAttentionMessages[data.lowAttentionMessages.length - 1].id !==
      messageId.current
  ) {
    currentLength.current = data.lowAttentionMessages.length;
    messageId.current =
      data.lowAttentionMessages[data.lowAttentionMessages.length - 1].id;

    if (messageId.current !== previousMessageId.current) {
      animateIn();
    }

    // Store the id of the previous message in the stack.
    // This is done so that if a message is popped, the next one does not animate in,
    // since it was not newly added, but already in the stack
    if (data.lowAttentionMessages.length > 1) {
      previousMessageId.current =
        data.lowAttentionMessages[data.lowAttentionMessages.length - 2].id;
    } else {
      previousMessageId.current = -1;
    }

    handlePopMessage();
  } else if (!(data.lowAttentionMessages.length > 0)) {
    messageId.current = -1;
  }

  return (
    <div className={classes.root}>
      <div>
        <div>
          {!loading &&
          !error &&
          data &&
          data.lowAttentionMessages &&
          data.lowAttentionMessages.length > 1 ? (
            <div className={classes.alertMarginBottom}>
              <AlertMessage
                variant={
                  data.lowAttentionMessages[
                    data.lowAttentionMessages.length - 2
                  ].variant
                }
                severity={
                  data.lowAttentionMessages[
                    data.lowAttentionMessages.length - 2
                  ].severity
                }
              >
                {
                  data.lowAttentionMessages[
                    data.lowAttentionMessages.length - 2
                  ].text
                }
              </AlertMessage>
            </div>
          ) : null}
          {!loading &&
          !error &&
          data &&
          data.lowAttentionMessages &&
          data.lowAttentionMessages.length > 0 ? (
            <div className={`${classes.alertMarginBottom} ${animationClass}`}>
              <AlertMessage
                variant={
                  data.lowAttentionMessages[
                    data.lowAttentionMessages.length - 1
                  ].variant
                }
                severity={
                  data.lowAttentionMessages[
                    data.lowAttentionMessages.length - 1
                  ].severity
                }
              >
                {
                  data.lowAttentionMessages[
                    data.lowAttentionMessages.length - 1
                  ].text
                }
              </AlertMessage>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
	},
  },
  alertMarginBottom: {
    marginBottom: theme.spacing(1),
  },
  animationIn: {
    position: 'relative',
    display: 'block',
    transform: 'translateX(-15%)',
    animation: '$fadeInRight 200ms linear forwards',
    zIndex: 1,
  },
  animationOut: {
    position: 'relative',
    display: 'block',
    transform: 'translateX(15%)',
    animation: '$fadeOutRight 200ms linear forwards',
    zIndex: 1,
    opacity: 0,
  },
  '@keyframes fadeInRight': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-15%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOutRight': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(15%)',
    },
  },
  animationHide: {
    display: 'none',
  },
  animationNone: {
    animation: 'none',
    opacity: 0,
  },
}));

export default LowAttentionMessageHandler;
