import { gql } from '@apollo/client';

export const CREATE_PAYMENT_AUTH_TOKEN = gql`
  mutation CREATE_PAYMENT_AUTH_TOKEN {
    authorizeToken {
      ... on PaymentToken {
        token
      }
      ... on PaymentTokenError {
        code
        message
      }
    }
  }
`;
