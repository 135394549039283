import { gql } from '@apollo/client';

export const chatMessageFragment = gql`
	fragment ChatMessageFragment on ChatMessage {
		id
		subject
		body
		from {
			id
			fullName
		}
		group { 
			id
			name
		}
		isRead
		readAt
		created
	}
`;
